import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import * as $ from 'jquery';

@Directive({
  selector: '[appSearchBox]',
})
export class SearchBoxDirective {
  @Output() onEvent = new EventEmitter<any>();
  @Input() searchAfter: number = 3;
  @HostListener('keyup', ['$event'])
  public onKeyUp(event: any): void {
    let mainDiv = this.renderer2.parentNode(this.ele.nativeElement);
    let input = $(this.ele.nativeElement);
    if (event.target.value.length > 0) {
      this.addMultiClass(mainDiv, 'pyt_Searching shadow-sm');
    } else {
      this.removeMultiClass(mainDiv, 'pyt_Searching shadow-sm');
    }
    if (event.keyCode == 27) {
      this.renderer2.setProperty(this.ele.nativeElement, 'value', '');
      this.removeMultiClass(mainDiv, 'pyt_Searching shadow-sm');
    }
    if (
      event.keyCode != 16 &&
      event.keyCode != 17 &&
      event.keyCode != 18 &&
      event.keyCode != 9
    ) {
      // this.emitEvent$.next(event);
    }
  }
  constructor(private ele: ElementRef, private renderer2: Renderer2) { }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    const pyt_SearchBox = this.renderer2.parentNode(this.ele.nativeElement);

    const searchIconBox = this.renderer2.createElement('SPAN');
    this.addMultiClass(searchIconBox, 'searchIconBox');

    const closeIconBox = this.renderer2.createElement('SPAN');
    this.addMultiClass(closeIconBox, 'closeIconBox');

    this.renderer2.listen(closeIconBox, 'click', (e) => {
      this.renderer2.setProperty(this.ele.nativeElement, 'value', '');

      this.removeMultiClass(pyt_SearchBox, 'pyt_Searching shadow-sm');
      this.onEvent.emit(this.ele.nativeElement);
    });

    const searchIcon = this.renderer2.createElement('I');
    this.addMultiClass(searchIcon, 'fas fa-search');

    const closeIcone = this.renderer2.createElement('I');
    this.addMultiClass(closeIcone, 'fas fa-times');

    this.renderer2.appendChild(searchIconBox, searchIcon);
    this.renderer2.appendChild(closeIconBox, closeIcone);

    this.addMultiClass(pyt_SearchBox, 'pyt_SearchBox');

    // this.renderer2.appendChild(pyt_SearchBox, this.ele.nativeElement);
    this.renderer2.appendChild(pyt_SearchBox, searchIconBox);
    this.renderer2.appendChild(pyt_SearchBox, closeIconBox);
  }

  addMultiClass(ele: any, obj: any) {
    obj.split(' ').forEach((className: string) => {
      this.renderer2.addClass(ele, className);
    });
  }

  removeMultiClass(ele: any, obj: any) {
    obj.split(' ').forEach((className: string) => {
      this.renderer2.removeClass(ele, className);
    });
  }
}

// <div class="pyt_SearchBox" >
//   <input type="text" appSearchBox placeholder = "Filter Staff" ngModel #filterVal >
//     <span class="searchIconBox" >
//       <i class="fas fa-search" > </i>
//     < /span>
//     < span class="closeIconBox" >
//      <i class="fas fa-times" > </i>
//     < /span>
// < /div>
