import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector, ComponentType } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector } from '@angular/core';

import { FormModalOverlayRef } from '../classes/form-modal.ref';
import { CustomOverlayAnimationConfig } from '../interfaces/animation-modal-data';
import { customOverlayPosition } from '../interfaces/customPosition-modal-data';
import { COMPONENT_MODAL_DATA } from '../tokens/component-data.token';
import { CommonFunctionsService } from './common-functions.service';


/**
 *
 * Is object main jo data key hai uska use kisi bhi kaam ke liye use kr sakte hai.
 *
 *
 */
export interface FormModalConfig {
    data?: any;
    panelClass?: string;
    hasBackdrop?: boolean;
    backdropClass?: string;
    isCentered?: boolean;
    close?: string;
    customOverlayPosition?: customOverlayPosition | null;

    isAnimation?: CustomOverlayAnimationConfig | null;
}

const DEFAULT_CONFIG: FormModalConfig = {
    hasBackdrop: true,
    backdropClass: 'my-overlay-modal-backdrop',
    panelClass: 'my-modal-panel'
};

@Injectable()
export class ComponentModalService {
    public dialogRef!: FormModalOverlayRef;
    constructor(
        private injector: Injector,
        private overlay: Overlay,
        private _CommonFunctionsService: CommonFunctionsService
    ) { }

    open<T>(component: ComponentType<T>, config: FormModalConfig = {}) {
        // Returns an OverlayRef (which is a PortalHost)
        const modalConfig = { ...DEFAULT_CONFIG, ...config };
        const overlayRef = this.createOverlay(modalConfig);
        const dialogRef = new FormModalOverlayRef(overlayRef);

        const overlayComponent = this.attachModalContainer<T>(overlayRef, modalConfig, dialogRef, component);

        this.dialogRef = dialogRef;
        return dialogRef;
    }

    // tslint:disable-next-line:max-line-length
    private attachModalContainer<T>(overlayRef: OverlayRef, config: FormModalConfig, dialogRef: FormModalOverlayRef, component: ComponentType<T>) {
        const injector = this.createInjector(config, dialogRef);

        const containerPortal = new ComponentPortal(component, null, injector);
        const containerRef: ComponentRef<T> = overlayRef.attach(containerPortal);
        return containerRef.instance;
    }

    // private createInjector(config: FormModalConfig, dialogRef: FormModalOverlayRef): PortalInjector {
    //     const injectionTokens = new WeakMap();

    //     injectionTokens.set(FormModalOverlayRef, dialogRef);
    //     injectionTokens.set(COMPONENT_MODAL_DATA, config);
    //     return new PortalInjector(this.injector, injectionTokens);
    // }
    private createInjector(config: FormModalConfig, dialogRef: FormModalOverlayRef): Injector {
      return Injector.create({
        providers: [{
          provide: FormModalOverlayRef, useValue: dialogRef
        },
        {
          provide: COMPONENT_MODAL_DATA, useValue: config.data
        }]
      });
    }
    private getOverlayConfig(config: FormModalConfig): OverlayConfig {
        let positionStrategy = this.overlay.position()
            .global()
            .centerHorizontally()
            .centerVertically();


        positionStrategy = this._CommonFunctionsService.setPositionStrategy(config, positionStrategy);
        let panelCls: any = this._CommonFunctionsService.setMdlAnimation(config, config.panelClass);



        const overlayConfig = new OverlayConfig({
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass,
            panelClass: panelCls,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });

        return overlayConfig;
    }

    private createOverlay(config: FormModalConfig) {
        // Returns an OverlayConfig
        const overlayConfig = this.getOverlayConfig(config);

        // Returns an OverlayRef
        return this.overlay.create(overlayConfig);
    }
}
