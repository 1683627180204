import { Component, Inject, OnInit } from '@angular/core';
import { FormModalOverlayRef } from '@features/customCDK/cdkCustomMdl/classes/form-modal.ref';
import { COMPONENT_MODAL_DATA } from '@features/customCDK/cdkCustomMdl/tokens/component-data.token';
import { DashboardService } from '@page/dashboard/dashboard.service';

@Component({
  selector: 'app-auto-log-out-pop',
  templateUrl: './auto-log-out-pop.component.html',
  styleUrls: ['./auto-log-out-pop.component.scss']
})
export class AutoLogOutPopComponent implements OnInit {
  secondTimerLeft: string;

  constructor(
    private dashboardService: DashboardService,
    public dialogRef: FormModalOverlayRef,
    @Inject(COMPONENT_MODAL_DATA) public data: any
  ) {

  }

  ngOnInit(): void {
    this.data.data.secondTimerLeft.subscribe((val) => {
      this.secondTimerLeft = val;
      // console.log(this.secondTimerLeft);
      if(this.secondTimerLeft == '0:0'){
        this.dashboardService.dashboardModalClose.next(true)
      }

    })
  }
  _continue() {
    this.dialogRef.close(this.data, 'continue');
  }

  _logout() {
    this.dialogRef.close(this.data);
    this.dashboardService.dashboardModalClose.next(true)
  }
}
