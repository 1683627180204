import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynPolarChartComponent } from './dyn-polar-chart.component';
import { NgChartsModule } from 'ng2-charts';



@NgModule({
  declarations: [
    DynPolarChartComponent
  ],
  imports: [
    CommonModule,
    NgChartsModule
  ],
  exports: [DynPolarChartComponent]
})
export class DynPolarChartModule { }
