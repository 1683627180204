import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConfirmationModalOverlayRef } from '@features/customCDK/cdkCustomMdl/classes/confirmation-modal.ref';
import { ConfirmationSelectModalData } from '@features/customCDK/cdkCustomMdl/interfaces/confirmation-modal-data';
import { CONFIRMATION_MODAL_DATA } from '@features/customCDK/cdkCustomMdl/tokens/confirmation-data.token';

@Component({
  selector: 'app-connected-banking-modal',
  templateUrl: './connected-banking-modal.component.html',
  styleUrls: ['./connected-banking-modal.component.scss']
})
export class ConnectedBankingModalComponent implements OnInit {

  modalSetting: ConfirmationSelectModalData;
    dataValue:any = this.data
  radioForm: FormControl = new FormControl()
  constructor(
    public dialogRef: ConfirmationModalOverlayRef,
    @Inject(CONFIRMATION_MODAL_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.modalSetting = this.data;
    this.radioForm.setValue(this.modalSetting.title2)
  }


  public closeModal($event: any) {
    this.dialogRef.events.next({
      type: 'close',
      data: this.data
    });
  }

  public confirmModal($event: any) {
    this.data['selectedValue'] = this.radioForm.value
    this.dialogRef.events.next({
      type: 'confirm',
      data: this.data
    });
  }

  public cancelModal($event: any) {
    this.dialogRef.events.next({
      type: 'cancel',
      data: this.data
    });
  }

}
