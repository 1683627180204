
import { Router } from '@angular/router';
import { AsyncSubject, BehaviorSubject, Subject } from 'rxjs';

import { config } from './../../core/services/request-url.service';
import { ApiRequestService } from './../../core/services/api-request.service';
import { Injectable } from '@angular/core';
import { SessionStorageService } from '@core/services/session-storage.service';
import { NgxToasterService } from '@core/services/toasterNgs.service';
import { AutoLogoutService } from '@features/autoLogout/service/auto-logout.service';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class AuthCoreService {
  //loginUserName: Subject<any> = new Subject();
  userForgetPasswordData = new AsyncSubject();
  isSessionTimeOut: BehaviorSubject<boolean> = new BehaviorSubject(false);
  encrypted: boolean | undefined = undefined;
  unSub: AutoLogoutService;
  isSubMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(private apiRequest: ApiRequestService,
    private route: Router,
    private ngrxToaster: NgxToasterService,
    private sessionStorage: SessionStorageService,
    private _AutoLogoutService: AutoLogoutService,
  ) {
    this.unSub = _AutoLogoutService;
    // this.positions();
  }

  // login api integration
  login(payloadData: any) {
    return this.apiRequest.postdataWithoutHeader(payloadData, config.auth['login']);
  };

  // verify OTP integration
  verifyOtp(payload: any) {
    return this.apiRequest.postdata(payload, config.auth['verifyOtp']);
  };

  // change password  integration
  changePassword(payload: any, url: string) {
    return this.apiRequest.postdata(payload, config.auth[url]);
  };

  // change password  integration
  updatePassword(payload: any) {
    return this.apiRequest.postdata(payload, config.auth['updatePassword']);
  };

  // forget password send OTP integration
  forgetPassword(payload: any) {
    return this.apiRequest.postdata(payload, config.auth['forgetPassword']);
  };

  // forget password verify OTP integration
  forgetPasswordVerifyOtp(payload: any) {
    return this.apiRequest.postdata(payload, config.auth['forgetPasswordVerifyOtp']);
  };

  // setting permission api integration
  permission(payload: any) {
    return this.apiRequest.postdata(payload, config.auth['permission']);
  };


  // getting current positions
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });



  };
  sidebarColps: boolean = false;
  private cols = new BehaviorSubject<boolean>(this.sidebarColps)
  updateCols = this.cols.asObservable();
  toggleCols(value: boolean) {
    this.cols.next(value)
  }
  // getting current positions
  // userPosition(): Promise<any>
  // {
  //   return new Promise((resolve, reject) => {
  //     navigator.geolocation.getCurrentPosition(resp => {
  //         resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
  //       },
  //       err => {
  //         reject(err);
  //       });
  //   }).then(
  //     (positions: any) => {
  //       if (positions) {
  //         AppData.latLang.next({
  //           lat:positions.lat,
  //           lang:positions.lng
  //         })

  //       }
  //     }
  //   );
  // };



  logout(hardLogout: boolean = true, errorMsg?: string) {
    if (hardLogout) {
      let payload: FormData = new FormData()
      //this.isSessionTimeOut.next(false);
      this.apiRequest.postdataAdmin(payload, config.auth['logout']).subscribe(

        (resp: any) => {
            if (resp.statuscode === 200) {
              this.ngrxToaster.showSuccess(resp.msg, undefined);

            }
            this.sessionStorage.clearStorage();
          },(error: any) => {
            this.sessionStorage.clearStorage();
          }

      );
    } else {
      this.sessionStorage.clearStorage();
      this.sessionStorage.removeItem('userDetails')
      this.ngrxToaster.showError(errorMsg ?? 'Session Expired!!', 'Error');
    }

    setTimeout(() => {
      this.unSub.uninit()
      //   let un: any = this._AutoLogoutService.userIdlenessChecker;
      //   let unq: any = this._AutoLogoutService.secondLevelUserIdleChecker;
      //   un.unsubscribe();
      //   unq.unsubscribe();
    }, 1);

    this.route.navigate(['/login']);

  }

  shouldEncryptApiRequest(){
    return this.apiRequest.postdata('', config['shouldApiEncryptDecrypt']);
  }

}
