import { Injectable } from '@angular/core';
import {ApiRequestService} from '../../core/services/api-request.service';
import { config } from '@core/services/request-url.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  // signUpSteps:BehaviorSubject<any> = new BehaviorSubject({ signUpUsername: '', companyTypeMobile: ''});

  corporateBaseUrl=environment.corporateBaseUrl;
  constructor(
    private apiRequest: ApiRequestService,
  ) { }

  createUser(payLoadData:any){
    return this.apiRequest.postdata(payLoadData,config.SignUp.createUser);
  }
  getCompanyList(payLoadData:any){
    return this.apiRequest.postdata(payLoadData,config.SignUp['getCompanyList']);
  }
  assignCompanyToUser(payLoadData:any){
    return this.apiRequest.postdata(payLoadData,config.SignUp['assignCompany'])
  }
  assignContactToUser(payLoadData:any){
    return this.apiRequest.postdata(payLoadData,config.SignUp['assignContact'])
  }


  validateOtp(payLoadData:any){
    return this.apiRequest.postdata(payLoadData,config.SignUp['ValidateOTP'])
  };

  createNewUser(payload: any){
    return this.apiRequest.postdata(payload, config.SignUp.createNewUser);
  };

  sendOTP(payload: any){
    return this.apiRequest.postdata(payload, config.SignUp.sendOTP);
  }

  sendEmail(payload: any){
    return this.apiRequest.postdata(payload, config.SignUp.sendEmail);
  }
  sendEmailOTP(payload: any){
    return this.apiRequest.postdata(payload, config.SignUp.sendEmailOTP);
  }
  verifyEmailOTP(payload: any){
    return this.apiRequest.postdata(payload, config.SignUp.verifyEmailOTP);
  }
  getDataFromToken(payload: any){
    return this.apiRequest.postdata(payload, config.SignUp.resellerDetails);
  }
}
