
import { SharedModule } from '@shared/shared.module';
// import { TemplateComponent } from './template.component';

import { NgModule } from '@angular/core';


import { SidebarModule } from '../components/sidebar/sidebar.module';
import { HeaderModule } from '../components/header/header.module';
import { FooterModule } from '../components/footer/footer.module';
import { ContentModule } from '../components/content/content.module';
import { TemplateComponent } from './template.component';

@NgModule({
  declarations: [
    TemplateComponent
  ],
  imports: [
    SharedModule,
    SidebarModule,
    HeaderModule,
    FooterModule,
    ContentModule,
  ],
  exports: [
    TemplateComponent
  ]
})
export class TemplateModule { }
