import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessDetailComponent } from './business-detail.component';
import { SharedModule } from '@shared/shared.module';



@NgModule({
  declarations: [BusinessDetailComponent],
  imports: [
    SharedModule
  ],
  exports:[BusinessDetailComponent]
})
export class BusinessDetailModule { }
