import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { RNFIPAY_CONFIG } from '@rnficonfig/config.service';

@NgModule({})
export class RnfiModule {
  constructor(@Optional() @SkipSelf() parentModule: RnfiModule) {
    if (parentModule) {
      throw new Error('RnfiModule is already loaded. Import it in the AppModule only!');
    }
  }

  static forRoot(config: any): ModuleWithProviders<RnfiModule> {
    return {
      ngModule: RnfiModule,
      providers: [
        {
          provide: RNFIPAY_CONFIG,
          useValue: config
        }
      ]
    };
  }
}
