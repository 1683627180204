import { BehaviorSubject, filter, Subscription } from 'rxjs';
import { ApiRequestService } from '@core/services/api-request.service';
import { Injectable, OnDestroy } from '@angular/core';
import { config } from '@core/services/request-url.service';
import { NgxToasterService } from '@core/services/toasterNgs.service';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TicklerService implements OnDestroy {

  ticklerSubscription: Subscription;
  ticklers: any;

  ticklersData: BehaviorSubject<any> = new BehaviorSubject('');

  constructor(private apiRequestService: ApiRequestService,
    private router: Router,
    private _NgxToasterService: NgxToasterService) {
      // this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((e: any) => {
      //   if (e.url.indexOf("/dashboard") != -1) {

      //     if (!this.ticklersData.getValue()) {
      //       this.getTicklers();
      //     }

      //   }
      // })

   }

   get _ticklerData() {
    return this.ticklersData.asObservable();
  };

  getTicklers() {
    const formData: FormData = new FormData()
    this.ticklerSubscription =  this.apiRequestService.postdata(formData, config.tickler).subscribe(
      {
        next: (resp: any) => {
          if(resp.statuscode == 200){
            this.ticklers = resp;
            this.ticklersData.next(resp)
            //console.log("tickler ", resp)
          } else{
            this.ticklersData.next([])
            this._NgxToasterService.showError(resp.message, "Error")
          }

        }, error: (err: any) =>{
          this._NgxToasterService.showError(err.message, "Error")
        }
      }
    );
  }

  ngOnDestroy(): void {
    if(this.ticklerSubscription){
      this.ticklerSubscription.unsubscribe();
    }
  }
}
