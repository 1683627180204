<div class="dtPagination flex-wrap" *ngIf="tableOptions.config.totalPages > 1 && !tableOptions.config.localFilterVal
  && viewTwoPagination !=='viewTwoPagination'">
    <a href="javascript:void(0);" *ngIf="tableOptions.config.currentPage > 1" (click)="tableOptions.config.currentPage > 1? _PytCustTblService.dtUpdateByPagination('first',tableOptions) : false">First</a>
    <a href="javascript:void(0);" *ngIf="tableOptions.config.currentPage > 1" (click)="tableOptions.config.currentPage > 1? _PytCustTblService.dtUpdateByPagination('back',tableOptions) : false">Previous</a>

    <!-- <a href="javascript:void(0);" [ngStyle]="{'font-size': i+1 == tableOptions.config.currentPage? '30px': 'unset'}" *ngFor="let item of [].constructor(tableOptions.config.totalPages); let i = index" (click)="tableOptions.config.currentPage != (i+1)?_PytCustTblService.dtUpdateByPagination(i+1,tableOptions) : false">
    {{i+1+''}}
</a> -->
    <div appPytCustTblPager [tableOptions]="tableOptionsCopy" [totalPages]="tableOptions.config.totalPages" [currentPage]="tableOptions.config.currentPage"></div>
    <a href="javascript:void(0);" *ngIf="tableOptions.config.totalPages > tableOptions.config.currentPage" (click)="tableOptions.config.totalPages > tableOptions.config.currentPage? _PytCustTblService.dtUpdateByPagination('next',tableOptions) : false">Next</a>
    <a href="javascript:void(0);" *ngIf="tableOptions.config.totalPages > tableOptions.config.currentPage" (click)="tableOptions.config.totalPages > tableOptions.config.currentPage? _PytCustTblService.dtUpdateByPagination('last',tableOptions) : false">Last</a>
</div>


<!-- <div class="mt-3">

  <ul id="pagination">
      <li class="viewtxt pr-4">Viewing 1-20 of 275180</li>
      <li class="arrow"><a class="" href="#"><i class="far fa-chevron-left"></i></a></li>
      <li><a href="#">1</a></li>
      <li><a href="#" class="active">2</a></li>
      <li><a href="#">3</a></li>...

      <li><a href="#">10</a></li>
      <li class="arrow"><a class="" href="#"><i class="far fa-chevron-right"></i></a></li>
    </ul>
  </div>
  </div> -->

<ul id="pagination" *ngIf="viewTwoPagination ==='viewTwoPagination' ">
  <li class="viewtxt pr-4">Viewing {{tableOptions.config.fromRec}}-{{tableOptions.config.toRec}} of
    {{tableOptions.config.totalCount}}</li>

  <li> <span [ngClass]="{'pagerMute':tableOptions.config.currentPage == 1}"
      (click)="tableOptions.config.currentPage > 1? _PytCustTblService.dtUpdateByPagination('back',tableOptions) : false">
    </span></li>

    <li class="dtPagination" *ngIf="tableOptions.config.totalPages > 1 && !tableOptions.config.localFilterVal">
      <!-- <a href="javascript:void(0);" *ngIf="tableOptions.config.currentPage > 1"
        (click)="tableOptions.config.currentPage > 1? _PytCustTblService.dtUpdateByPagination('first',tableOptions) : false">First</a> -->
      <a class="arrow" href="javascript:void(0);" *ngIf="tableOptions.config.currentPage > 1"
        (click)="tableOptions.config.currentPage > 1? _PytCustTblService.dtUpdateByPagination('back',tableOptions) : false">
        <i class="far fa-chevron-left"></i></a>

      <div class="d-flex" appPytCustTblPager [tableOptions]="tableOptionsCopy" [totalPages]="tableOptions.config.totalPages"
        [currentPage]="tableOptions.config.currentPage"></div>
      <a class="arrow" href="javascript:void(0);" *ngIf="tableOptions.config.totalPages > tableOptions.config.currentPage"
        (click)="tableOptions.config.totalPages > tableOptions.config.currentPage? _PytCustTblService.dtUpdateByPagination('next',tableOptions) : false">
        <i class="far fa-chevron-right"></i></a>
      <!-- <a href="javascript:void(0);" *ngIf="tableOptions.config.totalPages > tableOptions.config.currentPage"
        (click)="tableOptions.config.totalPages > tableOptions.config.currentPage? _PytCustTblService.dtUpdateByPagination('last',tableOptions) : false">Last</a> -->
    </li>
</ul>


