import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { CustomOverlayConfig } from './template-modal.service';

@Injectable()
export class CommonFunctionsService {

  constructor() { }
  setPositionStrategy(customConfig: CustomOverlayConfig, positionStrategy: GlobalPositionStrategy) {
    if (customConfig.size) {
      positionStrategy = positionStrategy
        .width(customConfig.size.width)
        .height(customConfig.size.height);
    }

    if (customConfig.isCentered) {
      positionStrategy = positionStrategy
        .centerHorizontally()
        .centerVertically();
    }

    if (customConfig.customOverlayPosition?.topLeft) {
      let posVal: string[] = this.setMdlPosition(customConfig.customOverlayPosition?.topLeft);

      positionStrategy = positionStrategy
        .top(posVal[0])
        .left(posVal[1]);
    }

    if (customConfig.customOverlayPosition?.topCenter) {
      let posVal: string[] = this.setMdlPosition(customConfig.customOverlayPosition?.topCenter);


      positionStrategy = positionStrategy
        .top(posVal[0])
        .centerHorizontally(posVal[1])
    }

    if (customConfig.customOverlayPosition?.topRight) {
      let posVal: string[] = this.setMdlPosition(customConfig.customOverlayPosition?.topRight);

      positionStrategy = positionStrategy
        .top(posVal[0])
        .right(posVal[1]);
    }

    if (customConfig.customOverlayPosition?.leftCenter) {
      let posVal: string[] = this.setMdlPosition(customConfig.customOverlayPosition?.leftCenter);

      positionStrategy = positionStrategy
        .centerVertically(posVal[0])
        .left(posVal[1]);
    }

    if (customConfig.customOverlayPosition?.rightCenter) {
      let posVal: string[] = this.setMdlPosition(customConfig.customOverlayPosition?.rightCenter);

      positionStrategy = positionStrategy
        .centerVertically(posVal[0])
        .right(posVal[1]);
    }

    if (customConfig.customOverlayPosition?.leftBottom) {
      let posVal: string[] = this.setMdlPosition(customConfig.customOverlayPosition?.leftBottom);

      positionStrategy = positionStrategy
        .bottom(posVal[0])
        .left(posVal[1]);
    }

    if (customConfig.customOverlayPosition?.bottomCenter) {
      let posVal: string[] = this.setMdlPosition(customConfig.customOverlayPosition?.bottomCenter);

      positionStrategy = positionStrategy
        .bottom(posVal[0])
        .centerHorizontally(posVal[1]);
    }

    if (customConfig.customOverlayPosition?.bottomRight) {
      let posVal: string[] = this.setMdlPosition(customConfig.customOverlayPosition?.bottomRight);

      positionStrategy = positionStrategy
        .bottom(posVal[0])
        .right(posVal[1]);
    }
    return positionStrategy;
  }

  setMdlPosition(posVal: [string, string] | boolean): string[] {
    let firstValue: string = '0px';
    let SecondValue: string = '0px';
    if (posVal.constructor === Array) {
      firstValue = posVal[0];
      SecondValue = posVal[1];
    }
    return [firstValue, SecondValue]
  }

  setMdlAnimation(customConfig: any, custPanelClass: any) {
    let panelCls: any = custPanelClass;
    if (customConfig?.isAnimation) {
      let updateVal: any = ['animate__animated', custPanelClass]
      let customVal: any = customConfig?.isAnimation?.inAnimationStyle;
      if (customVal) {
        if (customVal.constructor === Array) {
          customVal.forEach(element => {
            updateVal.push('animate__' + element)
          });
        } else {
          updateVal.push('animate__' + customVal)
        }
      }
      panelCls = updateVal;

    }
    return panelCls;
  }
  _updateDetails(orginal: any, update: any) {
    let data = orginal.filter((e: any, index: number) => {
      // update[index]['RequestId'] = user?.userId + '_' + this._commonService.UniqueRequestId()
      return _.merge(e, update[index]);
    });
    return data;
  }
  objectReplace(original: any, current: any) {
    return _.merge(original, _.pick(current, _.keys(original)));
  }
  replaceAllObjKeys = (obj, getNewKey) => {

    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        this.replaceAllObjKeys(obj[i], getNewKey[i]);
      }
    }
    else if (typeof obj === "object") {
      for (const key in obj) {
        let ele = obj[key];
        if ((typeof ele === "object" || Array.isArray(ele)) && getNewKey.hasOwnProperty(key)) {
          this.replaceAllObjKeys(ele, getNewKey[key]);
        }
        else {
          obj[key] = getNewKey[key];
          // if (getNewKey.hasOwnProperty(key)) {
          // }else{

          // }
        }
        // const newKey = getNewKey(key);
        // obj[newKey] = obj[key];
        // if (key !== newKey) {
        //   delete obj[key];
        // }
        // this.replaceAllObjKeys(obj[newKey], getNewKey);
      }
    }

    return obj;
  };
}
