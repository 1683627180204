import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { PartnerDocumentsComponent } from './partner-documents.component';
import { CustomOtpModule } from '@features/custom-otp/custom-otp.module';
import { CdkCustomMdlModule } from '@features/customCDK/cdkCustomMdl/cdk-custom-mdl.module';



@NgModule({
  declarations: [PartnerDocumentsComponent],
  imports: [
    SharedModule,
    CustomOtpModule,
    CdkCustomMdlModule
  ],
  exports:[PartnerDocumentsComponent]
})
export class PartnerDocumentsModule { }
