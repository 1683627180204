import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  OnChanges,
  HostListener,
  ViewEncapsulation,
  AfterContentInit,
} from '@angular/core'
import {
  Chart,
  ChartConfiguration,
  ChartType,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export interface chartData {
  label: string;
  value: number;
  color: string;
  val?: number;
}

@Component({
  selector: 'polar-chart',
  templateUrl: './dyn-polar-chart.component.html',
  styleUrls: ['./dyn-polar-chart.component.scss'],
  // encapsulation: ViewEncapsulation.Emulated
})
export class DynPolarChartComponent implements OnInit, OnChanges {
  options: any;
  title = 'ng2-charts-demo';
  chart: any;

  realData: any;
  innerFontSize: any;

  // PolarArea
  public polarAreaChartLabels: string[] = [
    'Download Sales',
    'In-Store Sales',
    'Mail Sales',
    'Telesales',
    'Corporate Sales',
  ];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // console.log("onResize ",this.innerFontSize);
    // console.log("onResize ",this.options);
    // this.options.data.datasets[0].datalabels.labels.value.font()
    // console.log("fontttt ", font)
  }

  @Input('chartData') data: chartData[] = [
    {
      label: 'one',
      value: 35,
      color: 'red',
      val: 1,
    },
    {
      label: 'two',
      value: 21,
      color: 'blue',
      val: 1.5,
    },
    {
      label: 'three',
      value: 31,
      color: 'orange',
      val: 1.5 * 1.5,
    },
    {
      label: 'four',
      value: 41,
      color: 'green',
      val: 1.5 * 1.5 * 1.5,
    },
  ];

  // Create a new chart with dataLabels
  public polarAreaChartData: number[] = [300, 500, 100, 40, 120];

  public polarAreaLegend = true;

  public polarAreaChartType: ChartType = 'polarArea';

  @ViewChild('polarArea')
  polarAreaChart!: ElementRef;
  showChart: boolean;

  compare(a: any, b: any) {
    if (a.value < b.value) {
      return 1;
    }
    if (a.value > b.value) {
      return -1;
    }
    return 0;
  }

  constructor() {

   }

  ngOnInit() {
    Chart.register(ChartDataLabels);
  }

   pieLabelsLine = {
    id: 'pieLabelsLine',
    afterDraw(chart: any, easing: any) {

      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;
      const fontSize: any = (height / 114).toFixed(2);
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = 'middle';
       // console.log("font ", ctx)
      const cx = chart._metasets[0].data[0].x;
      const cy = chart._metasets[0].data[0].y;

      const sum = chart.data.datasets[0].data.reduce(
        (a: any, b: any) => a + b,
        0
      );

      chart.data.datasets.forEach((dataset: any, i: any) => {
        // console.log("data set ", dataset);
        const meta = chart.getDatasetMeta(i);
        for (const datapoint of chart.getDatasetMeta(i).data) {
          // console.log("loop ", datapoint)

          let percent = (datapoint.$context.parsed.r * 100) / sum;
          percent = Math.round(percent * 100) / 100;
          if (percent < 2 || datapoint.$context.parsed.r <= 0) continue;
          if (datapoint < 1) continue;
          const index: string | number = chart
            .getDatasetMeta(i)
            .data.indexOf(datapoint);
          const { x: a, y: b } = datapoint.tooltipPosition();

          const x = 2 * a - cx;
          const y = 2 * b - cy;

          const center = {
            x: width / 2,
            y: width / 2,
          };

          //  draw a circle in center of the chart
          ctx.beginPath();
          ctx.arc(center.x, center.y, (width * 25 / 100) / 4, 0, 2 * Math.PI);
          ctx.fillStyle = 'white';
          //shadow
          ctx.shadowColor = '#00000026';
          ctx.shadowBlur = 5;
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 0;
          ctx.lineWidth = 1;
          ctx.shadow = '#999999';
          ctx.fill();
          ctx.closePath();
          ctx.innerFontSize =  ~~(width*3 / 100).toFixed(0)
          //  draw a hollow circle in center of the chart
          ctx.beginPath();
          ctx.arc(center.x, center.y, (width * 15 / 100) / 4, 0, 2 * Math.PI);
          ctx.strokeStyle = 'grey';
          ctx.globalAlpha = 0.5;
          ctx.lineWidth = 3;
          ctx.stroke();
          ctx.globalAlpha = 1;
          ctx.closePath();


          // draw line
          const halfwidth = width / 2;
          const halfheight = height / 2;
          const xLine = x >= halfwidth ? x + 20 : x - 20;
          const yLine = y >= halfheight ? y + 20 : y - 20;

          const extraLine = x >= halfwidth ? 10 : -10;

          ctx.beginPath();
          ctx.moveTo(x, y);
          ctx.lineWidth = 3;
          ctx.arc(x, y, 2, 0, 2 * Math.PI, true);
          ctx.fill();
          ctx.moveTo(x, y);
          ctx.lineTo(xLine, yLine);
          ctx.lineTo(xLine + extraLine, yLine);
          ctx.strokeStyle = dataset.backgroundColor[index];
          // ctx.strokeStyle = "black";
          ctx.stroke();


          // text
          const textWidth = ctx.measureText(chart.data.labels[index]).width;
          const size = ctx?.innerFontSize;
          ctx.font = size+'px Arial';
          // control the position
          const textXPosition = x >= halfwidth ? 'left' : 'right';
          const plusFivePx = x >= halfwidth ? 5 : -5;
          ctx.textAlign = textXPosition;
          ctx.textBaseline = 'middle';
          // ctx.fillStyle = dataset.backgroundColor[index];
          ctx.fillStyle = 'white';

          // set padding to keep text inside the canvas
          const padding = 5;
          const left = padding;
          const right = width - textWidth - padding;
          const top = padding;
          const bottom = height - padding;
          const xText =
            x >= halfwidth
              ? Math.max(left, xLine + extraLine + 5)
              : Math.min(right, xLine + extraLine - 5);
          const yText =
            y >= halfheight ? Math.max(top, yLine) : Math.min(bottom, yLine);
          //    fill text with line breaks for space
          const text = chart.data.labels[index];

          let textLines = "";
          if(text && text.includes(" "))   textLines = text.split(" "); else textLines = "";
          const lineHeight = 3.5;

          if(textLines == "")
            ctx.fillText(text, xText, yText);
          else {
            const textHeight = textLines.length * lineHeight;
          for (let i = 0; i < textLines.length; i++) {
            ctx.fillText(textLines[i], xText, yText + (i - textLines.length / 2) * lineHeight * fontSize);
          }
        }

        }
      });

    },
  };



  ngAfterViewInit() {
    this.data.sort(this.compare);
    // console.log(this.data);
    let val = 0;
    let mul = Math.pow(1.1, this.data.length);
    if (
      this.data.every((item: any) => {
        val = item.value;
        return item.value == 0;
      })
    ) {
      mul = 0;
    }

    if (
      this.data.every((item: any) => {
        return item.value == val && mul != 0;
      })
    ) {
      mul = 0.5;
    }

    for (let i = 0; i < this.data.length; i++) {
      if (i != 0 && this.data[i].value != this.data[i - 1].value) {
        mul = mul / 1.1;
      }
      if (i == 0) mul = mul / 1.1;
      this.data[i].val = mul;
    }

    this.data.push(this.data.shift()!);

    this.realData = this.data.map((item: any) => item.value);
    // console.log(this.realData);
    // const that = this.pieLabelsLine
    this.options = {
      type: 'polarArea',
      data: {
        labels: this.data.map((d: any) => d.label),
        datasets: [
          {
            label: '# of Score',
            data: this.data.map((d: any) => d.val),
            datalabels: {
              labels: {

                value: {
                  align: 'end',
                  backgroundColor: function (ctx: any) {
                    var value = ctx.dataset.data[ctx.dataIndex];
                    return value > 50 ? 'white' : null;
                  },
                  borderColor: 'transparent',
                  borderWidth: 0,
                  borderRadius: 30,
                  color: function (ctx: any) {
                    var value = ctx.dataset.data[ctx.dataIndex];
                    return 'white';
                  },
                  font:function(ctx: any){
                      const fontSize: any = ~~(ctx?.chart?.height*3/ 100).toFixed(0)
                      return {size:fontSize}
                  },

                  formatter: (value: any, ctx: any) => {
                    let sum = 0;
                    let count = 0;
                    let dataArr = this.realData;
                    dataArr.map(function (data: any) {
                      sum += data;
                    });

                    if (sum == 0 ) {
                      return 'No Data';
                    }

                    let percentage: any = ((this.realData[ctx.dataIndex] * 100) / sum).toFixed(1);
                    if(percentage == 'NaN') return percentage =  'No Data'
                    return percentage + '%';
                  },
                },
              },
            },

            backgroundColor: this.data.map((d: any) => d.color),
          },
        ],
      },

      plugins: [ChartDataLabels, this.pieLabelsLine],
      options: {
        maintainAspectRatio: true,
        elements: {
          arc: {
            borderWidth: 0,
          },
          line: {
            borderWidth: 0,
          },
          point: {
            borderWidth: 0,
          },
          rectangle: {
            borderWidth: 0,
          },
          // layout: {
          //   padding: {
          //     left: 50
          //   }
          // },
          //  make the labels inside the chart
          center: {
            text: '100%',
          },

          plugins: {
            tooltip: {
              enabled: false,
            },

          },
        },
        labels: {
          display: false,
        },
        legend: {
          display: false,
        },

        scales: {
          r: {
            pointLabels: {
              display: false,
              centerPointLabels: false,
            },
            ticks: {
              min: 20,
              max: 70,
              display: true,
              color: 'transparent',
              backdropColor: 'transparent',
            },
            grid: {
              display: false,
            },
          },
        },
        responsive: true,
        display: true,
        plugins: {
          legend: {
            display: false,
          },

        },
        formatter: (value: any, ctx: any) => {
          // console.log("label valueds ", value)
        },
      },

    };

    //programatically create the canvas
    const canvas = document.createElement('canvas');
    canvas.id = 'myChart';
    canvas.width =
      this.polarAreaChart.nativeElement.parentNode.offsetWidth > 500
        ? 500
        : this.polarAreaChart.nativeElement.parentNode.offsetWidth;
    canvas.height = 299;
    const ctx = canvas.getContext('2d');
    this.polarAreaChart.nativeElement.appendChild(canvas);

    // console.log(ctx);
    this.chart = new Chart(canvas, this.options);

    this.chart.options.plugins.tooltip.enabled = false;
    // console.log(this.chart.options)
    this.chart.options.layout.padding = {
      left: 37,
      right: 36,
    };
    this.chart.update();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes == undefined) {
    } else {
      if (changes['data']) {
        this.data = changes['data'].currentValue;
        // console.log("data ngchange",this.data)
      }
    }
    this.data.sort(this.compare);
    let mul = Math.pow(1.1, this.data.length);

    if (this.data.every((item: any) => item.value == 0)) {
      mul = 0;
    }

    for (let i = 0; i < this.data.length; i++) {
      if (i != 0 && this.data[i].value != this.data[i - 1].value) mul /= 1.1;
      if (i == 0) mul / 1.1;
      this.data[i].val = mul;
    }
    this.data.push(this.data.shift()!);

    if (this.chart) {
      this.chart.data.datasets[0].data = this.data.map((d: any) => d.val);
      this.realData = this.data.map((d: any) => d.value);

      this.chart.data.labels = this.data.map((d: any) => d.label);
      this.chart.data.datasets[0].backgroundColor = this.data.map(
        (d: any) => d.color
      );
      //set the chart padding to keep the labels inside the chart
      this.chart.options.layout.padding = {
        left: 37,
        right:36,
      };
      this.chart.update();
    }
  }


}
