<div class="tab-pane ">
  <form>
    <div class="kyc-box" style="min-height: 435px;">
      <div class="kyc-heading pb-4">Contact Details</div>
      <div class="row mt-3">
        <div class="col-12 col-lg-6">
          <div class="floating-label">
            <input class="floating-input form-control" type="text" name="uName" [(ngModel)]="contactDetail.name"
              disabled placeholder=" ">
            <span class="highlight"></span>
            <label class="p-2">Contact Name <span class="text-danger star">*</span></label>
          </div>
        </div>


        <div class="col-12 col-lg-6">
          <div class="floating-label">
            <input class="floating-input form-control" type="text" name="phone" [(ngModel)]="contactDetail.phone"
              disabled placeholder=" ">
            <div class="input-group-append">
              <ng-container *ngIf="contactDetail.phone_verify else verify ">
                <i class="fas fa-check-circle check"></i>
              </ng-container>
              <ng-template #verify>
                <i class="fas fa-times-circle uncheck"></i>
              </ng-template>
            </div>
            <span class="highlight"></span>
            <label class="p-2">Phone Number <span class="text-danger star">*</span></label>
          </div>

        </div>


        <div class="col-12 col-lg-6">
          <div class="floating-label">
            <input class="floating-input form-control" type="text" name="email" [(ngModel)]="contactDetail.email"
              disabled placeholder=" ">
            <div class="input-group-append">
              <ng-container *ngIf="contactDetail.email_verify else verify ">
                <i class="fas fa-check-circle check"></i>
              </ng-container>
              <ng-template #verify>
                <i class="fas fa-times-circle uncheck"></i>
              </ng-template>
            </div>
            <span class="highlight"></span>
            <label class="p-2">Email ID <span class="text-danger star">*</span></label>
            <div class="text-danger" *ngIf="!contactDetail.email_verify">
              Please verify your email first !</div>
          </div>

        </div>
        <div class="col-12 col-lg-6" *ngIf="!contactDetail.email_verify">
          <button type="button" class="btn login-btn" style="width:150px ;" (click)="onVerify()">Verify Email</button>

        </div>
        <div class="col-12 col-lg-12">
          <div class="row">
            <div class="col-12 col-lg-6">
              <label class="title-con">Please give consensus for</label>
              <div class="consensus-main p-4">
                <div class="form-group form-check pl-0">
                  <label class="form-check-label" for="exampleCheck1">1. Cipher Connect</label>
                  <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="resellerDetails.cb_requested"
                  (ngModelChange)="updateReseAggre(resellerDetails.cb_requested,'cb')"  [ngModelOptions]="{standalone: true}" [disabled]="!contactDetail.email_verify || contactDetail.kyc_step > 2">
                </div>

                <div class="form-group form-check pl-0">
                  <label class="form-check-label" for="exampleCheck1">2. Cipher Payout</label>
                  <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="resellerDetails.payout_requested"
                  (ngModelChange)="updateReseAggre(resellerDetails.payout_requested,'payout')"
                  [ngModelOptions]="{standalone: true}" [disabled]="!contactDetail.email_verify || contactDetail.kyc_step > 2">
                </div>

<!--                <div class="form-group form-check pl-0">-->
<!--                  <label class="form-check-label" for="exampleCheck1">3. Cipher UPI Collect</label>-->
<!--                  <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="resellerDetails.upi_requested"-->
<!--                  (ngModelChange)="updateReseAggre(resellerDetails.upi_requested,'upi')" [ngModelOptions]="{standalone: true}" [disabled]="!contactDetail.email_verify || contactDetail.kyc_step > 2">-->
<!--                </div>-->

                <div class="form-group form-check pl-0" *ngIf="contactDetail.user_type !== 7">
                  <label class="form-check-label" for="exampleCheck1">4. Cipher Reseller</label>
                  <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="resellerDetails.reseller_requested"
                  (ngModelChange)="updateReseAggre(resellerDetails.reseller_requested, 'reseller')" [ngModelOptions]="{standalone: true}" [disabled]="!contactDetail.email_verify || contactDetail.kyc_step > 2">
                </div>

                <div class="form-group form-check pl-0" *ngIf="contactDetail.user_type !== 7">
                  <label class="form-check-label" for="exampleCheck1">5. Cipher Aggregator</label>
                  <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="resellerDetails.aggregator_requested"
                  (ngModelChange)="updateReseAggre(resellerDetails.aggregator_requested,'aggregator')" [ngModelOptions]="{standalone: true}"
                  [disabled]="!contactDetail.email_verify || contactDetail.kyc_step > 2">
                </div>

              </div>
            </div>
            <!-- <div class="col-12 col-lg-6">
                        <label class="p-2">Reseller</label>
                        <app-slider-toggle [(ngModel)]="resellerDetails.reseller_requested" (ngModelChange)="updateReseAggre(resellerDetails.reseller_requested, 'reseller')"
                         [ngModelOptions]="{standalone: true}"></app-slider-toggle>
                    </div>
                    <div class="col-12 col-lg-6">
                        <label class="p-2">Aggregator</label>
                        <app-slider-toggle [(ngModel)]="resellerDetails.aggregator_requested" (ngModelChange)="updateReseAggre(resellerDetails.aggregator_requested,'aggregator')"
                         [ngModelOptions]="{standalone: true}"></app-slider-toggle>
                    </div>

                    <div class="col-12 col-lg-6">
                      <label class="p-2">UPI Collect</label>
                      <app-slider-toggle [(ngModel)]="resellerDetails.upi_requested" (ngModelChange)="updateReseAggre(resellerDetails.upi_requested,'upi')"
                       [ngModelOptions]="{standalone: true}"></app-slider-toggle>
                  </div> -->
          </div>
        </div>

        <div class="col-12 d-flex justify-content-end">
          <button type="button" class="btn save-btn"
            [disabled]="!contactDetail.email_verify || contactDetail.kyc_step > 2"
            (click)="saveContactDetail()">Next</button>
        </div>
      </div>

    </div>
  </form>
</div>
<ng-template cdkPortal #modalTemplate="cdkPortal">
  <app-custom-otp [otpBox]="4" [title]="'Verify Your Email.'" [message]="'OTP sent to your registered email ID.'"
    [submitBtnName]="'Verify Email'" (sendOtp)="verifyOtp($event)" (resendOtp)="sendOTP(true)"
    (closeTemplateModal)="closeTemplateModal()" [submitBtnClass]="'btn btn-primary'"></app-custom-otp>
</ng-template>
