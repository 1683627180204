import {RelipayConfig} from '@core/types'


export const Relipayconfig: RelipayConfig = {
  colorTheme: '#67899',
  customScrollbars: true,
  layout: {
    style: 'relipay-distributer-template',
    width: 'boxed',
    navbar: {
      hidden: false,
    },
    header: {
      background: '#67899',
      hidden: false,
    },
    news: {
      background: '#67899',
      hidden: false,
    },
    footer: {
      background: '#67899',
      hidden: false,
    },
    sidepanel: {
      hidden: false,
    },
    content: {
      hidden: false,
    },
    slider: {
      hidden: false,
    },
  }
}
