<div class="tab-pane " role="tabpanel" *ngIf="showAddPartner">
  <form >
      <div class="kyc-box">
          <div class="kyc-heading pbc-4">Kyc Form
              <p>{{configDynamicField.heading}} </p>
              <div class="col-12 d-flex justify-content-end mt-button-5" *ngIf="kyc_data.is_partner > 1 && allDirectorList.length > 0 && kyc_data.kyc_status != 1 ">
                  <button class="btn add-button " type="button" (click)="onClickAddMore()">Add Directors</button>
              </div>
          </div>
          <div class="row">
              <div [ngClass]="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT' ?'col-4':'col-6' " >
                  <div class="floating-label">
                    <input class="floating-input form-control" type="text" [disabled]="partnersDocumentDetail.partner_file_url != 'assets/assets/img/upload_file.png'" [(ngModel)]="partnersDocumentDetail.name" name="partnerName">
                    <span class="highlight"></span>
                    <label class="p-2">{{configDynamicField.nameLabel}} <span class="text-danger star">*</span></label>
                </div>
              </div>
              <div [ngClass]="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT' ?'col-8':'col-6' " >
                <div class="floating-label">
                  <input class="floating-input form-control" type="text"  [(ngModel)]="partnersDocumentDetail.mobile" [appInputRestriction]="'integer'"
                  [disabled]="partnersDocumentDetail.partner_file_url != 'assets/assets/img/upload_file.png'"
                  #mobileno="ngModel" name="mobileno" id="mobileno" required minlength="10" maxlength="10" pattern="^[456789][0-9]{9}$">
                  <span class="highlight"></span>
                  <label class="p-2">{{configDynamicField.mobileNumber}} <span class="text-danger star">*</span></label>
                  <div *ngIf="mobileno.invalid && (mobileno.dirty || mobileno.touched)" style="color: red;">
                    <div *ngIf="mobileno.errors?.['required']">
                      Mobile Number is required.
                    </div>
                    <div *ngIf="mobileno.errors?.['minlength'] || mobileno.errors?.['maxlength'] || mobileno.errors?.['pattern']">
                      Please Enter at least 10 digit valid Mobile number.
                    </div>

                  </div>
                </div>
              </div>
              <div [ngClass]="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT' ?'col-4':'col-6' " >
                <div class="floating-label">
                  <input class="floating-input form-control" type="text"  [(ngModel)]="partnersDocumentDetail.email" name="email_id"
                  [disabled]="partnersDocumentDetail.partner_file_url != 'assets/assets/img/upload_file.png'"
                  #emailid="ngModel" name="emailid" id="emailid" required  pattern="^\S+@\S+\.[A-Za-z]\S+$" >
                  <span class="highlight"></span>
                  <label class="p-2">{{configDynamicField.emailId}} <span class="text-danger star">*</span></label>
                  <div *ngIf="emailid.invalid && (emailid.dirty || emailid.touched)" style="color: red;">
                    <div *ngIf="emailid.errors?.['required']">
                      Email id is required.
                    </div>
                    <div *ngIf="emailid.errors?.['pattern']">
                      Please Enter Valid Email id
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3" [ngClass]="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT' ?'col-8':'col-6' ">
                  <div class="floating-label">
                      <select class="floating-select form-control" (change)="onAddressChange()" [disabled]="!enableText && isShow" [(ngModel)]="partnersDocumentDetail.address_proof" name="addressProof" id="addressProof">
                        <option value="" selected>Choose any Address Proof</option>
                        <option *ngFor="let val of addressTypeList" [value]="val.value">{{val.name}}</option>
                       </select>
                      <!-- <span class="highlight"></span> -->
                      <label class="floating-label-label text-black" >{{configDynamicField.addressNameLabel}}  <span class="text-danger star">*</span></label>

                  </div>
              </div>
              <div [ngClass]="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT' ?'col-12 col-lg-4':'col-12 col-lg-6' "  *ngIf="partnersDocumentDetail.status == '' || partnersDocumentDetail.status == '4' || !partnersDocumentDetail.status || partnersDocumentDetail.partner_file_url == 'assets/assets/img/upload_file.png' || !partnersDocumentDetail.partner_file_url ">
                  <p>{{configDynamicField.panCardHeading}}<span class="text-danger star">*</span></p>
                  <div class="csv-drag" appFileUpload (fileDropped)="onPartnerFileChange($event,4,'a',partnersDocumentDetail.partner_file_url)">
                      <!-- a for authorised signatory and d for director -->
                      <div class="drop-zone-prompt">
                          <img [src]="partnersDocumentDetail.partner_file_url" class="mb-4"><br>
                          <input type="file" #fileDropRef10 id="fileDropRef10" (change)="onPartnerFileChange($event,4,'a',partnersDocumentDetail.partner_file_url)" accept=".png, .jpg, .jpeg"
                            [disabled]="pan_verify" />
                          <p style="font-size: 11px;" class="mb-0">Allowed Format - JPG, JPEG, PNG </p>
                          <p style="font-size: 11px;">Max File Size 2MB</p>
                          <p>Drop file here or <span>Click to Upload</span></p>
                      </div>
                  </div>
                  <a class="document" *ngIf="partnersDocumentDetail.partner_file_url != 'assets/assets/img/upload_file.png'" (click)="viewDoc(partnersDocumentDetail.partner_file_url)">View Document</a>
              </div>
              <div [ngClass]="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT' ?'col-12 col-lg-4':'col-12 col-lg-6' "  *ngIf="partnersDocumentDetail.status && partnersDocumentDetail.status != '4' && partnersDocumentDetail.partner_file_url != 'assets/assets/img/upload_file.png' && partnersDocumentDetail.partner_file_url != null ">
                <p>Pan Card </p>
                <a class="document" (click)="viewDoc(partnersDocumentDetail.partner_file_url)">
                      <img [src]="partnersDocumentDetail.partner_file_url" class="mb-4" style="height: auto !important;min-width: 300;max-width: 90%;">
                  </a>

              </div>
              <div [ngClass]="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT' ?'col-12 col-lg-4':'col-12 col-lg-6' "  *ngIf="partnersDocumentDetail.status == '' || partnersDocumentDetail.status == '4' || !partnersDocumentDetail.status || partnersDocumentDetail.partner_file_url1 == 'assets/assets/img/upload_file.png' || !partnersDocumentDetail.partner_file_url1 ">
                  <p>{{partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT'? "Upload "+partnersDocumentDetail.address_proof+" front side ":'Upload  Address Proof'}}<span class="text-danger star">*</span>  </p>
                  <div class="csv-drag" appFileUpload (fileDropped)="onPartnerFileChange($event,5,'a',partnersDocumentDetail.partner_file_url1)">
                      <!-- a for authorised signatory and d for director -->
                      <div class="drop-zone-prompt">
                          <img [src]="partnersDocumentDetail.partner_file_url1" class="mb-4"><br>
                          <input type="file" #fileDropRef11 id="fileDropRef11" (change)="onPartnerFileChange($event,5,'a',partnersDocumentDetail.partner_file_url1)" accept=".png, .jpg, .jpeg" />
                          <p style="font-size: 11px;" class="mb-0">Allowed Format - JPG, JPEG, PNG </p>
                          <p style="font-size: 11px;">Max File Size 2MB</p>
                          <p>Drop file here or <span>Click to Upload</span></p>
                          <!-- <label for="fileDropRef11">Browse for file</label> -->
                      </div>
                  </div>
                  <a class="document" *ngIf="partnersDocumentDetail.partner_file_url1 != 'assets/assets/img/upload_file.png'" (click)="viewDoc(partnersDocumentDetail.partner_file_url1)">View Document</a>
              </div>
              <div [ngClass]="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT' ?'col-12 col-lg-4':'col-12 col-lg-6' " *ngIf="partnersDocumentDetail.status && partnersDocumentDetail.status != '4' &&  partnersDocumentDetail.partner_file_url1 != 'assets/assets/img/upload_file.png' ">
                <p>{{partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT'? partnersDocumentDetail.address_proof+" front side ":partnersDocumentDetail.address_proof == 'LICENCE'?'Driving License':partnersDocumentDetail.address_proof}}</p>
                <a class="document" (click)="viewDoc(partnersDocumentDetail.partner_file_url1)">
                      <img [src]="partnersDocumentDetail.partner_file_url1" class="mb-4" style="height: auto !important;min-width: 300;max-width: 90%;">
                  </a>

              </div>
              <ng-container *ngIf="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT'">
                <div [ngClass]="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT' ?'col-12 col-lg-4':'col-12 col-lg-6' " *ngIf="partnersDocumentDetail.status == '' || partnersDocumentDetail.status == '4' || !partnersDocumentDetail.status || partnersDocumentDetail.partner_file_url2 == 'assets/assets/img/upload_file.png' || !partnersDocumentDetail.partner_file_url2 ">
                  <p>Upload {{partnersDocumentDetail.address_proof}} Back side <span class="text-danger star">*</span></p>
                  <div class="csv-drag" appFileUpload (fileDropped)="onPartnerFileChange($event,6,'a',partnersDocumentDetail.partner_file_url2)">
                      <!-- a for authorised signatory and d for director -->
                      <div class="drop-zone-prompt">
                          <img [src]="partnersDocumentDetail.partner_file_url2" class="mb-4"><br>
                          <input type="file" #fileDropRef12 id="fileDropRef12" (change)="onPartnerFileChange($event,6,'a',partnersDocumentDetail.partner_file_url2)" accept=".png, .jpg, .jpeg" />
                          <p style="font-size: 11px;" class="mb-0">Allowed Format - JPG, JPEG, PNG </p>
                          <p style="font-size: 11px;">Max File Size 2MB</p>
                          <p>Drop file here or <span>Click to Upload</span></p>
                          <!-- <label for="fileDropRef11">Browse for file</label> -->
                      </div>
                  </div>
                  <a class="document" *ngIf="partnersDocumentDetail.partner_file_url2 != 'assets/assets/img/upload_file.png'" (click)="viewDoc(partnersDocumentDetail.partner_file_url2)">View Document</a>
              </div>
              <div [ngClass]="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT' ?'col-12 col-lg-4':'col-12 col-lg-6' " *ngIf="partnersDocumentDetail.status && partnersDocumentDetail.status != '4' && partnersDocumentDetail.partner_file_url2 != 'assets/assets/img/upload_file.png' ">
                <p>{{partnersDocumentDetail.address_proof}} Back side </p>
                <a class="document" (click)="viewDoc(partnersDocumentDetail.partner_file_url2)">
                      <img [src]="partnersDocumentDetail.partner_file_url2" class="mb-4" style="height: auto !important;min-width: 300;max-width: 90%;">
                  </a>

              </div>
              </ng-container>

              <label class="remarks">{{partnersDocumentDetail.status == '1' ?'': partnersDocumentDetail.remarks ? partnersDocumentDetail.remarks:'Details should be of the mentioned business only'}}</label>

          </div>
          <div class="row" *ngIf="isPartnerRejected || !isShowSaveNextButton || kyc_steps == 8 ">
              <div class="col-12 d-flex justify-content-end " >
                  <button type="button" class="btn save-btn" (click)="SaveAndNext()" [disabled]="(partnersDocumentDetail.partner_file_url == 'assets/assets/img/upload_file.png' || partnersDocumentDetail.partner_file_url1  == 'assets/assets/img/upload_file.png' || (partnersDocumentDetail.partner_file_url2  == 'assets/assets/img/upload_file.png' && (partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT')) || disableNextButton )">Next</button>
              </div>
          </div>
      </div>
  </form>
</div>

<div class="tab-pane " role="tabpanel" *ngIf="!showAddPartner">
  <form >
      <div class="kyc-box">
          <div class="kyc-heading pbc-4">Kyc Form
              <p>{{configDynamicField.heading}}</p>
              <div class="col-12 d-flex justify-content-end mt-button-5">
                  <button class="btn add-button " type="button" (click)="onClickBack()">Back</button>

              </div>
          </div>
          <div class="row ">
              <div [ngClass]="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT' ?'col-4':'col-6' " >
                  <div class="floating-label">
                      <input class="floating-input form-control" type="text" [(ngModel)]="partnersDocumentDetail.name" name="partnerName" [disabled]="isHiddenPan" >
                      <span class="highlight"></span>
                      <label class="p-2">{{configDynamicField.nameLabel}}<span class="text-danger star">*</span></label>
                  </div>
              </div>
              <div [ngClass]="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT' ?'col-8':'col-6' " >
                <div class="floating-label">
                  <input class="floating-input form-control" type="text"  [(ngModel)]="partnersDocumentDetail.mobile" [appInputRestriction]="'integer'"
                  [disabled]="isHiddenPan"
                  #mobileno="ngModel" name="mobileno" id="mobileno" required minlength="10" maxlength="10" pattern="^[456789][0-9]{9}$">
                  <span class="highlight"></span>
                  <label class="p-2">{{configDynamicField.mobileNumber}}<span class="text-danger star">*</span></label>
                  <div *ngIf="mobileno.invalid && (mobileno.dirty || mobileno.touched)" style="color: red;">
                    <div *ngIf="mobileno.errors?.['required']">
                      Mobile Number is required.
                    </div>
                    <div *ngIf="mobileno.errors?.['minlength'] || mobileno.errors?.['maxlength'] || mobileno.errors?.['pattern']">
                      Please Enter at least 10 digit valid Mobile number.
                    </div>
                  </div>
              </div>
            </div>
            <div [ngClass]="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT' ?'col-4':'col-6' " >
              <div class="floating-label">
                <input class="floating-input form-control" type="text"  [(ngModel)]="partnersDocumentDetail.email" name="email_id"
                [disabled]="isHiddenPan"
                #emailid="ngModel" name="emailid" id="emailid" required  pattern="^\S+@\S+\.[A-Za-z]\S+$" >
                <span class="highlight"></span>
                <label class="p-2">{{configDynamicField.emailId}}<span class="text-danger star">*</span></label>
                <div *ngIf="emailid.invalid && (emailid.dirty || emailid.touched)" style="color: red;">
                  <div *ngIf="emailid.errors?.['required']">
                    Email id is required.
                  </div>
                  <div *ngIf="emailid.errors?.['pattern']">
                    Please Enter Valid Email id
                  </div>
                </div>
            </div>
          </div>

              <div class="mb-3" [ngClass]="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT' ?'col-8':'col-6' ">
                  <!-- <p class="mb-5"><u>{{configDynamicField.addressHeading}}</u></p> -->
                  <div class="floating-label">
                      <select class="floating-select form-control" (change)="onAddressChange()" [disabled]="showHideDirectorAddressProof"
                       [(ngModel)]="partnersDocumentDetail.address_proof" name="addressProof" id="addressProof">
                        <!-- <option value="" selected>Choose any Address Proof</option> -->
                        <option *ngFor="let val of addressTypeList" [value]="val.value">{{val.name}}</option>
                      </select>
                      <span class="highlight"></span>
                      <label class="p-2 " >{{configDynamicField.addressNameLabel}}<span class="text-danger star">*</span></label>
                  </div>
              </div>
              <div [ngClass]="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT' ?'col-12 col-lg-4':'col-12 col-lg-6' ">
                  <p>{{configDynamicField.panCardHeading}}<span class="text-danger star">*</span></p>
                  <div class="csv-drag" appFileUpload (fileDropped)="onPartnerFileChange($event,4,'d',partnersDocumentDetail.partner_file_url)">
                      <!-- a for authorised signatory and d for director -->
                      <div class="drop-zone-prompt">
                          <img [src]="partnersDocumentDetail.partner_file_url" class="mb-4" ><br>
                          <input type="file" #fileDropRef10 id="fileDropRef10" (change)="onPartnerFileChange($event,4,'d',partnersDocumentDetail.partner_file_url)"
                           accept=".png, .jpg, .jpeg" [disabled]="isHiddenPan" />
                           <p style="font-size: 11px;" class="mb-0">Allowed Format - JPG, JPEG, PNG </p>
                            <p style="font-size: 11px;">Max File Size 2MB</p>
                          <p>Drop file here or <span>Click to Upload</span></p>
                      </div>
                  </div>
                  <a class="document" *ngIf="partnersDocumentDetail.partner_file_url != 'assets/assets/img/upload_file.png'"
                  (click)="viewDoc(partnersDocumentDetail.partner_file_url)">View Document</a>
              </div>


              <div [ngClass]="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT' ?'col-12 col-lg-4':'col-12 col-lg-6' ">

                  <p>{{partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT'? "Upload "+partnersDocumentDetail.address_proof+" front side ":'Upload  Address Proof'}} <span class="text-danger star">*</span></p>
                  <div class="csv-drag" appFileUpload (fileDropped)="onPartnerFileChange($event,5,'d',partnersDocumentDetail.partner_file_url1)">
                      <!-- a for authorised signatory and d for director -->
                      <div class="drop-zone-prompt">
                          <img [src]="partnersDocumentDetail.partner_file_url1" class="mb-4"><br>
                          <input type="file" #fileDropRef11 id="fileDropRef11" (change)="onPartnerFileChange($event,5,'d',partnersDocumentDetail.partner_file_url1)" accept=".png, .jpg, .jpeg" />
                          <p style="font-size: 11px;" class="mb-0">Allowed Format - JPG, JPEG, PNG </p>
                          <p style="font-size: 11px;">Max File Size 2MB</p>
                          <p>Drop file here or <span>Click to Upload</span></p>
                          <!-- <label for="fileDropRef11">Browse for file</label> -->
                      </div>
                  </div>
                  <a class="document" *ngIf="partnersDocumentDetail.partner_file_url1 != 'assets/assets/img/upload_file.png'"
                  (click)="viewDoc(partnersDocumentDetail.partner_file_url1)">View Document</a>
              </div>
              <ng-container *ngIf="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT'">
                <div [ngClass]="partnersDocumentDetail.address_proof == 'AADHAR' || partnersDocumentDetail.address_proof == 'PASSPORT' ?'col-12 col-lg-4':'col-12 col-lg-6' " *ngIf="partnersDocumentDetail.status == '' || !partnersDocumentDetail.status || partnersDocumentDetail.partner_file_url2 == 'assets/assets/img/upload_file.png' || !partnersDocumentDetail.partner_file_url2 ">
                  <p>Upload {{partnersDocumentDetail.address_proof}} Back side <span class="text-danger star">*</span></p>
                  <div class="csv-drag" appFileUpload (fileDropped)="onPartnerFileChange($event,6,'d',partnersDocumentDetail.partner_file_url2)">
                      <!-- a for authorised signatory and d for director -->
                      <div class="drop-zone-prompt">
                          <img [src]="partnersDocumentDetail.partner_file_url2" class="mb-4"><br>
                          <input type="file" #fileDropRef12 id="fileDropRef12" (change)="onPartnerFileChange($event,6,'d',partnersDocumentDetail.partner_file_url2)" accept=".png, .jpg, .jpeg" />
                          <p style="font-size: 11px;" class="mb-0">Allowed Format - JPG, JPEG, PNG </p>
                          <p style="font-size: 11px;">Max File Size 2MB</p>
                          <p>Drop file here or <span>Click to Upload</span></p>
                          <!-- <label for="fileDropRef11">Browse for file</label> -->
                      </div>
                  </div>
                  <a class="document" *ngIf="partnersDocumentDetail.partner_file_url2 != 'assets/assets/img/upload_file.png'" (click)="viewDoc(partnersDocumentDetail.partner_file_url2)">View Document</a>
              </div>
              </ng-container>
              <label class="remarks">{{partnersDocumentDetail.remarks ? partnersDocumentDetail.remarks:'Details should be of the mentioned business only'}}</label>
              <div class="col-12 d-flex justify-content-end mt-5">
              </div>

          </div>
      </div>
  </form>
</div>
<div class="tab-pane " *ngIf="!showAddPartner || (showAddPartner && kyc_data.is_partner > 1 && kyc_data.kyc_status == 1 )">
  <div class="kyc-box" >
    <ng-container *ngIf="kycPartnerList.length > 0 else nodata">
        <div class="kyc-heading pb-4">List of Kyc Partners</div>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <th>Sr.No.</th>
                    <th>Directors Name</th>
                    <th>Mobile Number</th>
                    <th>Email id</th>
                    <th>Address Proof Type</th>
                    <th>Status</th>
                    <th>Remarks</th>
                    <th>Action</th>
                </thead>
                <tbody>
                    <tr *ngFor="let value of kycPartnerList; let i=index">

                        <td>{{i+1}}</td>
                        <td>{{value.name}}</td>
                        <td>{{value?.mobile}}</td>
                        <td>{{value?.email}}</td>
                        <td>{{value.type}}</td>
                        <td>
                          <label>{{value.status == 1?'Verified':(value.status == 2?'Pending':'Rejected')}}
                            <i *ngIf="value.status != 3" class="fas "
                              [ngClass]="{'fa-check-square success': value.status == 1,'fa-times-square reject': value.status == 0,'fa-exclamation-circle pending': value.status == 2}"></i>

                          </label>

                        </td>
                        <td>{{value.remarks?value.remarks:'--'}}</td>
                        <td>
                            <a class="" data-bs-toggle="tooltip" title="Download PAN Document" (click)="viewDoc(value.pan_file)" ><i class="fas fa-arrow-circle-down upload"></i></a>
                            <a data-bs-toggle="tooltip" data-placement="bottom" title="Download {{value.type}} Document" (click)="viewDoc(value.aadhar_file)" ><i class="fas fa-arrow-circle-down upload"></i></a>
                            <a *ngIf=" value.type == 'AADHAR' || value.type == 'PASSPORT' " data-bs-toggle="tooltip" data-placement="bottom" title="Download {{value.type}} Document" (click)="viewDoc(value.aadhar_back_file)" ><i class="fas fa-arrow-circle-down upload"></i></a>
                            <a *ngIf="!value.status"   (click)="editDocument(value)"><i class="fas fa-pencil"></i></a>
                            <!-- *ngIf="!value.status" -->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
    <ng-template #nodata>
        <div class="kyc-heading pb-4">List of Kyc Partners</div>
        <div class="table-responsive">
            <table class="table">
                <tr>
                    <td>
                        <p>Record Not Found</p>
                    </td>
                </tr>
            </table>
        </div>

    </ng-template>
  </div>
</div>
<ng-template cdkPortal #modalTemplateOpt="cdkPortal">
  <app-custom-otp [otpBox]="6" [title]="'Verify Aadhar OTP'" [message]="'OTP sent to your Aadhar registered mobile number.'" [submitBtnName]="'Verify OTP'"
  (sendOtp)="verifyOtp($event)" (resendOtp)="sendOTP(true)"
   (closeTemplateModal)="closeModalPopUp()" [submitBtnClass]="'btn btn-primary'"></app-custom-otp>
</ng-template>
