import { SessionStorageService } from '@core/services/session-storage.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserDetailsService } from '@core/services/user-details.service';
import { AutoLogoutService } from '@features/autoLogout/service/auto-logout.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  loginSession: any;

  constructor(
    private route: Router,
    private sessionStorage: SessionStorageService,
    private _UserDetailsService: UserDetailsService,
    private _AutoLogoutService: AutoLogoutService

  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let session: any = this.sessionStorage.getItem('userDetails');

    this.loginSession = session ? JSON.parse(session).authtoken : false;
    // console.log(this.loginSession);

    if (this.loginSession) {
      this._UserDetailsService.setUserLoginDetails = JSON.parse(session);
      // this._AutoLogoutService.init();
      return true
    }
    // this._AutoLogoutService.uninit();
    this.sessionStorage.clearStorage();
    this.route.navigate(['/login']);

    return false;
  }
}
