<ng-autocomplete [data]="dropDownList2" [searchKeyword]="keyword" placeholder="Select Registered Firm List"
    (selected)='onFirmSelect($event)' [itemTemplate]="itemTemplate" [initialValue]="initialValue"
    [notFoundTemplate]="notFoundTemplate">
</ng-autocomplete>

<ng-template #itemTemplate let-item>
    <a [innerHTML]="item.name"></a>
</ng-template>

<ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="notFound"></div>
</ng-template>

