import { TemplateModule } from './template/template.module';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    TemplateModule,
  ],
  exports: [
    TemplateModule
  ]
})
export class LayoutsModule { }
