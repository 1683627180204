<div class="bank-modal">
    <div class="modal-txt">
        <p *ngIf="modalSetting.icon" class="pb-3"><img [src]="
          modalSetting.icon == 'error'?'assets/assets/img/svg/error.svg':
          modalSetting.icon == 'success'?'assets/assets/img/svg/success.svg':
          modalSetting.icon == 'info'?'assets/assets/img/svg/information.svg':
          modalSetting.icon == 'warning'?'assets/assets/img/svg/warning.svg':
          modalSetting.icon == 'custom'?modalSetting.img:
          'assets/assets/img/svg/warning.svg'"></p>
        <h5 *ngIf="modalSetting.title" [innerHTML]="modalSetting.title"></h5>

        <h3 *ngIf="modalSetting.title2" [innerHTML]="modalSetting.title2"></h3>
        <div *ngIf="modalSetting.msg" [innerHTML]="modalSetting.msg"></div>
    </div>
    <div class="modal-btn">
        <button type="button" tabindex="0" *ngIf="modalSetting.btnConfirm" (click)="confirmModal($event)" class="btn btn-primary mr-2">{{modalSetting.btnConfirm}}</button>
        <button type="button" tabindex="0" *ngIf="modalSetting.btnClose" (click)="closeModal($event)" class="btn btn-default">{{modalSetting.btnClose}}</button>
        <button type="button" tabindex="0" *ngIf="modalSetting.btnCancel" (click)="cancelModal($event)" class="btn btn-primary ml-2">{{modalSetting.btnCancel}}</button>
    </div>
</div>
