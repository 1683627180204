import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, OnChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxToasterService } from '@core/services/toasterNgs.service';
import { Subscription, take, timer } from 'rxjs';

@Component({
  selector: 'app-dynamic-otp',
  templateUrl: './dynamic-otp.component.html',
  styleUrls: ['./dynamic-otp.component.scss']
})
export class DynamicOtpComponent implements OnInit, OnChanges {

  // @Output() otpChange: EventEmitter<any> = new EventEmitter();
  // @Output() resendOtp: EventEmitter<any> = new EventEmitter(false);
  @Input() btnName:string;
  @Input() maxLength:number;
  @Input() timer:any;
  @Output() submit: EventEmitter<number> = new EventEmitter();
  @Output() resendOTP: EventEmitter<any> = new EventEmitter();
  @Output() resendOtpReset: EventEmitter<any> = new EventEmitter();
  @Input() resendOTPCounter: number = 30; //set resend timmer
  @Input() dynamicResendCounter: boolean = true; //show resend button

  submitted:boolean=false;
  countDown: Subscription;
  counter = 120;
  tick = 1000;
  formData:UntypedFormGroup;
  count: any;

  constructor(
    private toaster:NgxToasterService,
    private fb:UntypedFormBuilder,
  ) {
    this.formData=this.fb.group({
      otp:['',[Validators.required]]
    })
    // console.log(this.timer)
    // if(this.timer){
    //   this.transformData();
    // }
  }
  get f(){
    return this.formData.controls;
  }
  ngOnInit(): void {
    this.onResendOTP(false);
    // this.transformData()
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes['timer'])
    if(changes['timer'].currentValue){
      this.transformData();
      // setTimeout(() => {
      //   this.resendOtpReset.emit(true);
      // }, 1);

    }
  }

  // onResendOtp() {
  //   this.resendOtp.emit(true);
  // };
  onResendOTP(reset: boolean){
      let count: number = this.resendOTPCounter;
      let inter = setInterval(() => {
        count -= 1;
        this.count = count;
        if (count == 0) {
          clearInterval(inter);
          this.count = null;
          if (this.dynamicResendCounter) {
            this.resendOTPCounter += 30;
          }
        }
      }, 1000);
      if (reset) {
        this.resendOTP.emit(true);
      }

  }

  onSubmitOTP() {
    this.submitted=true;
    if(this.formData.invalid){
      return;
    }
    this.submit.emit(this.formData.value.otp);
  }


  transformData(){
    // console.log("inside transform data")
    this.countDown = timer(0, this.tick)
    .pipe(take(this.counter))
    .subscribe(() => {
      --this.counter;
      // console.log(this.counter);
      if (this.counter == 0) {
        this.countDown.unsubscribe();
      }
    });
    this.transform(120);
  }
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );
  }

}
