<!-- <div class="container" appFileUpload (fileDropped)="onFileDropped($event)">
	<img [src]="file_url" class="mb-4"><br>
	<input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event)" />
	<h3>Drag and drop file here or</h3>
	<label for="fileDropRef">Browse for file</label>
</div> -->
<div class="upload-bg">
<button type="button" class="close pr-3 pt-2" (click)="closeTab()" data-dismiss="modal" aria-label="Close">

	<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
		<g id="close" transform="translate(-1781 -738)">
		  <path id="noun-add-button-3977680" d="M105.5,17.5a18,18,0,1,0,18,18A18.019,18.019,0,0,0,105.5,17.5Z" transform="translate(1693.5 720.5)" fill="#e2e2e2"/>
		  <path id="noun-add-button-3977680-2" data-name="noun-add-button-3977680" d="M9.594,0a1.373,1.373,0,0,1,1.4,1.347V8.274h6.849a1.375,1.375,0,0,1,.061,2.75H10.991V17.9a1.371,1.371,0,0,1-2.741,0V11.025H1.4a1.375,1.375,0,0,1-.059-2.75h6.91V1.4A1.371,1.371,0,0,1,9.594,0Z" transform="translate(1799 742.393) rotate(45)" fill="#707070"/>
		</g>
	  </svg>
	  
</button>
<div [class]="'beni-modal bulk-popup w-100 p-5 '+showBulkTransferModal">
	<h6>Upload Filled Template</h6>
	<h2 class="mt-2 mb-5">Enter the required details in the template and upload (.xls, .xlsx, .csv)</h2>
	<div class="drop-zone csv-drag bulk-drag mb-5 d-flex flex-column">
	  <span class="drop-zone-prompt"><svg id="upload" xmlns="http://www.w3.org/2000/svg" width="48" height="48.188" viewBox="0 0 48 48.188">
		<rect id="Rectangle_18670" data-name="Rectangle 18670" width="48" height="48" transform="translate(0 0.094)" fill="none"></rect>
		<g id="Iconly_Light-outline_Upload" data-name="Iconly/Light-outline/Upload" transform="translate(0.537)">
			<g id="Upload-2" data-name="Upload" transform="translate(0 48.188) rotate(-90)">
				<path id="Upload-3" data-name="Upload" d="M10.409,46.926A10.409,10.409,0,0,1,0,37.008v-26.6A10.411,10.411,0,0,1,9.921,0H21.853a10.409,10.409,0,0,1,10.4,9.92v2.673a1.761,1.761,0,0,1-3.5.235l-.016-.235V10.406A6.888,6.888,0,0,0,22.26,3.519H10.409A6.889,6.889,0,0,0,3.521,10V36.513A6.888,6.888,0,0,0,10,43.407l.406.012H21.877a6.865,6.865,0,0,0,6.855-6.457V34.345a1.763,1.763,0,0,1,3.507-.235l.014.235v2.213a10.387,10.387,0,0,1-9.872,10.368H10.409Zm27.922-15.38a1.759,1.759,0,0,1-.164-2.292l.169-.2,3.85-3.834h-24a1.76,1.76,0,0,1-.235-3.5l.235-.014H42.19l-3.855-3.832a1.759,1.759,0,0,1-.178-2.292l.171-.2a1.764,1.764,0,0,1,2.284-.176l.2.169,6.876,6.839a1.752,1.752,0,0,1,.5,1.023h0v.04h0v.042h0v.145h0V23.5h0v.138a1.75,1.75,0,0,1-.171.591h0L48,24.258l-.014.023h0l-.019.033h0l-.019.031h0l-.021.033h0l-.019.028h0l-.016.026h0l-.016.021h0a1.769,1.769,0,0,1-.211.235l-6.834,6.8a1.759,1.759,0,0,1-2.488,0Z" fill="#00659e"></path>
			</g>
		</g>
	</svg></span>
	<p>Drag and Drop file</p>
	  <input type="file" name="myFile" class="drop-zone-input" (change)="onFileChange($event)" multiple="false" />
	</div>
	<button class="btn save-btn">Browse File From Computer</button>
</div>
</div>
