
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        FileUploadComponent,
        
    ],

    imports: [
        CommonModule,
        FormsModule,
    ],

    exports: [
        FileUploadComponent
    ]

})

export class FileUploadModule {

}