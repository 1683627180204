
import { Subscription } from 'rxjs';
import { DashboardService } from './../../../../pages/dashboard/dashboard.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgxToasterService } from '@core/services/toasterNgs.service';

@Component({
  selector: 'relipay-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, OnDestroy {
  userDetail: any;
  walletData: any;
  loader = false;

  amountDatasubscription: Subscription;

  constructor(
    private dashboardService: DashboardService,
    private toaster: NgxToasterService
    ) { }

  ngOnInit(): void {
  };




    ngOnDestroy(): void {

    }

}
