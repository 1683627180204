import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectedBankingModalComponent } from './connected-banking-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IcicBankComponent } from './icic-bank/icic-bank.component';
import { CommonConnectedModalComponent } from './common-connected-modal/common-connected-modal.component';
import { BulkPayoutModalComponent } from './bulk-payout-modal/bulk-payout-modal.component';



@NgModule({
  declarations: [
    ConnectedBankingModalComponent,
    IcicBankComponent,
    CommonConnectedModalComponent,
    BulkPayoutModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [ConnectedBankingModalComponent, IcicBankComponent, CommonConnectedModalComponent]
})
export class ConnectedBankingModalModule { }
