<div class="switch" *ngIf="toggelButtonType !== 'button-1'">
    <input type="checkbox" [id]="id" [(ngModel)]="_input" (ngModelChange)="onChange($event)" [disabled]="disabled"/>
    <span class="slider round"></span>
</div>

<div class="toggle-button-cover" *ngIf="toggelButtonType == 'button-1'">
  <div class="button-cover">
    <div class="button r" id="button-1">
      <input type="checkbox" class="checkbox" [id]="id" [(ngModel)]="_input" (ngModelChange)="onChange($event)" [disabled]="disabled"/>
      <div class="knobs" [attr.data-before-content]="toggleText['0']" [attr.data-after-content]="toggleText['1']"><span></span></div>
      <div class="layer"></div>
    </div>
  </div>
