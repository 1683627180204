import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactDetailComponent } from './contact-detail.component';
import { SharedModule } from '@shared/shared.module';
import { CustomOtpModule } from '@features/custom-otp/custom-otp.module';
import { CdkCustomMdlModule } from '@features/customCDK/cdkCustomMdl/cdk-custom-mdl.module';



@NgModule({
  declarations: [ContactDetailComponent],
  imports: [
    SharedModule,
    CustomOtpModule,
    CdkCustomMdlModule,
  ],
  exports:[ContactDetailComponent]
})
export class ContactDetailModule { }
