
import { Injectable } from '@angular/core';
import { ApiRequestService } from '@core/services/api-request.service';
import { FileuploadService } from '@core/services/file-upload.service';
import { config } from '@core/services/request-url.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PagesService {
  constructor(
    private apiRequest: ApiRequestService,
    private _FileuploadService: FileuploadService,
  ) { }
  getBankList(payLoad: any) {
    return this._FileuploadService.asyncMethod(config.UserKyc.getBankList);
    // return this.apiRequest.postdata(payLoad, config.UserKyc.getBankList);
  }
  getBankListForFund(payLoad: any) {
    return this.apiRequest.postAdminData(payLoad, config.UserKyc['getBankListForFund']['url']);
  }
  getVAList(): Observable<any> {
    return this.apiRequest.postdata(null, config['vaAccount']);
  }
  uploadProfilePicture(payLoad: any) {
    return this.apiRequest.postdata(payLoad, config.profilePicture);
  }
}
