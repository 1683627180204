import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-cdk-dropdown',
  templateUrl: './cdk-dropdown.component.html',
  styleUrls: ['./cdk-dropdown.component.scss'],
})
export class CdkDropdownComponent implements OnInit {
  @ViewChild(TemplateRef) templateRef!: TemplateRef<any>;
  @Output() closed = new EventEmitter<void>();
  @Input('autoClose') autoClose: boolean = true;

  constructor() {}

  ngOnInit(): void {}
}
