import { PermissionServiceService } from '@core/services/permission-service.service';

import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthCoreService } from '@page/auth/auth.service';
import { Subject, takeUntil, Subscription } from 'rxjs';
import { DashboardService } from '@page/dashboard/dashboard.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { KycServices } from '@page/dashboard/kyc/kyc-services';
import { KycComponent } from '@page/dashboard/kyc/kyc.component';
import { UserDetailsService } from '@core/services/user-details.service';
import { SessionStorageService } from '@core/services/session-storage.service';
import {SidebarService} from "@features/layouts/components/sidebar/sidebar-service.service";

@Component({
  selector: 'relipay-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // isShown: boolean = false
  private _unsubscribeAll: Subject<any>;
  userDetails: any;
  userBalance: any = '';
  userBalSubs: Subscription;
  currncyLength
  currency

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authCoreService: AuthCoreService,
    private authService: AuthCoreService,
    private router: Router,
    private dashBoardService: DashboardService,
    public config: NgbModalConfig,
    private modalService: NgbModal,
    private kycService: KycServices,
    private userDetailsService: UserDetailsService,
    private sessionStorage: SessionStorageService,
    public permission: PermissionServiceService,
    private _sideBarService: SidebarService
  ) {
    this._unsubscribeAll = new Subject();
    this.userDetailsService.userLoginDetails.subscribe({
      next: (value: any) => {
        this.userDetails=value?.data;
      }
    });
    // let session: any = this.sessionStorage.getItem('userDetails') ? JSON.parse(this.sessionStorage.getItem('userDetails')) : '';
    // this.userDetails = session?.data ?? session.user_details;
    // console.log('user detail',this.userDetails)
  }

  ngOnInit(): void {
    this.isOnConnect = this._sideBarService.selectedItem.value == 4;
    this.authService.updateCols
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: any) => {
        if (config) {
          const result = document.getElementsByClassName("header navbar navbar-expand-sm");
          result[0].classList.add('expand-header')

        } else {
          const result = document.getElementsByClassName("header navbar navbar-expand-sm");
          result[0].classList.remove('expand-header')

        }

      });

    this.userBalSubs = this.dashBoardService._userBalanceData.subscribe((bal: any) => {
      if (bal) {
        this.userBalance = (bal).replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',');
      }
    })
    let session: any = this.sessionStorage.getItem('userDetails');

    let loginSession = session ? JSON.parse(session).authtoken : false;
    if (loginSession) {
      this.getUserBalance()
    }
  };

  getUserBalance() {
    this.dashBoardService.userBalance();
  }

  onLogOut() {
    this.authCoreService.logout();
  }
  myProfile() {
    this.router.navigate(['/profile'])
  };


  async openkycModule() {
    const detail = await this.kycService.getKycDetail();
    this.kycService.kyc_detail_.next(detail);
    const modalRef = this.modalService.open(KycComponent);
    modalRef.closed.subscribe(res => {
    })
  }
  // showhide() {
  //   if (!this.isShown) {
  //     this.isShown = true;
  //     this.document.body.classList.add('sidebar-noneoverflow');
  //     this.authService.toggleCols(true)
  //   } else {
  //     this.isShown = false;
  //     this.document.body.classList.remove('sidebar-noneoverflow');
  //     this.authService.toggleCols(false)
  //   }
  // }
  isOnConnect: boolean = false;

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);

    this._unsubscribeAll.complete();
  }
}
