import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { DropdownTriggerForDirective } from './dropdown-trigger-for.directive';
import { CdkDropdownComponent } from './cdk-dropdown/cdk-dropdown.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [DropdownTriggerForDirective, CdkDropdownComponent],
  imports: [CommonModule, OverlayModule],
  exports: [DropdownTriggerForDirective, CdkDropdownComponent],
})
export class CdkDropDownModule {}
