<!-- <div class="rectangle">
    <div class="center">User Idle Timer Configuration</div>
    <form [formGroup]="inputForm" (ngSubmit)="onSubmit()" class="center">
        <div class="margin-form">
            <label>
        First Level Timer Value: (min.)
        <input style="margin-left: 15px;" type="number" formControlName="firstLevelTimer">
      </label>
        </div>

        <div class="margin-form">
            <label>
        Second Level Timer Value: (min.)
        <input type="number" formControlName="secondLevelTimer">
      </label>
        </div>

        <div class="center">
            <button type="submit" [disabled]="!inputForm.valid">Start</button>
        </div>
    </form>
</div>

<div class="timer">
    <div class="base-timer">
        <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <g class="base-timer__circle">
        <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45" />
        <path id="base-timer-path-remaining" stroke-dasharray="283"
          class="base-timer__path-remaining" d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "></path>
      </g>
    </svg>
        <span id="base-timer-label" class="base-timer__label">
      {{ idleTimerLeft }}
    </span>
    </div>
</div>

<div class="modal-container">
    <div id="myModal" class="modal">
        <div class="modal-content">
            <h2><b>Second Level Timer activated</b></h2>
            <div class="clock-style">
                <span>Time Remain -</span>
                <span class="time-in-mins">{{ secondTimerLeft }}</span>
            </div>

            <div style='display: flex;'>
                <button (click)='continue()'>Continue</button>
                <button (click)='logout()'>Logout</button>
            </div>
        </div>
    </div>
</div> -->

<ng-template cdkPortal #modalTemplate="cdkPortal">
    <div class="modal-otp col-12 p-3">
        <button type="button" class="close close-button" (click)="closeTemplateModal()">
    <span aria-hidden="true">&times;</span>
  </button>
        <div class="">
            <h2><b>Second Level Timer activated</b></h2>
            <div class="clock-style">
                <span>Time Remain -</span>
                <span class="time-in-mins">{{ secondTimerLeft }}</span>
            </div>

            <div style='display: flex;'>
                <button (click)='_continue()'>Continue</button>
                <button (click)='_logout()'>Logout</button>
            </div>
        </div>
    </div>
</ng-template>