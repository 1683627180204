<div class="customTblFilterHeaderBox">
    <div [style.maxWidth]="searchTypeWidth" *ngIf="tableOptions.config.searchType &&
    tableOptions.config?.showSearch">
        <input type="text" appSrchDbounce appSearchBox [searchAfter]="3" [debounceTime]="1000" [value]="tableOptions.config.serverSearchVal" (onEvent)="_PytCustTblService.dtSearchBy($event,tableOptions)" type="text" class="dtSearch" [placeholder]="tableOptions.config.placeholder">
    </div>

    <div>
        <div class="viewingBox">
            <span>Viewing {{tableOptions.config.fromRec}}-{{tableOptions.config.toRec}} of
        {{tableOptions.config.totalCount}}</span>

            <div> <span [ngClass]="{'pagerMute':tableOptions.config.currentPage == 1}"
              (click)="tableOptions.config.currentPage > 1? _PytCustTblService.dtUpdateByPagination('back',tableOptions) : false"><i
            class="fas fa-caret-left"></i></span>
                <span [ngClass]="{'pagerMute':tableOptions.config.totalPages == tableOptions.config.currentPage}"
                 (click)="tableOptions.config.totalPages > tableOptions.config.currentPage?
                 _PytCustTblService.dtUpdateByPagination('next',tableOptions): false"><i
            class="fas fa-caret-right"></i></span></div>
        </div>
        <div class="recordBox" *ngIf="tableOptions.config.pagger.length > 0 && tableOptions.config?.showRecordDropdown">
            <span>Records</span>
            <div class="">
                <select class="" (change)="_PytCustTblService.dtUpdateByPageLength($event,tableOptions)">
                <option *ngFor="let item of tableOptions.config.pagger"
                [selected]="tableOptions.config.totalItmePerPage == item" [value]="item">
                    {{item}}</option>
            </select>
            </div>
        </div>
        <a href="javascript:void(0)" *ngIf="tableOptions.config.reloadTable" class="syncBtn" [class]="{'rotateIt': tableOptions.config.rotateIt}" (click)="tableOptions.dtUpdateFnCallBack()" rel="noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="18.929" height="21" viewBox="0 0 18.929 21">
            <g id="reload" transform="translate(-93.941 -33.603)">
              <path id="Path_274129" data-name="Path 274129" d="M146.968,47.117a.837.837,0,0,0-1.071.5,8.068,8.068,0,1,1-1.951-8.506h-3.727a.837.837,0,0,0,0,1.674l5.5-.014a.837.837,0,0,0,.834-.834l.014-5.5h0a.836.836,0,1,0-1.671,0v3.242h0a9.765,9.765,0,1,0,2.575,10.507.837.837,0,0,0-.5-1.069Z" transform="translate(-34.649)" fill="#fff"/>
            </g>
          </svg>
        </a>

        <!-- <div class="" *ngIf="tableOptions.config.showToggel">
          <app-slider-toggle [toggelButtonType]="'button-1' "></app-slider-toggle>
        </div> -->

        <div *ngIf="download_reports && tableOptions.records.length > 0  && tableOptions.config.downloadReports" class=" exportBox">
            <button [dropdownTriggerFor]="dropdown" class="btn btn-danger  ">
              <i *ngIf="tableOptions.config.downloadReports?.exportIcon" class="fas fa-file-download mr-1 "></i>Export</button>

            <app-cdk-dropdown #dropdown>
                <div class="bg-white p-1 shadow">
                    <div class="dropdown-item" *ngFor="let file of downloadFileType" (click)="download(file.value)">{{file.name}}</div>
                </div>
            </app-cdk-dropdown>

        </div>

        <div *ngIf="tableOptions.config?.addButton" class="mx-2">
            <button class="btn btn-success" (click)="onAddButton()">Add Account</button>
        </div>

    </div>

</div>
