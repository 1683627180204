<div class="model-verifiy-section">
  <div class=" d-flex justify-content-end align-items-center p-3">
      <!-- <button type="button" class="close-btn" data-dismiss="modal" (click)="closeModal($event)">
          <span>×</span>
      </button> -->
  </div>
  <div class="d-flex justify-content-center align-items-center flex-column">
      <p *ngIf="modalSetting.icon" class="pb-3"><img [src]="
        modalSetting.icon == 'error'?'assets/assets/img/svg/error.svg':
        modalSetting.icon == 'success'?'assets/assets/img/svg/success.svg':
        modalSetting.icon == 'info'?'assets/assets/img/svg/information.svg':
        modalSetting.icon == 'warning'?'assets/assets/img/svg/warning.svg':
        modalSetting.icon == 'custom'?modalSetting.img:
        'assets/assets/img/svg/warning.svg'"></p>
      <h6 *ngIf="modalSetting.title2" [innerHTML]="modalSetting.title2" class="text-center px-5"></h6>
      <div class="verify-sec mt-3 w-100">
          <div class="d-flex justify-content-center">
              <p>Bene Name</p>
              <p class="textans">: {{dataValue.data?.name}}</p>
          </div>
          <div class="d-flex justify-content-center flex-wrap">
              <p>VPA ID</p>
              <p class="textans">: {{dataValue.data?.vpa}}</p>
          </div>
          <div class="d-flex justify-content-center">
              <p>Account No.</p>
              <p class="textans">: {{dataValue.data?.accountNumber}}</p>
          </div>

          <div class="row">
              <div class="col-lg-12 d-flex justify-content-center">
                  <button type="button" class="btn btn-yes mt-3" *ngIf="modalSetting.btnConfirm" (click)="confirmModal($event)">
                    {{modalSetting.btnConfirm}}
                  </button>
              </div>
              <div class="col-lg-12 d-flex justify-content-center">
                  <button type="button" class="btn btn-no mt-3" *ngIf="modalSetting.btnClose"   (click)="cancelModal($event)">
                    {{modalSetting.btnClose}}
                  </button>
              </div>
          </div>
      </div>

  </div>
</div>
