<div class="tab-pane">
    <form [formGroup]="form">
        <div class="kyc-box">
            <div class="kyc-heading pb-4">Business Detail</div>
            <div class="row mt-3">
                <div class="col-12 col-lg-6">
                    <div class="floating-label">

                        <select class="floating-select form-control" formControlName="business_type_id">
                            <option [value]="item.id" *ngFor="let item of firm_list">{{item.name}}</option>
                        </select>
                        <span class="highlight"></span>
                        <label class="floating-label-label" *ngIf="form.get('business_type_id')?.value">Business Type <span class="text-danger star">*</span></label>
                        <label class="p-2" *ngIf="!form.get('business_type_id')?.value">Business Type <span class="text-danger star">*</span></label>
                        <app-show-errors [ctrl]="form.get('business_type_id')" [requiredMesg]="'Business Type is required'">
                        </app-show-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="floating-label">

                        <select class="floating-select form-control" formControlName="business_entity">
                            <option [value]="item.value" *ngFor="let item of businessEntity">{{item.name}}</option>
                        </select>
                        <span class="highlight"></span>
                        <label class="floating-label-label" *ngIf="form.get('business_entity')?.value">Business Entity <span class="text-danger star">*</span></label>
                        <label class="p-2" *ngIf="!form.get('business_entity')?.value">Business Entity <span class="text-danger star">*</span></label>
                        <app-show-errors [ctrl]="form.get('business_entity')" [requiredMesg]="'Business Entity is required'">
                        </app-show-errors>
                    </div>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="floating-label">

                        <select class="floating-select form-control" formControlName="business_category_id" onclick="this.setAttribute('value', this.value);" value="">
                           <option value="" selected disabled >Select business category</option>
                          <option [value]="item.id" *ngFor="let item of business_category_list">{{item.name}}</option>
                        </select>
                        <span class="highlight"></span>
                        <label class="floating-label-label text-black">Business Category <span class="text-danger star">*</span></label>
                        <!-- <label class="p-2" *ngIf="!form.get('business_category_id')?.value">Business Category</label> -->
                        <app-show-errors [ctrl]="form.get('business_category_id')" [requiredMesg]="'Business Category is required'">
                        </app-show-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-6" *ngIf="form.get('business_category_id')?.value == 13 ">
                    <div class="floating-label">
                        <input class="floating-input form-control" type="text" formControlName="other_sub_category">
                        <span class="highlight"></span>
                        <label class="p-2">Other Business Category <span class="text-danger star">*</span></label>
                        <app-show-errors [ctrl]="form.get('other_sub_category')" [requiredMesg]="'Other Business Category is required'">
                        </app-show-errors>
                    </div>
                </div>
                <!--  || company_id == 12 -->
                <div class="col-12 col-lg-6" *ngIf="company_id == 3 ">
                    <div class="floating-label">

                        <select class="floating-select form-control" formControlName="doctype">
                           <option value="" selected disabled>Select Document Type</option>
                          <option [value]="item.type" *ngFor="let item of documentList" [hidden]="form.get('business_type_id')?.value == 3?!item?.is_show:!item?.is_trust_show">{{item.value}}</option>
                        </select>
                        <span class="highlight"></span>
                        <label class="floating-label-label">Select Document Type  <span class="text-danger star">*</span></label>
                        <!-- <label class="p-2" *ngIf="!form.get('business_category_id')?.value">Business Category</label> -->
                        <app-show-errors [ctrl]="form.get('doctype')" [requiredMesg]="'Document Type is required'">
                        </app-show-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-6" *ngIf="form.get('business_type_id')?.value == 3 && form.get('doctype')?.value == 'SHOP' ">
                    <div class="floating-label">
                        <label class="floating-label-label">Select State <span class="text-danger star">*</span></label>
                        <select class="floating-select form-control" formControlName="state_code" onclick="this.setAttribute('value', this.value);" value="">
                           <option value="" required>Select State</option>
                          <option [value]="item.state_code" *ngFor="let item of shopStateList">{{item.state}}</option>
                        </select>
                        <span class="highlight"></span>
                        <!-- <label class="p-2" *ngIf="!form.get('business_category_id')?.value">Business Category</label> -->
                        <app-show-errors [ctrl]="form.get('state_code')" [requiredMesg]="'State is required'">
                        </app-show-errors>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 col-lg-6">
                    <div class="floating-label" *ngIf="form.get('gstn_no') ">
                        <input class="floating-input form-control" type="text" formControlName="gstn_no" (change)="onChangeGstn()" appUppercase>
                        <span class="highlight"></span>
                        <label class="p-2">GSTIN No. <span class="text-danger star">*</span></label>
                        <app-show-errors [ctrl]="form.get('gstn_no')" [requiredMesg]="'GSTIN No. is required'">
                        </app-show-errors>
                    </div>
                    <div class="floating-label" *ngIf="form.get('udyam_no') ">
                        <input class="floating-input form-control" type="text" formControlName="udyam_no" (ngModelChange)="onUdyamChange($event)" appUppercase>
                        <span class="highlight"></span>
                        <label class="p-2">Udyog Aadhar <span class="text-danger star">*</span></label>
                        <app-show-errors [ctrl]="form.get('udyam_no')" [requiredMesg]="'Udyog Aadhar is required'">
                        </app-show-errors>
                    </div>
                    <div class="floating-label" *ngIf="form.get('shop_number') ">
                        <input class="floating-input form-control" type="text" formControlName="shop_number" (ngModelChange)="onShopNoChange($event)" [appInputRestriction]="'integer'" appUppercase>
                        <span class="highlight"></span>
                        <label class="p-2">Shop Number <span class="text-danger star">*</span></label>
                        <app-show-errors [ctrl]="form.get('shop_number')" [requiredMesg]="'Shop no is required'">
                        </app-show-errors>
                    </div>
                    <div class="floating-label" *ngIf="form.get('coi') ">
                        <input class="floating-input form-control" type="text" formControlName="coi" appUppercase>
                        <span class="highlight"></span>
                        <label class="p-2">COI <span class="text-danger star">*</span></label>
                        <app-show-errors [ctrl]="form.get('coi')" [requiredMesg]="'COI is required'">
                        </app-show-errors>
                    </div>
                    <!-- <div class="floating-label" *ngIf="form.get('doc_type')?.value == 'UDYOGAADHAR'">
                          <input class="floating-input form-control" type="text" formControlName=""
                          (change)="onChangeGstn('u')" maxlength="19" appUppercase >
                          <span class="highlight"></span>
                          <label class="p-2">Udyog Aadhar</label>udyamno
                          <app-show-errors [ctrl]="form.get('udyamno')" [requiredMesg]="'Udyog Aadhar is required'" >
                          </app-show-errors>
                      </div> -->


                    <div class="floating-label">
                        <input class="floating-input form-control" type="text" formControlName="business_name" placeholder=" ">
                        <span class="highlight"></span>
                        <label class="p-2">Business Name <span class="text-danger star">*</span></label>
                        <app-show-errors [ctrl]="form.get('business_name')" [requiredMesg]="'Business Name is required'">
                        </app-show-errors>
                    </div>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="floating-label">
                        <textarea class="floating-input floating-textarea form-control" formControlName="description" placeholder=""></textarea>
                        <span class="highlight"></span>
                        <label class="p-2">Business Description <span class="text-danger star">*</span></label>
                        <app-show-errors [ctrl]="form.get('description')" [requiredMesg]="'Business description is required'">
                        </app-show-errors>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <div class="kyc-heading kyc-black pb-4">Website Details</div>
                </div>
                <div class="col-12 col-lg-6">

                    <div class="floating-label">
                        <app-custom-datepicker formControlName="site_reg_date" [datepickerType]="'bsDatepicker'" [class]=" 'form-control'" [dateFormate]="dateFormat" [placeholder]="'Select Date *'"></app-custom-datepicker>
                        <div class="claender-img">
                            <img src="assets/assets/img/svg/calander.svg">
                        </div>

                        <span class="highlight"></span>
                        <label class="p-2" *ngIf="form.get('site_reg_date')?.value">Website Registration Date <span class="text-danger star">*</span></label>
                        <app-show-errors [ctrl]="form.get('site_reg_date')" [requiredMesg]="'Website Registration Date is required'">
                        </app-show-errors>
                        <!-- <div *ngIf="(form.get('site_reg_date')?.touched )">
                            <span class="text-danger" *ngIf="form.get('site_reg_date')?.errors?.['required']">
                                Website Registration Date is required
                                </span>
                        </div> -->
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="floating-label">
                        <input class="floating-input form-control" type="text" formControlName="domain_origin">
                        <span class="highlight"></span>
                        <label class="p-2">Domain Origin <span class="text-danger star">*</span></label>
                        <app-show-errors [ctrl]="form.get('domain_origin')" [requiredMesg]="'Domain origin is required'">
                        </app-show-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="floating-label">
                        <input class="floating-input form-control" type="text" formControlName="site_url">
                        <span class="highlight"></span>
                        <label class="p-2">Website URL <span class="text-danger star">*</span></label>
                        <app-show-errors [ctrl]="form.get('site_url')" [requiredMesg]="'Website URL is required'">
                        </app-show-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-6 help-btn">

                    <a class="document" href="https://www.whois.com/whois/" target="_blank">
                        <button type="button" class=" help mx-auto w-100">Help</button>
                    </a>

                </div>
                <div class="col-12">
                    <div class="kyc-heading kyc-black pb-4">Address Details</div>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="floating-label">
                        <textarea class="floating-input form-control" formControlName="address_street_no" placeholder=" "></textarea>
                        <span class="highlight"></span>
                        <label class="p-2">Street No. <span class="text-danger star">*</span></label>
                    </div>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="floating-label">
                        <input class="floating-input form-control" type="text" formControlName="address_pin_code" placeholder=" ">
                        <span class="highlight"></span>
                        <label class="p-2">Pin Code <span class="text-danger star">*</span></label>
                    </div>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="floating-label">
                        <input class="floating-input form-control" type="text" formControlName="address_city_name">
                        <span class="highlight"></span>
                        <label class="p-2">City <span class="text-danger star">*</span></label>
                    </div>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="floating-label">
                        <input class="floating-input form-control" type="text" formControlName="address_state_name">
                        <span class="highlight"></span>
                        <label class="p-2">State <span class="text-danger star">*</span></label>
                    </div>
                </div>
                <ng-container *ngIf="!form.get('isOperational_address')?.value">
                    <div class="col-12">
                        <div class="kyc-heading kyc-black pb-4">Operational Address Details</div>
                    </div>

                    <div class="col-12 col-lg-6">
                        <div class="floating-label">
                            <input class="floating-input form-control" type="text" formControlName="operational_street_no" placeholder=" ">
                            <span class="highlight"></span>
                            <label class="p-2">Street No. <span class="text-danger star">*</span></label>
                            <app-show-errors [ctrl]="form.get('operational_street_no')" [requiredMesg]="'Street no is required'">
                            </app-show-errors>
                        </div>
                    </div>

                    <div class="col-12 col-lg-6">
                        <div class="floating-label">
                            <input class="floating-input form-control" type="text" formControlName="operational_pin_code" placeholder=" ">
                            <span class="highlight"></span>
                            <label class="p-2">Pin Code <span class="text-danger star">*</span></label>
                            <app-show-errors [ctrl]="form.get('operational_pin_code')" [requiredMesg]="'Pin Code is required'">
                            </app-show-errors>
                        </div>
                    </div>

                    <div class="col-12 col-lg-6">
                        <div class="floating-label">
                            <input class="floating-input form-control" type="text" formControlName="operational_city_name" placeholder=" ">
                            <span class="highlight"></span>
                            <label class="p-2">City <span class="text-danger star">*</span></label>
                            <app-show-errors [ctrl]="form.get('operational_city_name')" [requiredMesg]="'City is required'">
                            </app-show-errors>
                        </div>
                    </div>

                    <div class="col-12 col-lg-6">
                        <div class="floating-label">
                            <input class="floating-input form-control" type="text" formControlName="operational_state_name" placeholder=" ">
                            <span class="highlight"></span>
                            <label class="p-2">State <span class="text-danger star">*</span></label>
                            <app-show-errors [ctrl]="form.get('operational_state_name')" [requiredMesg]="'State is required'">
                            </app-show-errors>
                        </div>
                    </div>

                </ng-container>



                <div class="col-12 col-lg-6">
                    <div class="form-group form-check pl-0">
                        <div class="custom-control custom-checkbox checkbox-info">
                            <input type="checkbox" class="custom-control-input" formControlName="isOperational_address" id="sChkbox" name="sChkbox" (change)="onSelectOperationAddress()">
                            <label class="custom-control-label" for="sChkbox">
                              <strong>Operational Address same as on above</strong><br>
                              <span>Physical Verification may take place at this address.</span>
                            </label>
                        </div>
                    </div>
                </div>


                <div class="col-12 col-lg-6">
                    <button type="button" *ngIf="!isEditBusinessDetail || currentKycStep < '3'" class="btn save-btn" [disabled]="form.invalid" (click)="openSubmitConfirmationModal()">Save & Next</button>
                    <!-- <button *ngIf="isEditBusinessDetail" class="btn save-btn" (click)="saveBusinessDetail()">Update</button> -->
                </div>

                <!--   -->

            </div>

        </div>
    </form>
</div>
