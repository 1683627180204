
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from '@core/services/common.service';
import { UserDetailsService } from '@core/services/user-details.service';
import { AuthCoreService } from '@page/auth/auth.service';
import { RnfiConfigService } from '@rnficonfig/config.service';
import { Subject, takeUntil, delay } from 'rxjs';

@Component({
  selector: 'relipay-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {

  relipayConfig: any;

  public set _relipayConfig(v: any) {
    this.relipayConfig = v;
  }

  public get _relipayConfig(): any {
    return this.relipayConfig;
  }
  logedInUserDetails: any;
  loginData: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  payoutSideBar: any = null;

  public set _payoutSideBar(v: any) {
    this.payoutSideBar = v;
  }

  public get _payoutSideBar(): any {
    return this.payoutSideBar;
  }
  constructor(
    private _rnfiConfigService: RnfiConfigService,
    private _AuthCoreService: AuthCoreService,
    private userDetailsService: UserDetailsService,
    private _CommonService: CommonService,
  ) {
    // console.log("template")
    this._unsubscribeAll = new Subject();

    this._CommonService.subMenu.subscribe((e) => {
      if (e) {
        this._payoutSideBar = e;
      } else {
        this._payoutSideBar = null;
      }
    })
    this._rnfiConfigService.config.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: any) => {
        // console.log(config);

        this._relipayConfig = config;
      });
    this.userDetailsService.userLoginDetails.subscribe({
      next: (value: any) => {
        // console.log("login details  value ", value)
        this.loginData = value?.data;
      }
    });


  }

  ngOnInit(): void {
    this._AuthCoreService.updateCols
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: any) => {
        if (config) {
          const result = document.getElementsByClassName("main-container");
          //console.log('sidebar-closed add')

          result[0].classList.add('sidebar-closed')
          result[0].classList.remove('sbar-open')
        } else {
          const result = document.getElementsByClassName("main-container");
          result[0].classList.remove('sidebar-closed')
          result[0].classList.add('sbar-open')
          //console.log('sidebar-closed remove')
        }
      });

      this._AuthCoreService.isSubMenu.pipe(takeUntil(this._unsubscribeAll)).subscribe((res: any) => {
        const result = document.getElementsByClassName("main-container");
        if(res) {
          result[0].classList.add('is-submenu')
        }
        else {
          result[0].classList.remove('is-submenu')
        }
      })

  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

}
