import { NgModule } from '@angular/core';
import { KycComponent } from './kyc.component';
import { BankDetailComponent } from './bank-detail/bank-detail.component';
import { MoreDetailRequiredComponent } from './more-detail-required/more-detail-required.component';
import { FileUploadModule } from '@shared/components/file-upload/file-upload.module';
import { SharedModule } from '@shared/shared.module';
import { ContactDetailModule } from './contact-detail/contact-detail.module';
import { BusinessDetailModule } from './business-detail/business-detail.module';
import { VerifyDocumentModule } from './verify-document/verify-document.module';
import { FileuploadService } from '@core/services/file-upload.service';
import { BankDetailModule } from './bank-detail/bank-detail.module';


@NgModule({
  declarations: [
    KycComponent,
    MoreDetailRequiredComponent,

  ],
  imports: [
    // FileUploadModule,
    SharedModule,
    ContactDetailModule,
    BusinessDetailModule,
    VerifyDocumentModule,
    BankDetailModule
  ],
  exports:[
    KycComponent  ],
    // providers:[FileuploadService]
})
export class KycModule { }
