import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocModalComponent } from './doc-modal.component';
import { PipesModule } from '@core/pipes/pipes.module';



@NgModule({
  declarations: [
    DocModalComponent
  ],
  imports: [
    CommonModule,
    PipesModule
  ],
  exports:[DocModalComponent]
})
export class DocModalModule { }
