import { environment } from 'src/environments/environment';
import { Observable, retry, catchError, BehaviorSubject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { config } from './../../../../core/services/request-url.service';
import { ApiRequestService } from '@core/services/api-request.service';
import { Injectable } from '@angular/core';
import { PermissionServiceService } from '@core/services/permission-service.service';
import { Side_Bar } from '@core/common/common-config';
import { SessionStorageService } from '@core/services/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  selectedItem: BehaviorSubject<number> = new BehaviorSubject(0);
  sideBarList: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private _permissionService:PermissionServiceService, private _sessionStorage: SessionStorageService) {

  }


  refreshSidebar() {
    console.log('refreshing');
    if(!sessionStorage.getItem('userDetails')) return;
    let sideBar: any = [...Side_Bar];
    for (let i = 0; i < sideBar.length; i++){
      const e = sideBar[i];
      if (!this._permissionService.hasModule(e.module)) {
        sideBar.splice(i, 1);
        i--;
        continue;
      }
      if(e.subMenu && e.subMenu.length > 0){
        for (let j = 0; j < e.subMenu.length; j++){
          const subMenu = e.subMenu[j];
          if (!this._permissionService.hasPermission(subMenu.permission)) {
            e.subMenu.splice(j, 1);
            j--;
          }
        }
      }
    }
    this.sideBarList.next(sideBar);
  }




}
