import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import * as _ from 'lodash'
import { SessionStorageService } from './session-storage.service';
import { KycServices } from '@page/dashboard/kyc/kyc-services';
import { PermissionServiceService } from './permission-service.service';
import { SidebarComponent } from '@features/layouts/components/sidebar/sidebar.component';
import { SidebarService } from '@features/layouts/components/sidebar/sidebar-service.service';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {
  userLoginDetails: BehaviorSubject<any> = new BehaviorSubject('');


  constructor(
    private sessionStorage: SessionStorageService,
    private kycService: KycServices,
    private permissionService: PermissionServiceService,
    private sidebarService: SidebarService
  ) {

  }

  set setUserLoginDetails(data: any) {
    // console.log(data)
    // let details = this.userLoginDetails.getValue();
    // details = _.merge({}, details, data);
    console.log('setting session');
    this.sessionStorage.setItem('userDetails', data);
    this.sessionStorage.setItemLocalStorage("userDetails", data);
    this.permissionService.initPermissions();
    this.sidebarService.refreshSidebar()
    // this.sessionStorage.setItem('kycSteps', data?.data?.kyc_step);
    this.sessionStorage.setItem('is_kyc', data?.data?.is_kyc);
    this.userLoginDetails.next(data);
  };

  get getUserLoginDetails(): any | Observable<any> {
    return this.userLoginDetails.asObservable();
  };

  delete(clear?: any) {
    const currentItems = this.userLoginDetails.getValue();
    if (clear) {
      var result = _.omit(currentItems, clear)
      this.userLoginDetails.next(result);
    } else {
      this.userLoginDetails.next(null);
    }
  };


  updateLocalStorage(obj: any) {
    const encode: any = this.sessionStorage.getItem('userDetails');
    let loginDtl: any = JSON.parse(encode);
    let userDtl: any = loginDtl;
    if (obj.constructor === Array) {
      obj.forEach((element) => {
        for (const k in element) {
          _.set(userDtl, k, element[k]);
        }
      });
    } else {
      for (const k in obj) {
        _.set(userDtl, k, obj[k]);
      }
    }
    this.setUserLoginDetails = userDtl;
  }
  updateKycDetail(obj: any) {
    let kyc_detail: any = this.kycService.kyc_detail_.getValue();
    if (obj.constructor === Array) {
      obj.forEach((element) => {
        for (const k in element) {
          _.set(kyc_detail, k, element[k]);
        }
      });
    } else {
      for (const k in obj) {
        _.set(kyc_detail, k.split('.'), obj[k]);

      }
    }
    this.kycService.kyc_detail_.next(kyc_detail);
  }
}
