import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-primary-drop-down',
  templateUrl: './primary-drop-down.component.html',
  styleUrls: ['./primary-drop-down.component.scss']
})
export class PrimaryDropDownComponent implements OnInit {

  @Input() dropDownList1:any;
  @Output() selected_value=new EventEmitter<any>();
  keyword = 'name';
  initialValue:string=''
  constructor() { }

  ngOnInit(): void {
  }
  
  onFirmSelect(event){
    this.selected_value.emit(event);
  } 
}
