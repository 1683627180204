import {Injectable} from '@angular/core';
import {SessionStorageService} from "@core/services/session-storage.service";

@Injectable(
  {
  providedIn: 'root'
}
)
export class PermissionServiceService {

   permissions: any;
  constructor(private _sessionStorageService: SessionStorageService) { }


  initPermissions() {
    if(!this._sessionStorageService.getItem('userDetails')) {
      // console.log('here', this.permissions);
      this.permissions = [];
      return []
    };
    this.permissions = JSON.parse(this._sessionStorageService.getItem('userDetails')).permissions;
    // console.log(this.permissions);

    return JSON.parse(this._sessionStorageService.getItem('userDetails')).permissions;
  }
  hasModule(module: string | undefined) {
    if(!module) return true;
    this.permissions ??= this.initPermissions();
    if(!this.permissions) return false;
    return this.permissions.some((permission: any) => {
      return permission.module === module;
    });
  }

  hasPermission(permission: string | undefined, module?: string) {
    if(!permission) return true;
    this.permissions ??= this.initPermissions();

    // console.log(permission)
    if (module) {
      let mod = this.permissions.find((perm: any) => perm.module === module);
      if(mod) {
        return mod.permissions.includes(permission);
      }
      return false;
    }
    if(!this.permissions) return false;
    return this.permissions?.some((perm: any) => {
      return perm.permissions?.includes(permission);
    });
  }
}
