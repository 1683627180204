// setting urls
export const config: any = {

  // encrypt and decrypt key
  aesEnc: {
    key: '6fa979f20126cb08aa645a8f495f6d85',
    iv: 'I8zyA4lVhMCaJ5Kg'
  },

  // sidebar related Urls
  sidebar: {
    token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9',
    url: 'dashboard/dashboard/getsidebar',
  },

  dashboard: {
    transactionList: {
      url: 'recentTransaction'
    },

    leadCount: {
      url: 'dashboard'
    },
    bussinessTrends: {
      url: 'businessTrends'
    },

    userBalance: {
      url: 'userBalance'
    },

    dashboardCount: {
      url: 'dashboad-counts'
    },
    categoryWiseTarget: {
      url: 'category-wise-target'
    },

    paymentModeTransfer: {
      url: 'payment-mode-tranfer'
    },
  },

  tickler: {
    url: 'auth/tickler'
  },

  // Accounts Related URLs
  auth: {
    login: {
      url: 'auth/login',
    },

    verifyOtp: {
      url: 'auth/verify'
    },

    changePassword: {
      url: 'change-password'
    },

    change_user_password: {
      url: 'auth/change-user-password'
    },

    updatePassword: {
      url: 'update-password'
    },

    logout: {
      url: 'user/logout'
    },

    forgetPassword: {
      url: 'auth/user-forgot-password'
    },
    forgetPasswordVerifyOtp: {
      url: 'verifyotp'
    },

    permission: {
      url: 'Settings/Permission'
    },
  },
  // Sign up related urls
  SignUp: {
    createUser: {
      url: 'account/create'
    },
    getCompanyList: {
      url: 'user/company-list'
    },
    assignCompany: {
      url: 'account/company'
    },
    assignContact: {
      url: 'account/contact'
    },
    // *******************
    createNewUser: {
      url: 'user/user-unique'
    },
    ValidateOTP: {
      url: 'user/send-otp'
    },
    sendOTP: {
      url: 'user/user-create'
    },
    sendEmail: {
      url: 'send-verifyemail'
    },
    sendEmailOTP: {
      url: 'emailsendverifyotp'
    },
    verifyEmailOTP: {
      url: 'emailverifyotp'
    },
    resellerDetails: {
      url: 'get-reseller-details'
    },
  },
  //Validate otp related Urls
  ValidateOtp: {
    otp: {
      url: 'account/otp'
    },
  },
  // KYC related Urls
  UserKyc: {
    contactDetail: {
      url: 'contact-details'
    },
    gstnDetail: {
      url: 'kyc/get-gstn'
    },
    udyamDetail: {
      url: 'kyc/get-udyam-details'
    },
    shopStateList: {
      url: 'kyc/state-list'
    },
    getShopDetail: {
      url: 'kyc/shop-details'
    },
    addBusinessDetail: {
      url: 'kyc/store-business-details'
    },
    storeUdhyamDetails: {
      url: 'kyc/store-udyam-details'
    },

    storeShopDetails: {
      url: 'kyc/store-shop-details'
    },

    addStoreUdyamDetail: {
      url: 'kyc/store-udyam-details'
    },
    addStoreShopDetail: {
      url: 'kyc/store-shop-details'
    },

    getBankList: {
      url: 'bank/bank-master'
    },
    getBankListForFund: {
      url: 'get-bank-list'
    }
    ,
    chequeOcr: {
      url: 'kyc/bank-ocr'
    }
    ,
    saveBankDetail: {
      url: 'kyc/bank-verify'
    },
    finalKycUpdate: {
      url: 'kyc/final-kyc-update'
    },
    createVirtualAccount: {
      url: 'virtual-account'
    },
    adminKycDetail: {
      url: 'kyc/kyc-details'
    },
    getDocumentList: {
      url: 'kyc/document-list'
    },
    uploadDocument: {
      incorporatepanDetail: {url: 'kyc/pan-ocr'},
      panVerify: {url: 'kyc/pan-verify'},
      coiDetail: {url: 'kyc/coi-verify'},
      gstDetail: {url: 'kyc/gst-ocr'},
      addShopUdyamDoc: {url: 'kyc/udyam-and-otherdoc'},
      moa_aoa_Detail: {url: 'kyc/moa-aoa'},
      boardResolution: {url: 'kyc/board-resolution'},

      partnerPanOcr: {url: 'kyc/partner-panocr'},
      partnerPanverify: {url: 'kyc/partner-panverify'},
      aadharOtp: {url: 'kyc/partner-aadharotp'},
      verifyAadharOtp: {url: 'kyc/partner-aadharverify'},
      verifyVoterId: {url: 'kyc/partner-voteridverify'},
      dlverify: {url: 'kyc/partner-dl'},
      ppverify: {url: 'kyc/partner-pp'},
      aadhar_store: {url: 'kyc/aadhar-store'},
      aadhar_ocr: {url: 'kyc/aadhar-ocr'},
      trustDeed: {url: 'kyc/trust-deed-or-mou'},
      byLawsDoc: {url: 'kyc/bylows-verify'},
      llpAgreement: {url: 'kyc/llp-agreement'},
      letterForPerson: {url: 'kyc/aletter-for-aperson'}
      // panDetail:{url:'company/pan'},
      // voterIdDetail:{url:'business/add'},
    },
    resellerRequest: {
      url: 'kyc/reseller-request'
    },
    // fetchPanName: {
    //   url: 'document/kyc/pan-fetch'
    // }
  },
  // White List
  WhiteList: {
    addWhiteListBank: {
      url: 'bank/add-account'
    },
    whiteListBank: {
      url: 'bank/get-account-list'
    },
  },
  Statements: {
    payoutStatements: {
      url: 'statement',
      download: 'save-axis-payout'
    },

    ledgerStatements: {
      url: 'txn-ledger',
      download: 'save-txn-ledger'
    },

    pennyDropStatements: {
      url: 'penny-statement',
      download: 'save-penny-payout'
    },

  },
  FundRequest: {
    onlineTransfer: {
      url: 'fund/createrequest'
    },
    allRequest: {
      url: 'fund/getrequest'
    },
    allRequestDownload: {
      url: 'fund/request-download'
    }
  },
  NewPayouts: {
    addContact: {
      url: 'payout/add-contact'
    },
    listContact: {
      url: 'payout/list-contact'
    }
  },
  getCredentials: {
    url: 'credentials/get-credentials'
  },
  sendCredentials: {
    url: 'credentials/send-credentials'
  },
  Addbeneficiary: {
    addbeneficiary: {
      url: 'payout/add-bene'
    },
    getbeneficiary: {
      url: 'payout/list-bene'
    },
    getStateMaster: {
      url: 'state'
    },
    getCityMaster: {
      url: 'city'
    },
    getPincode: {
      url: 'pincode'
    },
    get_pincode_details: {
      url: 'get-pincode-details'
    },
  },
  PayoutRequest: {
    otpPayout: {
      url: 'payout/send-otp'
    },
    axisSinglePayout: {
      url: 'payout/axis-single-payout'
    }
  },
  vaAccount: {
    url: 'va/get-virtual-account'
  },

  pennyDrop: {
    url: 'payout/peny_drop'
  },

  chargesslab: {
    url: 'payout/getchargesslab'
  },
  axis_bulk_payout: {
    url: 'payout/axis-bulk-payout'
  },
  payout_otp: {
    url: 'payout/send-payout-otp'

  },
  payout_list: {
    url: 'payout/bulk-payout-list'

  },
  shouldApiEncryptDecrypt: {
    url: 'should-encrypt'
  },
  profilePicture: {
    url: 'user/profile-picture'
  },
  // White List
  notification: {
    updateNotification: {
      url: 'notification/update-notification'
    },
  },
  connected_banking: {
    add_bank: {
      url: 'connected-bank/generate-lead'
    },
    open_bank: {
      url: 'connected-bank/lead-data'
    },
    search_bank: {
      url: 'connected-bank/search'
    },
    link_bank: {
      url: 'connected-bank/add'
    },
    bank_balance: {
      url: 'connected-bank/balance'
    },
    transfer_fund: {
      url: 'connected-bank/transfer'
    },
    status_enquiry: {
      url: 'connected-bank/status'
    },
    add_bene: {
      url: 'connected-bene/add-bene-maker'
    },
    bene_list: {
      url: 'connected-bene/bene-list'
    },
    fav_bene: {
      url: 'connected-bank/fav-bene'
    },
    initiate_transfer: {
      url: 'connected-bank/transfer'
    },
    statement: {
      url: 'connected-bank/statement'
    },
    mini_statement: {
      url: 'connected-bank/self-statement'
    },
    scheduled_statement: {
      url: 'connected-bank/scheduled-payments'
    },
    cancel_scheduled: {
      url: 'connected-bank/cancel-scheduled'
    },
    bulk_payout_otp: {
      url: 'connected-bank/send-otp'
    },
    connected_bulk_payout: {
      url: 'connected-bank/bulkpayout'
    },
    sub_user_list: {
      url: 'connected-bene/sub-user'
    },
    send_otp_maker: {
      url: 'connected-bene/send-otp'
    },
    verify_bene_maker: {
      url: 'connected-bene/set-bene-maker'
    },
    verify_otp_maker: {
      url: 'connected-bank/verify-otp-maker'
    },

    nonauth_bene_list: {
      url: 'connected-bene/bene-list'
    },
    auth_bene_list: {
      url: 'connected-bank/list-bene-maker'
    },
    beneficiary_details: {
      url: 'connected-bene/beneficiary-details'
    },
    send_otp_bene: {
      url: 'connected-bank/send-otp-bene'
    },
    verifiy_otp_nonauthbene: {
      url: 'connected-bene/authorise-bene'
    },
    reject_nonauthbene: {
      url: 'connected-bank/reject-beneficiary'
    },
    payout_authorize_transfer: {
      url: 'connected-bank/authorize-transfer'
    },
    payout_initiate_transfer: {
      url: 'connected-bank/initiate-transfer'
    },
    update_bene_limit: {
      url: 'connected-bene/update-bene-limit'
    },
    update_limit: {
      url: 'connected-bank/update-limit'
    },
    repeatPayment: {
      url: 'connected-bank/repeat-payment'
    }

  },
  user_management: {

    managementList: {
      url: 'user-management/list'
    },
    createUser: {
      url: 'user-management/create'
    },
    updateUser: {
      url: 'user-management/update'
    },
    resetPassword: {
      url: 'user-management/reset-password'
    },
    permissions: {
      url: 'user-management/get-permission'
    },

  },
  reseller: {
    reinvite_merchant: {
      url: 'reseller/reinvite-merchant'
    },
    reseller_refferal: {
      url: 'reseller/get-referral'
    },
    merchant_list: {
      url: 'reseller/merchant-list'
    },
    createMerchant: {
      url: 'reseller/create-merchant'
    },
    getCharge: {
      url: 'charges/get-charges'
    },
    performanceCard: {
      url: 'reseller/reseller-performance'
    },
    performanceGraph: {
      url: 'reseller/reseller-performance-graph'
    },
    businessGraph: {
      url: 'reseller/reseller-business-graph'
    },
    transactionList: {
      url: 'reseller/list-transection'
    },
    resellerClient: {
      url: 'reseller/reseller-client'
    }
  },
  payin:{
    vpaList:{
      url:'payin/vpa-list',
    },
    vpaCreate:{
      url:'payin/vpa-create',
    },
    transactionList:{
      url:'payin/transaction-list',
    },
    dynamicQR:{
      url:'payin/dynamic-qr',
    },
    staticQR:{
      url:'payin/static-qr',
    }
  }
} as const;


