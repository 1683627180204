<div class="icici-popup">
  <div class="blue-bar">
      <div
          class="modal-search link-bank d-flex justify-content-between align-items-center">
          <h2>Link your ICICI Bank Current Account</h2>
          <button type="button" class="close-btn" data-dismiss="modal" aria-label="Close" (click)="closeModal($event)">
              <span aria-hidden="true">×</span>
          </button>
      </div>
      <div class="text-center p-5">
          <div class="mt-3 mb-5"><img src="assets/assets/img/icici.svg"></div>
          <h3>Approve Integration Connection Request</h3>
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
              eirmod
              tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero
              eos et
              accusam et justo</p>

          <button class="btn save-btn mt-4" *ngIf="modalSetting.btnConfirm" (click)="confirmModal($event)">
            {{modalSetting.btnConfirm}}
          </button>
      </div>
  </div>
</div>
