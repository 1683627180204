import { OnDestroy, Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { KYC_Service_Steps } from '@core/common/common-config';
import { SessionStorageService } from '@core/services/session-storage.service';
import { UserDetailsService } from '@core/services/user-details.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthCoreService } from '@page/auth/auth.service';
import { DashboardService } from '../dashboard.service';
import { KycServices } from './kyc-services';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss']
})
export class KycComponent implements OnInit, OnDestroy {
  kycServiceSteps: any[] = [...KYC_Service_Steps];
  documentDetail: any;
  kycSteps: any;
  userDetail: any;
  selectedkyc: any;
  kycDetail: any;
  userLoginDetail: any;
  constructor(
    public activeModal: NgbActiveModal,
    private kycServices: KycServices,
    private sessionStorage: SessionStorageService,
    private authCoreService: AuthCoreService,
    private ele: ElementRef,
    private userDetailsService: UserDetailsService,
    private dashBordService: DashboardService
  ) {
    this.kycServiceSteps[2].sub_type = [];
    this.dashBordService.getCompanyList();
    this.userDetailsService.userLoginDetails.subscribe({
      next: (value: any) => {
        this.userLoginDetail = value?.data;
        this.kycSteps = value?.data?.kyc_step;
        this.userDetail = value;
        this.kycServiceSteps[0].status = this.userDetail?.data?.email_verify ? 1 : 2;
        this.kycServices.finalKycStep.next(this.userDetail.data.kyc_step);
        this.kycServices.user_detail.next(this.userDetail?.data);
      }
    });

    // this.kycDetail=this.kycServices.kyc_detail_.getValue();

    //   if(this.kycDetail)  {
    //     this.kycDetail.document_list.forEach(element => {
    //       if (this.kycDetail.kyc_list.hasOwnProperty(element.value)) {
    //         let currentKey = element.value;
    //         if (currentKey == 'kycpartnership') {
    //           this.kycServiceSteps[2].sub_type.push({ name: element.name, selected: element.step, is_edit: element.is_edit, status: (currentKey == 'kycpartnership' && (this.kycDetail.kyc_list)[`${currentKey}`].length != 0) ? (this.kycDetail.kyc_list)[`${currentKey}`][0].status : 3 })
    //         } else {
    //           this.kycServiceSteps[2].sub_type.push({ name: element.name, selected: element.step, is_edit: element.is_edit, status: (this.kycDetail.kyc_list)[`${currentKey}`] ? (this.kycDetail.kyc_list)[`${currentKey}`].status : 3 })
    //         }
    //       }
    //     });
    //     for (const key in this.kycDetail.kyc_list) {
    //       if((key === 'businessdetail' || key === 'bankdetail' )&& this.kycDetail.kyc_list[`${key}`] ){
    //         this.kycServiceSteps[1].status=key === 'businessdetail' && this.kycDetail.kyc_list[`${key}`]?this.kycDetail.kyc_list[`${key}`].status:3;
    //         this.kycServiceSteps[3].status=key === 'bankdetail' && this.kycDetail.kyc_list[`${key}`]?this.kycDetail.kyc_list[`${key}`].status:3;
    //       }  }
    // } else{
    //   this.getKycViewModeDetail();
    // }

  }

  ngOnInit(): void {


    this.kycServices.kyc_detail_.subscribe(res => {
      if (res) {
        this.kycDetail = res;
        if (this.kycDetail) {
          this.kycServiceSteps[2].sub_type = [];
          this.kycDetail.document_list.forEach(element => {
            if (this.kycDetail.kyc_list.hasOwnProperty(element.value)) {
              let currentKey = element.value;
              let kycPartnerStatus = 0;
              let kycPartners = (this.kycDetail.kyc_list)[`${currentKey}`];
              if (currentKey == 'kycpartnership') {
                if (kycPartners.length != 0) {
                  const isRejected = kycPartners.filter(r => r.status == 0);
                  const isPending = kycPartners.filter(r => r.status == 2);
                  const isApprove = kycPartners.filter(r => r.status == 1);
                  const isLimitOver = kycPartners.filter(r => r.status == 4);
                  kycPartnerStatus = isRejected.length > 0 ? isRejected[0].status : isLimitOver.length > 0 ? isLimitOver[0].status : isPending.length > 0 ? isPending[0].status : isApprove[0].status

                }
                this.kycServiceSteps[2].sub_type.push({ name: element.name, selected: element.step, is_edit: element.is_edit, status: (currentKey == 'kycpartnership' && (this.kycDetail.kyc_list)[`${currentKey}`].length != 0) ? kycPartnerStatus : 3 })
              } else {
                // console.log(currentKey)
                this.kycServiceSteps[2].sub_type.push({ name: currentKey == 'kyctrustdeedormou' && this.userLoginDetail.company_id == 2 ? 'Partnership Deed' : element.name, selected: element.step, is_edit: element.is_edit, status: (this.kycDetail.kyc_list)[`${currentKey}`] ? (this.kycDetail.kyc_list)[`${currentKey}`].status : 3 })
              }
            }
          });
          // console.log(this.kycServiceSteps[2].sub_type)
          for (const key in this.kycDetail.kyc_list) {
            if (key === 'businessdetail') {
              this.kycServiceSteps[1].status = this.kycDetail.kyc_list[`${key}`] ? this.kycDetail.kyc_list[`${key}`].status : 3;
            } else if (key === 'bankdetail') {
              this.kycServiceSteps[3].status = this.kycDetail.kyc_list[`${key}`] ? this.kycDetail.kyc_list[`${key}`].status : 3;
            }
          }
        }
        setTimeout(() => {
          if (this.ele.nativeElement.querySelectorAll('.rejectedLink').length > 0) {
            this.ele.nativeElement.querySelectorAll('.rejectedLink')[0].click();
          }

        }, 1);
      }
    })

    this.selectedkyc = Number(this.kycSteps);
    this.authCoreService.isSessionTimeOut.subscribe(res => { if (res) { this.closeTab(); } });




  }

  // async getKycViewModeDetail() {
  //   this.kycServiceSteps[2].sub_type = [];
  //   this.kycDetail = '';
  //   this.kycDetail = await this.kycServices.getKycDetail();
  //   this.kycServices.kyc_detail_.next(this.kycDetail);
  //   this.kycDetail.document_list.forEach(element => {
  //     if (this.kycDetail.kyc_list.hasOwnProperty(element.value)) {
  //       let currentKey = element.value;
  //       if (currentKey == 'kycpartnership') {
  //         this.kycServiceSteps[2].sub_type.push({ name: element.name, selected: element.step, is_edit: element.is_edit, status: (currentKey == 'kycpartnership' && (this.kycDetail.kyc_list)[`${currentKey}`].length != 0) ? (this.kycDetail.kyc_list)[`${currentKey}`][0].status : 3 })
  //       } else {
  //         this.kycServiceSteps[2].sub_type.push({ name: element.name, selected: element.step, is_edit: element.is_edit, status: (this.kycDetail.kyc_list)[`${currentKey}`] ? (this.kycDetail.kyc_list)[`${currentKey}`].status : 3 })
  //       }
  //     }
  //   });
  //   for (const key in this.kycDetail.kyc_list) {
  //     if ((key === 'businessdetail' || key === 'bankdetail') && this.kycDetail.kyc_list[`${key}`]) {
  //       this.kycServiceSteps[1].status = key === 'businessdetail' && this.kycDetail.kyc_list[`${key}`] ? this.kycDetail.kyc_list[`${key}`].status : 3;
  //       this.kycServiceSteps[3].status = key === 'bankdetail' && this.kycDetail.kyc_list[`${key}`] ? this.kycDetail.kyc_list[`${key}`].status : 3;
  //     }
  //   }
  // }
  onClickOuterLi(item: any) {
    if (item.selected < this.kycSteps && item.selected != 3) {
      this.selectedkyc = item.selected;
      // this.callKycDetail(item.selected);
    } else if (item.selected == this.kycSteps && item.selected != 3) {
      this.selectedkyc = item.selected;
    }
  }
  onClickInnerList(value: any) {
    if (value.selected < this.kycSteps) {
      this.selectedkyc = value.selected;
      this.kycServices.selectedKycStep.next(value.selected);
      // this.callKycDetail(value.selected);
    } else if (value.selected == this.kycSteps) {
      this.selectedkyc = value.selected;
      this.kycServices.selectedKycStep.next(value.selected);
    }
  }
  closeTab() {
    this.activeModal.close({ 'close': true });
  }
  onSaveBusinessDetail(value: any) {
    if (value) {
      this.activeModal.close();
    }
  }
  onSubmitBankDetail(value: boolean) {
    this.activeModal.close();
  }
  onDocumentUpload(value: any) {
    this.kycSteps = value;
    this.selectedkyc = value;
  }
  // callKycDetail(kycStep: any) {
  //   const kycDetail_ = this.kycDetail.kyc_list;
  //   switch (kycStep) {
  //     case 2:
  //       if (!kycDetail_.businessdetail) {
  //         this.getKycViewModeDetail();
  //       }
  //       break;
  //     case 3:
  //       if (!kycDetail_.kycpan) {
  //         this.getKycViewModeDetail();
  //       }
  //       break;
  //     case 4:
  //       if (!kycDetail_.kyccoi) {
  //         this.getKycViewModeDetail();
  //       }
  //       break;
  //     case 5:
  //       if (!kycDetail_.kycgst) {
  //         this.getKycViewModeDetail();
  //       }
  //       break;
  //     case 6:
  //       if (!kycDetail_.moaaoakyc) {
  //         this.getKycViewModeDetail();
  //       }
  //       break;
  //     case 7:
  //       if (!kycDetail_.kycboard) {
  //         this.getKycViewModeDetail();
  //       }
  //       break;
  //     case 8:
  //       if (kycDetail_.kycpartnership.length == 0) {
  //         this.getKycViewModeDetail();
  //       }
  //       break;
  //     case 9:
  //       if (!kycDetail_.bankdetail) {
  //         this.getKycViewModeDetail();
  //       }
  //       break;
  //   }
  // }
  ngOnDestroy(): void {
    if ((this.kycServices.finalKycStep.getValue() == 10) && this.userDetail.data.is_kyc != 1) {
      let payload_: FormData = new FormData();
      this.kycServices.finalKycUpdate(payload_).subscribe(res => {

      })
    }
    this.kycServices.selectedKycStep.next('');
    this.kycServices.resellerData.next('');
  }
  // async callKyc() {
  //   this.kycDetail = await this.kycServices.getKycDetail();
  //   this.kycServices.kyc_detail_.next(this.kycDetail.kyc_list);
  // }

}
