import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild,OnDestroy } from '@angular/core';
import { CommonService } from '@core/services/common.service';
import { FileuploadService } from '@core/services/file-upload.service';
import { config } from '@core/services/request-url.service';
import { SessionStorageService } from '@core/services/session-storage.service';
import { NgxToasterService } from '@core/services/toasterNgs.service';
import { UserDetailsService } from '@core/services/user-details.service';
import { ConfirmationComponent } from '@features/customCDK/cdkCustomMdl/components/confirmation/confirmation.component';
import { ConfirmationModalConfig, ConfirmationModalService } from '@features/customCDK/cdkCustomMdl/services/confirmation-modal.service';
import { filter } from 'rxjs';
import { KycServices } from '../kyc-services';

@Component({
  selector: 'app-verify-document',
  templateUrl: './verify-document.component.html',
  styleUrls: ['./verify-document.component.scss'],
})
export class VerifyDocumentComponent implements OnInit, AfterViewInit,OnDestroy {

  isImageDisabled: boolean = false;
  status: any = '';
  @Input() documentDetail: any;
  @Output() isKycStep = new EventEmitter();
  document_list:any[]=[];
  _kyc_list:any={};
  fileType: string = '';
  fileType1: string = '';
  file_url: string = '';
  file_url1: string = '';
  pdfPath: string = '';
  document_name: string = '';
  remarks: string = '';
  common_msg: string = 'Details should be of the mentioned business only';
  file_: File;
  current_doc_obj:any={};
  userPanName: string = '';
  childKycStep: number | string;
  kyc_steps: number | string = 0;
  // uploadFileType = 'JPG, JPEG, PNG, PDF';
  unlinkUrl:string='';
  unlinkUrl1:string='';
  documentType:string='';
  userData:any;
  constructor(
    private kycServices: KycServices,
    private toaster: NgxToasterService,
    private sessionStorage: SessionStorageService,
    private _FileuploadService: FileuploadService,
    private userDetailsService: UserDetailsService,
    private _CommonService:CommonService,
    private _ConfirmationModalService: ConfirmationModalService,
  ) { }

  ngOnInit(): void {
    this.current_doc_obj={};
    this.setDefaultImage();
    this.getuserDetail();
    this.userDetailsService.userLoginDetails.subscribe({
      next: (value: any) => {
        this.userData=value?.data;
        this.kyc_steps = value?.data?.kyc_step;
      }
    });
    this.kycServices.kyc_detail_.subscribe(res => {
      if (res) {
        this.document_list=res.document_list;
        this._kyc_list=res.kyc_list;
        this.current_doc_obj= this.document_list.filter(d=> d.step == this.kyc_steps)[0];
        if(this.current_doc_obj){
          this.getDocumentFormate(this.current_doc_obj);
        }

      }
    });
    this.documentType=this._kyc_list?.businessdetail?.doctype;
    this.kycServices.selectedKycStep.subscribe(res => {
      if (res) {
        this.kyc_steps = res;
        this.childKycStep = res;
        this.current_doc_obj= this.document_list.filter(d=> d.step == this.kyc_steps)[0];
        this.kycServices.selectedChildKycStep.next(this.childKycStep);
        this.getDocumentFormate(this.current_doc_obj);
        setTimeout(() => {
          this.bindDocumentUrls(this.kyc_steps)
        }, 1);
      }
    })

  }
  getuserDetail() {
    let _userDetail = this.sessionStorage.getItem('userDetails') ? JSON.parse(this.sessionStorage.getItem('userDetails')) : {};
    if (_userDetail) { this.userPanName = _userDetail.data.name; }
  }
  ngAfterViewInit(): void {

  }

  onChildDocChange(event) {
    this.getDocumentUrl(event, 1);
  }
  onFileChange(event) {
    this.getDocumentUrl(event, 2);
  }
  getDocumentUrl(event, fileno) {

    this.file_ = <File>event.target.files[0];
    var maxfilesize = 2 * (1024 * 1024)  // 2 Mb
    var filesize = this.file_.size
    if (maxfilesize >= filesize) {
      // const fileExt=this.file_.name.split('.')[1];
      const fileExt = this.file_.name.split('.')[(this.file_.name.split('.').length) - 1].toLowerCase();
      if ((this.current_doc_obj?.type != 'pdf') && (fileExt != 'jpeg' && fileExt != 'jpg' && fileExt != 'png')) {
        this.toaster.showError('You can only upload JPG, PNG and JPEG file', 'Error');
        return;
      }
      if (this.current_doc_obj?.type == 'pdf' && fileExt != 'pdf') {
        this.toaster.showError('You can only upload PDF file', 'Error');
        return;
      }

      let customConfig: ConfirmationModalConfig = {
        data: {
          icon: 'info',
          title: 'Final Confirmation?',
          title2: '',
          msg: "Are you sure to proceed with these documents, NOTE: Details once submitted will not be changed?",
          btnConfirm: 'Confirm',
          btnClose: 'Cancel'
        },
      };

      let confirmationRef =
        this._ConfirmationModalService.open<ConfirmationComponent>(
          ConfirmationComponent,
          customConfig
        );
      confirmationRef.events.pipe(
        filter((event) => {
          // console.log(event);
          return !!event
        }),
      ).subscribe(async (events) => {
        switch (events.type) {
          case 'confirm':

            let uploadedFile = await this._FileuploadService.uploadFileFn(this.file_, this.unlinkUrl);
            confirmationRef.close(customConfig);

            if (uploadedFile.response == 1) {
              this.bindValue(uploadedFile.pan_proof, fileno, this.file_.name);
            } else {
              this.toaster.showError(uploadedFile.message, 'Error');
            }
            break;
          case 'close':
            // console.log('close modal');
            confirmationRef.close(customConfig);
            break;
        }
      });

    }
    else {
      this.toaster.showInfo('File size should not be greater than 2MB', "Info")
    }
  }
  bindValue(imgUrl, file_no, file) {
    switch (file_no) {
      case 1:
        this.fileType = '';
        this.fileType = ((file).split('.')[1]).toLowerCase();
        this.file_url = imgUrl;
        // if (this.kyc_steps == 6){this.selectedfile.nativeElement.value = "";}
        break;
      case 2:
        this.fileType1 = '';
        // this.selectedfile2.nativeElement.value = "";
        this.fileType1 = ((file).split('.')[1]).toLowerCase();
        this.file_url1 = imgUrl;
        break;
    }
    // this.verifyOcrDocument()
  }

   verifyOcrDocument() {

    let commonUrl = config.UserKyc.uploadDocument;
    let requesrUrl = "";
    let payLoad = new FormData();
    this.document_name = '';

    const request_doc= this.document_list.filter(d=> d.step == this.kyc_steps)[0];
    // console.log(request_doc.value);
    this.document_name=request_doc.value;
    //console.log(this.document_name)
    switch (this.document_name) {
      case 'kycpan':
        // this.document_name = 'kycpan';
        requesrUrl = commonUrl.incorporatepanDetail;
        payLoad.append('file', this.file_);
        payLoad.append('type', 'PAN');
        payLoad.append('fileurl', this.file_url)
        break;
      case 'kyccoi':
        // this.document_name = 'kyccoi';
        requesrUrl = commonUrl.coiDetail;
        payLoad.append('fileurl', this.file_url)
        break;
        case 'kycbylows':
        // this.document_name = 'kyccoi';
        requesrUrl = commonUrl.byLawsDoc;
        payLoad.append('fileurl', this.file_url)
        break;
        case 'kycaletteraperson':
        requesrUrl = commonUrl.letterForPerson;
        payLoad.append('fileurl', this.file_url)
        break;
        case 'kycllpagreement':
        requesrUrl = commonUrl.llpAgreement;
        payLoad.append('fileurl', this.file_url)
        break;
        case 'kyctrustdeedormou':
        requesrUrl = commonUrl.trustDeed;
        payLoad.append('fileurl', this.file_url)
        break;
      case 'kycgst':
        if(this.documentType == 'GST' || !this.documentType){
          requesrUrl = commonUrl.gstDetail;
          payLoad.append('file', this.file_);
          payLoad.append('type', 'GST');
        }
        else{
          requesrUrl = commonUrl.addShopUdyamDoc;
          payLoad.append('type', this.documentType);
        }
          payLoad.append('fileurl', this.file_url)
        break;
      case 'moaaoakyc':
        // this.document_name = 'moaaoakyc';
        requesrUrl = commonUrl.moa_aoa_Detail;
        payLoad.append('moaurl', this.file_url);
        payLoad.append('aoaurl', this.file_url1);
        break;
      case 'kycboard':
        // this.document_name = 'kycboard';
        requesrUrl = commonUrl.boardResolution;
        payLoad.append('boardurl', this.file_url);
        break;
    }
    if (this.file_url == 'assets/assets/img/upload_file.png') {
      return;
    } else if (this.kyc_steps == 6 && this.file_url1 == 'assets/assets/img/upload_file.png' && this.current_doc_obj.value == 'moaaoakyc') {

      return;
    }

           this.kycServices.addBusinessDocuments(payLoad, requesrUrl).subscribe(res => {
            if (res.statuscode == 200 ) {
              if (this.kyc_steps == 3) {
                if(res.reupload){
                  this.toaster.showError(res.message, 'Error');
                }else if(!res.reupload && !res.call_api_verify){
                  this.getSuccessRes(res);
                  this.toaster.showSuccess(res.message, 'Success');
                }
                else{
                  const payLoad_ = new FormData();
                  payLoad_.append('pan_number', res?.data?.pan_number);
                  this.kycServices.addBusinessDocuments(payLoad_, commonUrl.panVerify).subscribe(respo => {
                    if (respo.statuscode == 200 ) {
                      if(respo.reupload){
                        this.toaster.showError(respo.message, 'Error');
                      }else{
                        this.getSuccessRes(respo);
                        this.toaster.showSuccess(respo.message, 'Success');
                      }
                    } else {
                      this.toaster.showError(res.message, 'Error');
                    }
                  })
                }
              } else {

                if(this.documentType == 'GST' || !this.documentType){
                  if(!res.reupload){
                    this.toaster.showSuccess(res.message, 'Success');
                    this.getSuccessRes(res);
                  }else{
                    this.toaster.showError(res.message, 'Error');
                  }
                }else{
                  this.toaster.showSuccess(res.message, 'Success');
                  this.getSuccessRes(res);
                }
              }
            } else {
              this.toaster.showError(res.message, 'Error');
            }
          },(error)=>{
            this.toaster.showError(error.message, 'Error')
          })


  }
  getSuccessRes(res:any){
    if (this.kycServices.finalKycStep.getValue() != 10) {
      this.kyc_steps = res.kyc_step;
      this.isKycStep.emit(res.kyc_step);
      this.userDetailsService.updateLocalStorage(
        { ['data.kyc_step']: res.kyc_step }
      )
      this.updateKycDetail(res);
      this.setDefaultImage();
    } else {
      this.updateKycDetail(res);
      let reqData: FormData = new FormData();
      this.kycServices.finalKycUpdate(reqData).subscribe(res => { })
    }
  }
  setDefaultImage() {
    this.fileType = '';
    this.fileType1 = '';
    this.unlinkUrl='';
    this.unlinkUrl1='';
    this.file_url = 'assets/assets/img/upload_file.png';
    this.file_url1 = 'assets/assets/img/upload_file.png';
    this.pdfPath = 'assets/img/Mask Group 43.png';
  }
  onSubmitKyc(value) {
    this.isKycStep.emit(value);
    this.kyc_steps = value;
  }
  bindDocumentUrls(childKycStep) {
    this.file_url = 'assets/assets/img/upload_file.png';
    this.file_url1 = 'assets/assets/img/upload_file.png';
    this.pdfPath = 'assets/img/Mask Group 43.png';
    var uploadedData: any = {};
    this.kycServices.kyc_detail_.subscribe(res => {
      if (res) {
        uploadedData = res.kyc_list;

      }
    });

    this.isImageDisabled = false;
    this.status='';
    this.fileType='';
    this.unlinkUrl='';
    this.unlinkUrl1='';
    const request_doc= this.document_list.filter(d=> d.step == childKycStep)[0];
    const doc_name=request_doc.value;
    switch (doc_name) {
      case 'kycpan':
        if (uploadedData.kycpan && uploadedData?.kycpan?.status != 4) {
          this.file_url = !uploadedData.kycpan.status?this.file_url:  uploadedData.kycpan.pan_path;
          this.setDocumentValue(uploadedData.kycpan,uploadedData.kycpan.pan_path);
        }
        break;
      case 'kyccoi':
        if (uploadedData.kyccoi) {
          this.file_url = !uploadedData.kyccoi.status?this.file_url:  uploadedData.kyccoi.file_path;
          this.setDocumentValue(uploadedData.kyccoi,uploadedData.kyccoi.file_path);
        }
        break;
        case 'kycaletteraperson':
        if (uploadedData.kycaletteraperson) {
          this.file_url = !uploadedData.kycaletteraperson.status?this.file_url:  uploadedData.kycaletteraperson.file_path;
          this.setDocumentValue(uploadedData.kycaletteraperson,uploadedData.kycaletteraperson.file_path);
        }
        break;
        case 'kycllpagreement':
          if (uploadedData.kycllpagreement) {
            this.file_url = !uploadedData.kycllpagreement.status?this.file_url:  uploadedData.kycllpagreement.file_path;
            this.setDocumentValue(uploadedData.kycllpagreement,uploadedData.kycllpagreement.file_path);
          }
          break;
          case 'kyctrustdeedormou':
            if (uploadedData.kyctrustdeedormou) {
              this.file_url = !uploadedData.kyctrustdeedormou.status?this.file_url:  uploadedData.kyctrustdeedormou.file_path;
              this.setDocumentValue(uploadedData.kyctrustdeedormou,uploadedData.kyctrustdeedormou.file_path);
            }
            break;
      case 'kycgst':
        if (uploadedData.kycgst) {
          this.file_url = !uploadedData.kycgst.status?this.file_url:  uploadedData.kycgst.gst_path;
          this.setDocumentValue(uploadedData.kycgst,uploadedData.kycgst.gst_path);
        }
        break;
        case 'kycbylows':
        if (uploadedData.kycbylows) {
          this.file_url = !uploadedData.kycbylows.status?this.file_url:  uploadedData.kycbylows.file_path;
          this.setDocumentValue(uploadedData.kycbylows,uploadedData.kycbylows.file_path);
        }
        break;
      case 'moaaoakyc':
        if (uploadedData.moaaoakyc) {
          this.file_url = !uploadedData.moaaoakyc.status?this.file_url:  uploadedData.moaaoakyc.moafilepath;
          this.file_url1 = !uploadedData.moaaoakyc.status?this.file_url1: uploadedData.moaaoakyc.aoafilepath;
          this.unlinkUrl=uploadedData.moaaoakyc.moafilepath;
          this.unlinkUrl1=uploadedData.moaaoakyc.aoafilepath;
          this.isImageDisabled = uploadedData.moaaoakyc.status ? true : false;
          this.status=uploadedData.moaaoakyc.status;
          this.remarks = uploadedData.moaaoakyc.remarks;
          const extLen=this.file_url.split('.').length;
          this.fileType=this.file_url.split('.')[`${extLen-1}`].toLowerCase();
          const extLen1 = this.file_url.split('.').length;
          this.fileType1 = this.file_url.split('.')[`${extLen1 - 1}`].toLowerCase();
        }
        break;
      case 'kycboard':
        if (uploadedData.kycboard) {
          this.file_url = !uploadedData.kycboard.status?this.file_url:  uploadedData.kycboard.filepath;
          this.setDocumentValue(uploadedData.kycboard,uploadedData.kycboard.filepath);
          break;
        }
    }
  }
  setDocumentValue(data: any,unlink_url:any) {
    this.unlinkUrl=unlink_url;
    this.isImageDisabled = data.status || !data.update_counter ? true : false;
    this.status = data.status;
    this.remarks = data.remarks;
    const extLen = this.file_url.split('.').length;
    this.fileType = this.file_url.split('.')[`${extLen - 1}`].toLowerCase();
  }

  updateKycDetail(data:any) {
    var jsonObj: any = {};
    switch (this.document_name) {
      case 'kycboard':
        jsonObj.filepath = this.file_url;
        break;
      case 'kyccoi':
        jsonObj.file_path = this.file_url;
        break;
      case 'kycgst':
        jsonObj.gst_path = this.file_url;
        break;
      case 'kycpan':
        jsonObj.pan_path = this.file_url;

        break;
      case 'moaaoakyc':
        jsonObj.moafilepath = this.file_url;
        jsonObj.aoafilepath = this.file_url1;
        break;
        case 'kycaletteraperson':
          jsonObj.file_path = this.file_url;
          break;
          case 'kycbylows':
          jsonObj.file_path = this.file_url;
          break;
          case 'kycllpagreement':
            jsonObj.file_path = this.file_url;
          break;
          case 'kyctrustdeedormou':
            jsonObj.file_path = this.file_url;
          break;
    }

    jsonObj.status = data.doc_status?data.doc_status: 2;
    jsonObj.update_counter = 0;
    this.userDetailsService.updateKycDetail(
      { [`kyc_list.${this.document_name}`]: jsonObj }
    )
  }

  ngOnDestroy(): void {
  }

  getDocumentFormate(data:any){
    if(data.value == 'moaaoakyc' || data.value == 'kyccoi' || data.value == 'kycboard' || data.value == 'kycllpagreement' || data.value == 'kyctrustdeedormou' || data.value == 'kycaletteraperson' || data.value == 'kycbylows'){
      this.current_doc_obj.document_type='PDF Only';
      this.current_doc_obj.type='pdf';
    }else if(data.value == 'kycpan' || data.value == 'kycgst'){
      this.current_doc_obj.document_type='JPG, JPEG, PNG';
    }

  }
}
