import { BehaviorSubject, Subscription } from 'rxjs';
import { ApiRequestService } from '@core/services/api-request.service';
import { Injectable, Output, EventEmitter } from '@angular/core';

import { config } from './../../core/services/request-url.service';
import { NgxToasterService } from '@core/services/toasterNgs.service';
import { SignUpService } from '@page/auth/sign-up.service';
import { TicklerService } from '@page/tickler.service';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  transListSubs: Subscription;
  userBalSubs: Subscription;
  bussinesstrendsSubs: Subscription;
  leadCountSubs: Subscription;
  categoryWiseTargetSub: Subscription;
  paymentModeTransferSub: Subscription;

  @Output() dashboardCall: EventEmitter<any> = new EventEmitter();

  firmList: BehaviorSubject<any> = new BehaviorSubject('');
  businessCategoryList: BehaviorSubject<any> = new BehaviorSubject('');

  transactionData: BehaviorSubject<any> = new BehaviorSubject('');
  userBalanceData: BehaviorSubject<any> = new BehaviorSubject('');
  bussinessTrendsData: BehaviorSubject<any> = new BehaviorSubject('');
  leadCountData: BehaviorSubject<any> = new BehaviorSubject('');
  categoryWiseTarget: BehaviorSubject<any> = new BehaviorSubject('');
  paymentModeTransfer: BehaviorSubject<any> = new BehaviorSubject('');
  selectedDate: BehaviorSubject<any> = new BehaviorSubject('');
  dashboardModalClose: BehaviorSubject<any> = new BehaviorSubject('');
  from_date: string;
  to_date: string;

  get _transactionData() {
    return this.transactionData.asObservable();
  };
  get _userBalanceData() {
    return this.userBalanceData.asObservable();
  };
  get _bussinessTrendsData() {
    return this.bussinessTrendsData.asObservable();
  };
  get _leadCountData() {
    return this.leadCountData.asObservable();
  };
  get _categoryWiseTarget() {
    return this.categoryWiseTarget.asObservable();
  };
  get _paymentModeTransfer() {
    return this.paymentModeTransfer.asObservable();
  };

  constructor(
    private apiRequestService: ApiRequestService,
    private _NgxToasterService: NgxToasterService,
    private signUpServices: SignUpService,
    private _TicklerService: TicklerService,

  ) {
    DashboardService.instance = this;

    this.dashboardCall.subscribe(() => {
      // this.userBalance();
      console.log("inside instance")
      // this.getTransactionList();
      // this.bussinessTrends();
    })
  };

  loadDashboard() {
    if (!this.transactionData.getValue()) {
      this.getTransactionList();
    }
    if (!this.bussinessTrendsData.getValue()) {
      this.bussinessTrends();
    }
    if (!this.leadCountData.getValue()) {
      this.leadCount();
    }
    if (!this.categoryWiseTarget.getValue()) {
      this.getCategoryWiseTarget();
    }
    if (!this.paymentModeTransfer.getValue()) {
      this.getPaymentModeTransfer();
    }
    if (!this._TicklerService.ticklersData.getValue()) {
      this._TicklerService.getTicklers();
    }
  }

  getCategoryWiseTarget(
    from_date: string = '',
    to_date: string = ''
  ) {
    this.from_date = from_date;
    this.to_date = to_date;
    // console.log(this.from_date);
    if (this.from_date && this.to_date) {
      let formData: FormData = new FormData();
      formData.append('from_date', from_date)
      formData.append('to_date', to_date)
      this.categoryWiseTargetSub = this.apiRequestService.postAdminData(formData, config['dashboard'].categoryWiseTarget.url).subscribe({
        next: (resp: any) => {
          if (resp.statuscode === 200) {
            this.categoryWiseTarget.next(resp)
          } else {
            this.categoryWiseTarget.next([]);
            this._NgxToasterService.showError(resp.message, "Error")
          }


        }, error: (err: any) => {
          this.categoryWiseTarget.next([])
          this._NgxToasterService.showError(err.message, "Error")
        }
      })
    }

  };

  getPaymentModeTransfer(
    from_date: string = '',
    to_date: string = ''
  ) {
    this.from_date = from_date;
    this.to_date = to_date;
    if (this.from_date && this.to_date) {
      let formData: FormData = new FormData();
      formData.append('from_date', from_date)
      formData.append('to_date', to_date)
      this.paymentModeTransferSub = this.apiRequestService.postAdminData(formData, config['dashboard'].paymentModeTransfer.url).subscribe({
        next: (resp: any) => {
          if (resp.statuscode === 200) {
            this.paymentModeTransfer.next(resp)
          } else {
            this.paymentModeTransfer.next([]);
            this._NgxToasterService.showError(resp.message, "Error")
          }
        }, error: (err: any) => {
          this.paymentModeTransfer.next([])
          this._NgxToasterService.showError(err.message, "Error")
        }
      })
    }
  };



  getTransactionList() {
    this.transListSubs = this.apiRequestService.postAdminData(null, config['dashboard'].transactionList.url).subscribe({
      next: (resp: any) => {
        if (resp.statuscode === 200) {
          this.transactionData.next(resp.data)
        } else {
          this.transactionData.next([])
          this._NgxToasterService.showError(resp.message, "Error")
        }


      }, error: (err: any) => {

        this._NgxToasterService.showError(err.message, "Error")
      }
    })
  };


  static _userBalance() {
    this.instance.userBalance();
  }
  static instance: DashboardService;
  userBalance(): Promise<void> {
    return new Promise((res, rej) => {
      this.userBalSubs = this.apiRequestService.postAdminData(null, config['dashboard'].userBalance.url).subscribe((resp: any) => {
        if (resp.statuscode === 200) {
          this.userBalanceData.next(resp.balance)
          res(resp.balance);
        } else {
          this.userBalanceData.next([]);
          this._NgxToasterService.showError(resp.message, "Error");
          rej(false);
        }
      })
    })
    // return this.apiRequestService.postAdminData(null, config['dashboard'].userBalance.url);

  };

  leadCount(
    dateType: string | null = 'Today',
    from_date: string = '',
    to_date: string = ''
  ) {
    this.from_date = from_date;
    this.to_date = to_date;
    let formData: FormData = new FormData();
    formData.append('date', dateType as string)
    formData.append('from_date', from_date)
    formData.append('to_date', to_date)
    this.apiRequestService.postAdminData(null, config['dashboard'].dashboardCount.url).subscribe((resp: any) => {
      // console.log(resp);

      if (resp.statuscode === 200) {
        this.leadCountData.next(resp)
      } else {
        this.leadCountData.next([]);
        this._NgxToasterService.showError(resp.message, "Error")
      }


    })
  };

  bussinessTrends(
    dateType: string | null = 'Today',
    from_date: string = '',
    to_date: string = ''
  ) {
    this.from_date = from_date;
    this.to_date = to_date;
    // this.leadCount();

    let formData: FormData = new FormData();
    formData.append('date', dateType as string)
    formData.append('from_date', from_date)
    formData.append('to_date', to_date)
    this.bussinesstrendsSubs = this.apiRequestService.postAdminData(formData, config['dashboard'].bussinessTrends.url).subscribe({
      next: (resp: any) => {
        // console.log(resp)
        if (resp.statuscode === 200) {
          this.bussinessTrendsData.next(resp)
        } else {
          this.bussinessTrendsData.next([]);
          this._NgxToasterService.showError(resp.message, "Error")
        }


      }, error: (err: any) => {
        this.bussinessTrendsData.next([])
        this._NgxToasterService.showError(err.message, "Error")
      }
    })
  };


  ngOnDestroy(): void {
    if (this.transListSubs) {
      this.transListSubs.unsubscribe();
    }
    if (this.userBalSubs) {
      this.userBalSubs.unsubscribe();
    }
    if (this.bussinesstrendsSubs) {
      this.bussinesstrendsSubs.unsubscribe()
    }
    if (this.leadCountSubs) {
      this.leadCountSubs.unsubscribe()
    }
    if (this.categoryWiseTargetSub) {
      this.categoryWiseTargetSub.unsubscribe()
    }
    if (this.paymentModeTransferSub) {
      this.paymentModeTransferSub.unsubscribe()
    }
  };

  getCompanyList() {
    this.businessCategoryList.subscribe(res => {
      if (!res) {
        this.getFirmList();
        this.getBusinessCategotyList();
      }
    })
  }
  getFirmList() {
    let formData = new FormData();
    formData.append('type', '1');
    // let payLoad = { type: 1 };
    this.signUpServices.getCompanyList(formData).subscribe(res => {
      this.firmList.next(res.company_list);
    })
  }
  getBusinessCategotyList() {
    let formData = new FormData();
    formData.append('type', '2');
    this.signUpServices.getCompanyList(formData).subscribe(res => {
      this.businessCategoryList.next(res.company_list);
    })
  }

  resetDashboarBehaviours() {
    // this.businessCategoryList.next(null);
    this.transactionData.next(null);
    this.userBalanceData.next(null);
    this.bussinessTrendsData.next(null);
    this.leadCountData.next(null);
    this.categoryWiseTarget.next(null);
    this.paymentModeTransfer.next(null);
    this.selectedDate.next(null);
    this.dashboardModalClose.next(null);
    this._TicklerService.ticklersData.next(null);
  }
}
