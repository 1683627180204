import { ExcelToJsonService } from './../../../core/services/excelToJsonToggle/excel-to-json.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import { NgxToasterService } from '@core/services/toasterNgs.service';

type AOA = any[][];
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [ExcelToJsonService]
})
export class FileUploadComponent implements OnInit {
  // data: any;
  showBulkTransferModal:any = ''
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';
  docType = [ 'xls', 'xlsx', 'csv'];


  data: AOA = [[1, 2], [3, 4]];
  constructor(
    public activeModal: NgbActiveModal,
    public _ExcelToJsonService: ExcelToJsonService,
    private _NgxToasterService: NgxToasterService
  ) { }
  ngOnInit(): void {
   console.log(this.showBulkTransferModal);

  }
  closeTab() {
    this.activeModal.close(false)
  }

  onFileChange(ev: any) {
    // this._ExcelToJsonService.getJsonFromExcelFn(ev.target.files[0]).then(
    //   function (result) {

    //     // that.successFn(mainDiv, files.name, result);
    //   },
    //   function (err) {

    //     // that.errorFn('Select valid file');
    //   }
    // );
    //console.log(ev.target.files[0]);

    const target: DataTransfer = <DataTransfer>(ev.target);
    // console.log(this.docType.indexOf((target.files[0].name).substring((target.files[0].name).lastIndexOf('.') + 1)) != -1);

    if (target.files.length !== 1 || this.docType.indexOf((target.files[0].name).substring((target.files[0].name).lastIndexOf('.') + 1)) == -1){
      this._NgxToasterService.showError('Upload Valid file!', 'Error');
       throw new Error('Cannot use multiple files');
      }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {

      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

      let arr: any = []
      this.data.map((res: any, index: number) => {
        if (index !== 0) {
          let dt: any = {};
          for (const key in this.data[0]) {

            const element = (this.data[0][key]).replace(/ /g, "_").replace('-', "_").replace('.', "").toLowerCase();
            dt[element] = res[key] ?? ''
          }
          arr.push(dt);
        }
      })

      if(arr.length > 0 && arr.length < 100){
        for(let element of arr){
          for (let key in element){
            console.log("key ", key)
            console.log("element ", element)
            if(!element[key] && key != 'sr_no' && key != 'beneid'){
              this._NgxToasterService.showError(`Please upload the right data!`, 'Error');
              this.data = [[1, 2], [3, 4]];
              return;
            }
          }
        }
      }else{
        this._NgxToasterService.showError(`${arr.length >=100 ?'Records length could not be greater than 99':'Please upload the right data!'}`, 'Error');
        this.data=[[1, 2], [3, 4]];
        this.closeTab()
        return;
      }

      let fileObj = {
        fileName: ev.target.files[0].name,
        fileSize: (ev.target.files[0].size / 1024).toFixed(2),
        fileData: arr
      }
      this.activeModal.close(fileObj)
      console.log(fileObj)
    };
    reader.readAsBinaryString(target.files[0]);
  }
}
