import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dash-info-box',
  templateUrl: './dash-info-box.component.html',
  styleUrls: ['./dash-info-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashInfoBoxComponent implements OnInit {
  @Input() head: string | HTMLElement | JQuery;
  @Input() title: string | HTMLElement | JQuery;
  @Input() btnName: string | HTMLElement | JQuery = 'Click to Verify';

  @Output()btnClick:EventEmitter<any> = new EventEmitter<any>()
  constructor() { }

  ngOnInit(): void {
  }

}
