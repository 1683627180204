import { Component, OnInit, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxToasterService } from '@core/services/toasterNgs.service';
import { KycServices } from '../kyc-services';
import { BankListService } from '@core/services/bank-list.service';
import { FileuploadService } from '@core/services/file-upload.service';
import { ConfirmedValidator } from '@core/common/common-function';
import { UserDetailsService } from '@core/services/user-details.service';
import { regExpNativePatternValidator } from '@core/utility/validator';
import { ConfirmationModalConfig, ConfirmationModalService } from '@features/customCDK/cdkCustomMdl/services/confirmation-modal.service';
import { ConfirmationComponent } from '@features/customCDK/cdkCustomMdl/components/confirmation/confirmation.component';
import { filter } from 'rxjs';
import { DocModalComponent } from '@shared/components/doc-modal/doc-modal.component';

@Component({
  selector: 'app-bank-detail',
  templateUrl: './bank-detail.component.html',
  styleUrls: ['./bank-detail.component.scss']
})
export class BankDetailComponent implements OnInit {

  @ViewChild('checkFile') selectedfile: ElementRef;
  @Output() saveBankDetail = new EventEmitter<boolean>();
  submitted: boolean = false;
  file_url: string = 'assets/assets/img/upload_file.png';
  bankList: any[] = this.bankListService.bankList.getValue();
  user_id: number;
  form: UntypedFormGroup;
  file_: File;
  kycStep_: any;
  status: any = '';
  constructor(
    private toaster: NgxToasterService,
    private kycServices: KycServices,
    private fb: UntypedFormBuilder,
    private _FileuploadService: FileuploadService,
    private bankListService: BankListService,
    private userDetailsService: UserDetailsService,
    private _ConfirmationModalService: ConfirmationModalService,
  ) {
    this.bankListService.bankList.subscribe(res=>{
      if (!res) {
        this.bankListService.getBankList();
      } else {
        this.bankList=res;
      }
    })
  }

  ngOnInit(): void {
    this.userDetailsService.userLoginDetails.subscribe({
      next: (value: any) => {
        this.kycStep_ = value?.data?.kyc_step;
      }
    });
    this.form = this.fb.group({
      bankId: ['', [Validators.required]],
      accountHolderName: ['', [Validators.required]],
      accountNumber: ['', [Validators.required]],
      c_accountNumber: ['', [Validators.required]],
      ifscCode: ['', [
        Validators.required, regExpNativePatternValidator(
          'ifsc', { termconditionError: () => `Please Enter valid ifsc code.`, }
        )
      ],],
      cancelChequeUrl: ['', Validators.required],
    }, {
      validator: ConfirmedValidator('accountNumber', 'c_accountNumber')  // adding custom validation to compare account and confirm account number
    });
    setTimeout(() => {
      this.bindBankDetail();
    }, 500);
    this.form.get('bankId')?.valueChanges.subscribe((val: any) => {
      this.form.get('ifscCode')?.setValue(val.ifsc)
    })

  }

  bindBankDetail() {
    const kyc_data = this.kycServices.kyc_detail_.getValue();
    const kycDetail = kyc_data.kyc_list;
    this.status = kycDetail.bankdetail.status;
    if (kycDetail.bankdetail.status == 1 || kycDetail.bankdetail.status == 2) {
      this.status = '';
      this.file_url = kycDetail.bankdetail.cheque_path;
      this.status = kycDetail.bankdetail.status;
      this.form.patchValue({
        bankId: this.bankList.filter(re => re.id == kycDetail.bankdetail.bank_id)[0],
        accountHolderName: kycDetail.bankdetail.holder_name,
        accountNumber: kycDetail.bankdetail.account_number,
        c_accountNumber: kycDetail.bankdetail.account_number,
        ifscCode: kycDetail.bankdetail.ifsc,
        cancelChequeUrl: kycDetail.bankdetail.cheque_path
      }, { emitEvent: false })
      if (kycDetail.bankdetail.status || !kycDetail.bankdetail.update_counter) {
        this.form.disable();
      }
    }
  }

  get bankDetailControle() {
    return this.form.controls;
  }
  onFileChange(event, unLinkUrl) {
    const unlinkrl = (unLinkUrl == 'assets/assets/img/upload_file.png') ? '' : unLinkUrl;
    this.getDocumentUrl(event, unlinkrl);
  }

  async getDocumentUrl(file, unLink_Url) {
    this.file_ = <File>file.target.files[0];
    // const fileExt=this.file_.name.split('.')[1];
    const fileExt = this.file_.name.split('.')[(this.file_.name.split('.').length) - 1].toLowerCase();
    if (fileExt != 'jpeg' && fileExt != 'jpg' && fileExt != 'png') {
      this.selectedfile.nativeElement.value = "";
      this.toaster.showError('You can only upload JPG, PNG and JPEG file', 'Error');
      return;
    }
    let uploadedFile = await this._FileuploadService.uploadFileFn(this.file_, unLink_Url);
    if (uploadedFile.response == 1) {
      this.file_url = uploadedFile.pan_proof;
      this.form.patchValue({ cancelChequeUrl: uploadedFile.pan_proof });
      // this.verifyCheque();
    } else {
      this.toaster.showError(uploadedFile.message, 'Error');
    }
  }

  onSaveBankDetail() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let customConfig: ConfirmationModalConfig = {
      data: {
        icon: 'info',
        title: 'Final Confirmation',
        title2: '',
        msg: "Are you sure to proceed with these documents, NOTE: Details once submitted will not be changed?",
        btnConfirm: 'Confirm',
        btnClose: 'Cancel'
      },
    };

    let confirmationRef =
      this._ConfirmationModalService.open<ConfirmationComponent>(
        ConfirmationComponent,
        customConfig
      );
    confirmationRef.events.pipe(
      filter((event) => {
        //console.log(event);
        return !!event
      }),
    ).subscribe(async (events) => {
      switch (events.type) {
        case 'confirm':
          confirmationRef.close(customConfig);
          const formControls = (Object.keys(this.form.value).length != 0) ? this.form.value : this.form.getRawValue();
          const payload = new FormData();
          payload.append('file', this.file_);
          payload.append('type', 'CHEQUE');
          payload.append('fileurl', this.file_url);
          payload.append('bank_id', formControls.bankId.id)
          payload.append('holder_name', formControls.accountHolderName)
          payload.append('account_number', formControls.accountNumber)
          payload.append('ifsc', formControls.ifscCode)
          this.kycServices.chequeOcr(payload).subscribe({
            next:(res:any) => {
            if (res.statuscode == 200) {
              if(res.reupload){
                this.toaster.showError(res.message,"Error");
              }else if(!res.reupload && !res.call_api_verify){
                this.callFinalKyc();
                this.toaster.showSuccess(res.message,"Success");
              }else{

                // if (res.data.account_number != formControls.accountNumber) {
                //   this.toaster.showInfo('Account Number Does not match', "Info")
                // }

                let formData: FormData = new FormData();
                formData.append('bank_id', formControls.bankId.id)
                formData.append('holder_name', formControls.accountHolderName)
                formData.append('account_number', formControls.accountNumber)
                formData.append('ifsc', formControls.ifscCode)
                this.kycServices.addBankDetails(formData).subscribe(resu => {
                  if (resu.statuscode == 200) {
                    if(resu.reupload){
                      this.toaster.showError(resu.message,"Error");
                    }else{
                      this.toaster.showSuccess(resu.message,"Success");
                      this.callFinalKyc();
                    }
                  } else {
                    this.toaster.showError(res.message, "Error");
                  }
                }
                )
              }

            }
          } , error: (err: any) =>{
            this.toaster.showError(err.message, "Error");
          }})

          break;
        case 'close':
          confirmationRef.close(customConfig);
          break;
      }
    });

  }

  callFinalKyc(){
    let payload_: FormData = new FormData();
    this.kycServices.finalKycUpdate(payload_).subscribe(resp => {
      if (resp) {
        this.userDetailsService.updateLocalStorage(
          { ['data.kyc_step']: resp.kyc_step }
        )
        this.saveBankDetail.emit(true);
        // this.toaster.showSuccess(resp.message, "Success");
      }
    })
  }

  viewDoc(value:any){
    let customConfigData: ConfirmationModalConfig = { data:{img:value}};
    let confirmationRef =this._ConfirmationModalService.open<DocModalComponent>(DocModalComponent,customConfigData);
  }
}
