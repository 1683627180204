
export const environment = {
  production: false,
  baseUrl: 'https://kyc.cipherpay.in:8000/api/',
  // corporateBaseUrl: 'https://kyc.cipherpay.in/api/corporate/',
  // adminBaseUrl: 'https://kyc.cipherpay.in/api/console/',
  corporateBaseUrl:'https://3.111.65.113/api/corporate/',
  adminBaseUrl: 'https://3.111.65.113/api/console/',
  imgBaseUrl: 'https://docs.paysprint.in/upload/uploadimage',
  imgBaseToken: '27faf26c87d99ae520ade3ce7cd88684',
  defEncKey: 'eagkauhf0009',
};
