import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';
import { ComponentRef, Injectable, InjectionToken, Injector } from '@angular/core';

import { ConfirmationModalOverlayRef } from '../classes/confirmation-modal.ref';
import { ConfirmationModalData, ConfirmationSelectModalData } from '../interfaces/confirmation-modal-data';
import { CONFIRMATION_MODAL_DATA } from '../tokens/confirmation-data.token';

import { ConfirmationComponent } from '../components/confirmation/confirmation.component'
import { customOverlayPosition } from '../interfaces/customPosition-modal-data';
import { CustomOverlayAnimationConfig } from '../interfaces/animation-modal-data';
import { CommonFunctionsService } from './common-functions.service';
import * as _ from 'lodash';
export const FILE_PREVIEW_DIALOG_DATA = new InjectionToken<ConfirmationModalConfig>('FILE_PREVIEW_DIALOG_DATA');

export interface ConfirmationModalConfig {
  hasBackdropClick?: boolean;
  hasEscapeKeyClose?: boolean;
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  data?: ConfirmationModalData | ConfirmationSelectModalData | any;

  isCentered?: boolean;

  customOverlayPosition?: customOverlayPosition | null;

  isAnimation?: CustomOverlayAnimationConfig | null;
}

const DEFAULT_CONFIG: ConfirmationModalConfig = {
  hasBackdropClick: false,
  hasEscapeKeyClose: false,
  hasBackdrop: true,
  backdropClass: 'my-overlay-modal-backdrop',
  panelClass: 'my-confirmation-panel',
  data: {
    icon: 'warning',
    title: 'Link your ICICI Bank Current Account',
    title2: 'Approve Integration Connection Request',
    msg: 'No user found for this number, please sign up',
    btnClose: 'No',
    btnConfirm: 'Yes'
  },
};


@Injectable()
export class ConfirmationModalService {
  public dialogRef!: ConfirmationModalOverlayRef;
  private localDEFAULT_CONFIG ;

  constructor(
    private overlay: Overlay,
    private _CommonFunctionsService: CommonFunctionsService
  ) { }

  open<T>(component: ComponentType<T>, config: ConfirmationModalConfig = {}) {
    // Returns an OverlayRef (which is a PortalHost)

    // this.localDEFAULT_CONFIG.data = this._CommonFunctionsService.replaceAllObjKeys(this.localDEFAULT_CONFIG,config);

    this.localDEFAULT_CONFIG = Object.assign({}, DEFAULT_CONFIG);
    this.localDEFAULT_CONFIG = _.merge(this.localDEFAULT_CONFIG, config);
    const modalConfig = this.localDEFAULT_CONFIG;
    console.log(this.localDEFAULT_CONFIG);

    // const modalConfig = { ...this.localDEFAULT_CONFIG, ...config };


    const overlayRef = this.createOverlay(modalConfig);
    const dialogRef = new ConfirmationModalOverlayRef(overlayRef);

    const overlayComponent = this.attachModalContainer(overlayRef, modalConfig, dialogRef, component);
    overlayRef.backdropClick().subscribe((_) => {
      if (this.localDEFAULT_CONFIG.hasBackdropClick) {
        dialogRef.close(config);
      }
    });
    overlayRef.keydownEvents().subscribe((_: KeyboardEvent) => {
      if (_.key == 'Escape') {
        if (this.localDEFAULT_CONFIG.hasEscapeKeyClose) {
          dialogRef.close(config);
        }
      }
    });
    this.dialogRef = dialogRef;
    return dialogRef;
  }

  private attachModalContainer<T>(overlayRef: OverlayRef, config: ConfirmationModalConfig,
    dialogRef: ConfirmationModalOverlayRef, component: ComponentType<T>) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(component, null, injector);
    const containerRef: ComponentRef<T> = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  // private createInjector(config: ConfirmationModalConfig, dialogRef: ConfirmationModalOverlayRef): PortalInjector {
  //   const injectionTokens = new WeakMap();

  //   injectionTokens.set(ConfirmationModalOverlayRef, dialogRef);
  //   injectionTokens.set(CONFIRMATION_MODAL_DATA, config.data);
  //   return new PortalInjector(this.injector, injectionTokens);
  // }
  private createInjector(config: ConfirmationModalConfig, dialogRef: ConfirmationModalOverlayRef): Injector {
    return Injector.create({
      providers: [{
        provide: ConfirmationModalOverlayRef, useValue: dialogRef
      },
      {
        provide: CONFIRMATION_MODAL_DATA, useValue: config.data
      }]
    });
  }
  // private createInjector(dialogConfig: FilePreviewDialogConfig, dialogRef: FilepreviewoverlayRef): Injector {
  //   return Injector.create({
  //     providers: [{
  //       provide: FilepreviewoverlayRef, useValue: dialogRef
  //     },
  //     {
  //       provide: FILE_PREVIEW_DIALOG_DATA, useValue: dialogConfig.data
  //     }]
  //   });
  // }
  private getOverlayConfig(config: ConfirmationModalConfig): OverlayConfig {
    let positionStrategy = this.overlay.position()
      .global();

    positionStrategy = this._CommonFunctionsService.setPositionStrategy(config, positionStrategy);
    let panelCls: any = this._CommonFunctionsService.setMdlAnimation(config, config.panelClass);

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: panelCls,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return overlayConfig;
  }

  private createOverlay(config: ConfirmationModalConfig) {
    // Returns an OverlayConfig
    const overlayConfig = this.getOverlayConfig(config);

    // Returns an OverlayRef
    return this.overlay.create(overlayConfig);
  }
}
