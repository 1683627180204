import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, AfterViewInit, } from '@angular/core';
import { CommonService } from '@core/services/common.service';
import { FileuploadService } from '@core/services/file-upload.service';
import { SessionStorageService } from '@core/services/session-storage.service';
import { NgxToasterService } from '@core/services/toasterNgs.service';
import { UserDetailsService } from '@core/services/user-details.service';
import { TemplateModalOverlayRef } from '@features/customCDK/cdkCustomMdl/classes/template-modal.ref';
import { CustomOverlayConfig, TemplateModalService, } from '@features/customCDK/cdkCustomMdl/services/template-modal.service';
import { ConfigDynamicField, PartnersDocumentDetail } from '../kyc-model';
import { KycServices } from '../kyc-services';
import { config } from '@core/services/request-url.service';
import { ConfirmationModalConfig, ConfirmationModalService } from '@features/customCDK/cdkCustomMdl/services/confirmation-modal.service';
import { ConfirmationComponent } from '@features/customCDK/cdkCustomMdl/components/confirmation/confirmation.component';
import { filter } from 'rxjs';
import { DocModalComponent } from '@shared/components/doc-modal/doc-modal.component';

@Component({
  selector: 'app-partner-documents',
  templateUrl: './partner-documents.component.html',
  styleUrls: ['./partner-documents.component.scss'],
})
export class PartnerDocumentsComponent implements OnInit, AfterViewInit {
  @ViewChild('modalTemplateOpt') modalTemplate!: TemplatePortal<any>;
  configDynamicField: ConfigDynamicField = new ConfigDynamicField();
  @Output() isKycStep = new EventEmitter();
  partnersDocumentDetail: PartnersDocumentDetail = new PartnersDocumentDetail();
  fetchUserPanName: string = '';
  isResendOtp: boolean = false;
  isHiddenPan: boolean = false;
  isDirectorUpdate: boolean = false;
  isOtpVerify: boolean = false;
  aadhar_no: string = '';
  full_name: string = '';
  document_id: any = '';
  // pdfPath: string = '';
  userDetail: any;
  file_: File;
  allDirectorList: any[] = [];
  kycPartnerList: any[] = [];
  authorisedPersonObj: any[] = [];
  isShow: boolean = false;
  showAddPartner: boolean = false;
  enableText: boolean = false;
  isShowSaveNextButton: boolean = false;
  isPartnerRejected: boolean = false;
  childKycStep: number | string = 0;
  kyc_steps: number | string;
  aadgarRefid: any;
  kyc_data: any;
  isPartOrDir: string = '';
  aadharPPFrontData: any = {};
  aadharPPBackData: any = {};
  aaFilef: any = '';
  aaFileb: any = '';
  panReupload:boolean=false;
  pan_verify:boolean=false;
  disableNextButton:boolean=false;
  showHideDirectorAddressProof:boolean=false;
  @ViewChild('fileDropRef10') selectedPan: ElementRef;
  @ViewChild('fileDropRef11') frontSide: ElementRef;
  auth_pers_name: any = {};
  @ViewChild('fileDropRef12') backSide: ElementRef;
  @ViewChild('mobileno') mobil_ :any;
  @ViewChild('emailid') emai_ :any;
  addressTypeList: any[] = [
    { id: 1, name: 'Aadhar Card', value: 'AADHAR' },
    { id: 2, name: 'Driving License', value: 'LICENCE' },
    { id: 3, name: 'Passport', value: 'PASSPORT' },
    { id: 4, name: 'Voter Id', value: 'VOTERID' },
  ];
  private templateRef!: TemplateModalOverlayRef;
  customConfig!: CustomOverlayConfig;
  constructor(
    private toaster: NgxToasterService,
    private kycServices: KycServices,
    private _FileuploadService: FileuploadService,
    private _TemplateModalService: TemplateModalService,
    private userDetailsService: UserDetailsService,
    private _ConfirmationModalService: ConfirmationModalService,
  ) { }

  ngOnInit(): void {
    this.isShow = true;
    this.setPartnerDefaultImage();
    this.userDetailsService.userLoginDetails.subscribe({
      next: (value: any) => {
        this.kyc_steps = value?.data?.kyc_step;
        this.userDetail=value?.data;
      },
    });

    this.showAddPartner = true;
    this.isShowSaveNextButton = false;
    this.setDynamicFildName(1);
    this.getKycViewModeDetail();

    this.kycServices.selectedChildKycStep.subscribe((res) => {
      if (res) {
        this.childKycStep = res;
        if (this.childKycStep < this.kyc_steps && this.childKycStep != 0) {
          this.isShowSaveNextButton = true;
        } else {
          this.isShowSaveNextButton = false;
        }
      }
    });
  }
  ngAfterViewInit(): void { }
  getDocumentUrl(event, fileno, unlinkUrl) {
    if(fileno == 6 && (this.partnersDocumentDetail.address_proof == 'AADHAR' || this.partnersDocumentDetail.address_proof == 'PASSPORT')){
      if(this.partnersDocumentDetail.partner_file_url1 == 'assets/assets/img/upload_file.png' || this.partnersDocumentDetail.partner_file_url1 == ''){
        this.toaster.showError(`Please upload ${this.partnersDocumentDetail.address_proof} front first`,'Error');
        this.backSide.nativeElement.value = '';
        return;
      }
    }
    this.auth_pers_name = {};
    this.file_ = <File>event.target.files[0];
    var maxfilesize = 2 * (1024 * 1024)  // 2 Mb
    var filesize = this.file_.size
    if (maxfilesize >= filesize) {
      // const fileExt=this.file_.name.split('.')[1];
      const fileExt = this.file_.name.split('.')[(this.file_.name.split('.').length) - 1].toLowerCase();
      if (fileExt != 'jpeg' && fileExt != 'jpg' && fileExt != 'png') {
        if (fileno == 4) {
          this.selectedPan.nativeElement.value = '';
        } else if (fileno == 5) {
          this.frontSide.nativeElement.value = '';
        } else {
          this.backSide.nativeElement.value = '';
        }
        this.toaster.showError('You can only upload JPG, PNG and JPEG file', 'Error');
        return;
      }

      // let customConfig: ConfirmationModalConfig = {
      //   data: {
      //     icon: 'error',
      //     title: 'Sorry!',
      //     title2: '',
      //     msg: "Please Complete your KYC first...!",
      //     btnConfirm: '',
      //     btnClose: 'Ok'
      //   },
      // };
      let customConfig: ConfirmationModalConfig = {
        data: {
          icon: 'info',
          title: 'Final Confirmation?',
          title2: '',
          msg: "Are you sure to proceed with these document details, NOTE: Details once submitted will not be changed?",
          btnConfirm: 'Confirm',
          btnClose: 'Cancel'
        },
      };

      let confirmationRef =
        this._ConfirmationModalService.open<ConfirmationComponent>(
          ConfirmationComponent,
          customConfig
        );
      confirmationRef.events.pipe(
        filter((event) => {
          // console.log(event);
          return !!event
        }),
      ).subscribe(async (events) => {
        switch (events.type) {
          case 'confirm':
            confirmationRef.close(customConfig);

            // console.log('confirmed action, proceed');
            const unlinkUrl_ = (unlinkUrl == 'assets/assets/img/upload_file.png') ? '' : unlinkUrl;
            let uploadedFile = await this._FileuploadService.uploadFileFn(this.file_, unlinkUrl_);
            if (uploadedFile.response == 1) {
              this.bindValue(uploadedFile.pan_proof, fileno, this.file_.name);
            } else {
              this.toaster.showError(uploadedFile.message, 'Error');
            }
            break;
          case 'close':
            if (fileno == 4) {
              this.selectedPan.nativeElement.value = '';
            } else if (fileno == 5) {
              this.frontSide.nativeElement.value = '';
            } else {
              this.backSide.nativeElement.value = '';
            }
            confirmationRef.close(customConfig);
            break;
        }
      });
    }
    else {
      this.toaster.showInfo('File size should not be greater than 2MB', "Info")
    }


  }
  onAddressChange() {
    this.partnersDocumentDetail.partner_file_url1 = 'assets/assets/img/upload_file.png';
    this.partnersDocumentDetail.partner_file_url2 = 'assets/assets/img/upload_file.png';
    // console.log(this.partnersDocumentDetail.address_proof);
  }
  getKycViewModeDetail() {
    this.document_id = '';

    //this.kyc_data = this.kycServices.kyc_detail_.getValue();
    this.kycServices.kyc_detail_.subscribe((res) => {
      if (res) {
        this.kyc_data = res;
      }
    });
    this.allDirectorList = [];
    this.kycPartnerList = [];
    const kycDetail = this.kyc_data.kyc_list;
    if (kycDetail.kycpartnership && kycDetail.kycpartnership.length > 0) {
      this.allDirectorList = kycDetail.kycpartnership;
      kycDetail.kycpartnership.forEach((element, index) => {
        if (index == 0) {
          //console.log('element',element);
          this.isShow = element.status == 1?true : (element.status == 2 && (element.pstatus == 2 || element.pstatus == 1) &&  (element.ostatus == 2 || element.ostatus == 1 )) || !element.status ? true : false;
          if(!element.ostatus) this.isShow = false;
          if(!(element.status && element.ostatus)) {
            this.isPartnerRejected = true;
          }
          // this.enableText = element.pan_path ? false : true;
          this.enableText =element.status == 1?false : (element.status == 0 && element.pstatus == 2)||(element.status == 2 && element.pstatus == 4)? true :(element.status == 2 && (element.pstatus == 2 || element.pstatus == 1) &&  (element.ostatus == 2 || element.ostatus == 1 )) || !element.status ? false : true;
          this.disableNextButton=element.status == 0 ? true :false;
          this.partnersDocumentDetail.name = '';
          this.partnersDocumentDetail.name = element.full_name;
          this.partnersDocumentDetail.mobile = element.mobile;
          this.partnersDocumentDetail.email = element.email;
          this.partnersDocumentDetail.document_id = element.id;
          this.document_id = element.id;
          this.pan_verify= (element.pstatus == 4 || element.pstatus == 0)? false:true;
          this.partnersDocumentDetail.address_proof = element.status == 0 || (element.status == 4 && (element.ostatus == 4 || element.ostatus == 0))?'': element.type == 'LICENSE' ? 'LICENCE' : element.type;
          this.partnersDocumentDetail.partner_file_url =element.status == 1?element.pan_path : ( element.pstatus == 4 || element.pstatus == 0)||(element.status == 4 && element.pstatus == 4)||(element.status == 2 && element.pstatus == 4)?'assets/assets/img/upload_file.png' : element.pan_path ? element.pan_path : 'assets/assets/img/upload_file.png';
          this.partnersDocumentDetail.partner_file_url1 =element.status == 1?element.av_path : (  element.ostatus == 4|| element.ostatus == 0)?'assets/assets/img/upload_file.png' : element.av_path ? element.av_path : 'assets/assets/img/upload_file.png';
          this.partnersDocumentDetail.partner_file_url2 =element.status == 1?element.av_bpath : (  element.ostatus == 4 || element.ostatus == 0)?'assets/assets/img/upload_file.png' : element.av_bpath ? element.av_bpath : 'assets/assets/img/upload_file.png';
          this.partnersDocumentDetail.status = element.status;
          this.partnersDocumentDetail.remarks=element.remarks;
        }
        else {
          this.kycPartnerList.push({
            pan_file: element.pan_path,
            aadhar_file: element.av_path,
            aadhar_back_file: element.av_bpath,
            name: element.full_name,
            mobile:element.mobile,
            email:element.email,
            type: element.type == 'LICENSE' ? 'LICENCE' : element.type,
            document_id: element.id,
            status: element.status,
            remarks: element.remarks,
            ostatus:element.ostatus,
            pstatus:element.pstatus
          });
        }
      });
    }
  }

  onClickAddMore() {
    this.showHideDirectorAddressProof=true;
    this.setDynamicFildName(0);
    this.showAddPartner = false;
    this.authorisedPersonObj = [];
    this.authorisedPersonObj.push({
      name: this.partnersDocumentDetail.name,
      mobile: this.partnersDocumentDetail.mobile,
      email: this.partnersDocumentDetail.email,
      address_proof: this.partnersDocumentDetail.address_proof,
      partner_file_url: this.partnersDocumentDetail.partner_file_url,
      partner_file_url1: this.partnersDocumentDetail.partner_file_url1,
      partner_file_url2: this.partnersDocumentDetail.partner_file_url2,
      id: this.partnersDocumentDetail.document_id,
      status: this.partnersDocumentDetail.status,
    });

    this.setPartnerDefaultImage();
    const pendingDirec = this.kycPartnerList.filter(d => !d.aadhar_file || !d.pan_file || d.ostatus == 4 || d.pstatus == 4);
    // this.kycPartnerList=this.kycPartnerList.filter(d=> d.aadhar_file );
    //  console.log(pendingDirec);
    if (pendingDirec.length > 0) { this.editDocument(pendingDirec[0]) }
  }

  onPartnerFileChange(event, fileNo, req_type, unlinkUrl) {
    this.isPartOrDir = req_type;
    if (this.userDetail.is_kyc == 1 && !this.isDirectorUpdate) {
      return;
    }
    if ((this.isPartOrDir == 'd') && (Number(this.kyc_data.is_partner) > 1 && Number(this.kyc_data.is_partner) - 1 == this.kycPartnerList.length && !this.isDirectorUpdate)) {
      this.toaster.showInfo(`You Can not add more than ${Number(this.kyc_data.is_partner) - 1} Directors`, "Info");
      return;
    }

    let pName = this.partnersDocumentDetail?.name.trim();
    if (fileNo == 4 && !pName) {
      this.toaster.showWarning(`${this.configDynamicField.nameLabel} is required`,'Warning');
      this.selectedPan.nativeElement.value = '';
      return;
    }
    let pPhone = this.partnersDocumentDetail?.mobile.trim();
    if (fileNo == 4 && (!pPhone || !this.mobil_.valid )) {
      if(!pPhone)
      this.toaster.showWarning(`${this.configDynamicField.mobileNumber} is required`,'Warning');
      this.selectedPan.nativeElement.value = '';
      return;
    }
    let pEmail = this.partnersDocumentDetail?.email.trim();
    if (fileNo == 4 && (!pEmail || !this.emai_.valid)) {
      if(!pEmail)
      this.toaster.showWarning(`${this.configDynamicField.emailId} is required`,'Warning');
      this.selectedPan.nativeElement.value = '';
      return;
    }
    let aProof = this.partnersDocumentDetail?.address_proof;
    if (fileNo == 5) {
      if (this.partnersDocumentDetail.partner_file_url == 'assets/assets/img/upload_file.png') {
        this.toaster.showWarning(`Please first upload pan card`, 'Warning');
        this.selectedPan.nativeElement.value = '';
        return;
      }
      if (!aProof) {
        this.toaster.showWarning(
          `Please Select Authorized Signatory's* (Address Proof)`,
          'Warning'
        );
        this.frontSide.nativeElement.value = '';
        this.backSide.nativeElement.value = '';
        return;
      }
    }
    this.getDocumentUrl(event, fileNo, unlinkUrl);
  }


  onClickBack() {
    this.showHideDirectorAddressProof=false;
    this.showAddPartner = true;
    this.setDynamicFildName(1);
    this.setPartnerDefaultImage();
    this.partnersDocumentDetail = new PartnersDocumentDetail();
    this.document_id = this.authorisedPersonObj[0].id;
    this.partnersDocumentDetail.document_id = this.authorisedPersonObj[0].id;
    this.partnersDocumentDetail.name = this.authorisedPersonObj[0].name;
    this.partnersDocumentDetail.mobile = this.authorisedPersonObj[0].mobile;
    this.partnersDocumentDetail.email = this.authorisedPersonObj[0].email;
    this.partnersDocumentDetail.address_proof = this.authorisedPersonObj[0].address_proof;
    this.partnersDocumentDetail.partner_file_url = this.authorisedPersonObj[0].partner_file_url;
    this.partnersDocumentDetail.partner_file_url1 = this.authorisedPersonObj[0].partner_file_url1;
    this.partnersDocumentDetail.status = this.authorisedPersonObj[0].status;
    this.partnersDocumentDetail.partner_file_url2 = this.authorisedPersonObj[0].partner_file_url2;
  }
  setDynamicFildName(reqType) {
    this.configDynamicField.heading =
      reqType == 1
        ? 'KYC of Authorised Signatory'
        : 'KYC of Directors';
    this.configDynamicField.panCardHeading =
      reqType == 1 ? 'Upload Pan' : 'Upload Pan';
    this.configDynamicField.nameLabel =
      reqType == 1 ? 'Person Name' : 'Director Name';
    this.configDynamicField.addressHeading =
      reqType == 1
        ? 'Address Proof of Authorised person'
        : 'Address Proof of Director';
    this.configDynamicField.addressNameLabel = 'Address Proof';
    this.configDynamicField.mobileNumber = 'Mobile Number';
    this.configDynamicField.emailId = 'Email id';

  }
  setPartnerDefaultImage() {
    this.partnersDocumentDetail.name = '';
    this.partnersDocumentDetail.mobile = '';
    this.partnersDocumentDetail.email = '';
    this.partnersDocumentDetail.address_proof = '';
    this.isHiddenPan = false;
    this.isDirectorUpdate = false;
    this.aadharPPFrontData = {};
    this.aadharPPBackData = {};
    this.partnersDocumentDetail.partner_file_url = 'assets/assets/img/upload_file.png';
    this.partnersDocumentDetail.partner_file_url1 = 'assets/assets/img/upload_file.png';
    this.partnersDocumentDetail.partner_file_url2 = 'assets/assets/img/upload_file.png';
  }

  getExtension(data): string {
    let value: string = '';
    let splitData = data.split('.');
    value = splitData[splitData.length - 1];
    return value;
  }
  bindValue(imgUrl, file_no, fileName) {
    let payLoad = new FormData();
    switch (file_no) {
      case 4:
        this.selectedPan.nativeElement.value = '';
        this.partnersDocumentDetail.partner_file_url = imgUrl;
        payLoad.append('type', 'PAN');
        payLoad.append('email', this.partnersDocumentDetail.email);
        payLoad.append('mobile', this.partnersDocumentDetail.mobile);
        if(!this.panReupload){
          this.document_id = this.isDirectorUpdate ? this.partnersDocumentDetail.document_id : (this.isPartOrDir == 'd' ? '' : (this.partnersDocumentDetail.document_id ? this.partnersDocumentDetail.document_id : ''));
        }
        break;
      case 5:
        this.frontSide.nativeElement.value = '';
        payLoad.append('type', this.partnersDocumentDetail.address_proof);
        if (this.partnersDocumentDetail.address_proof == 'AADHAR' || this.partnersDocumentDetail.address_proof == 'PASSPORT')
          payLoad.append('subtype', 'F');
        this.partnersDocumentDetail.partner_file_url1 = imgUrl;
        this.aaFilef = this.file_;
        break;
      case 6:
        this.backSide.nativeElement.value = '';
        payLoad.append('type', this.partnersDocumentDetail.address_proof);
        payLoad.append('subtype', 'B');
        this.partnersDocumentDetail.partner_file_url2 = imgUrl;
        this.aaFileb = this.file_;
        break;
    }
    if (this.document_id) {
      payLoad.append('id', this.document_id);
    }
    payLoad.append('file', this.file_);
    payLoad.append('fileurl', imgUrl);
    payLoad.append('full_name', this.partnersDocumentDetail.name);
    this.auth_pers_name.a_name = this.partnersDocumentDetail.name;
    if (this.partnersDocumentDetail.address_proof == 'AADHAR' ) {
      const storeObj = { id: this.document_id, file: this.aaFilef, fileb: this.aaFileb, fileurlf: this.partnersDocumentDetail.partner_file_url1, fileurlb: this.partnersDocumentDetail.partner_file_url2 };
      if (file_no == 6) {
        this.kycAadharStore(storeObj);
      }
    } else {
      this.commonApiRequest(payLoad, file_no);
    }

  }
  commonApiRequest(pay_load, doc_type) {

    this.kycServices.createPanOcr(pay_load).subscribe(
      {
        next: (res: any) => {
          if (res.statuscode == 200) {
            if (res.reupload) {
              if( doc_type == 4){
                this.panReupload=true;
                this.document_id = res.id;
                this.partnersDocumentDetail.partner_file_url = 'assets/assets/img/upload_file.png';
              }else if(doc_type == 5){
                this.partnersDocumentDetail.partner_file_url1 = 'assets/assets/img/upload_file.png';
              }else{
                this.partnersDocumentDetail.partner_file_url1 = 'assets/assets/img/upload_file.png';
                this.partnersDocumentDetail.partner_file_url2 = 'assets/assets/img/upload_file.png'
              }
              this.toaster.showError(res.message, 'Error');
            }
            else if (!res.reupload && res.call_api_verify) {this.panReupload=false;
              this.document_id = res.id;
              if (doc_type == 4) {
                const ocrPayLoad = new FormData();
                ocrPayLoad.append('id', this.document_id);
                ocrPayLoad.append('pan_number', res.data.pan_number);
                ocrPayLoad.append('fullname', res.data.full_name);
                this.auth_pers_name.p_name = res.data.full_name;
                this.kycServices.verifyPanOcr(ocrPayLoad).subscribe((respo) => {
                  if (res.statuscode == 200) {
                    if (res.reupload) {
                      this.panReupload=true;
                      this.toaster.showError(respo.message, 'Error');
                      this.partnersDocumentDetail.partner_file_url = 'assets/assets/img/upload_file.png';
                    } else {
                      this.showHideDirectorAddressProof=false;
                      this.pan_verify=true;
                      this.panReupload=false;
                      this.toaster.showSuccess(respo.message, 'Success');
                      this.isHiddenPan = true;
                      this.isShow = false;
                      this.enableText = true;
                    }
                  } else {
                    this.toaster.showError(respo.message, 'Error');
                  }
                });
              } else {
                this.auth_pers_name.av_name = res.data.full_name;
                if (this.partnersDocumentDetail.address_proof == 'VOTERID' || this.partnersDocumentDetail.address_proof == 'LICENCE') {
                  let commonUrl = config.UserKyc.uploadDocument;
                  let requesrUrl = "";
                  const ocrPayLoad = new FormData();
                  ocrPayLoad.append('id', this.document_id);
                  ocrPayLoad.append('fullname', res.data.full_name);
                  ocrPayLoad.append('type', this.partnersDocumentDetail.address_proof);
                  switch (this.partnersDocumentDetail.address_proof) {
                    case 'VOTERID':
                      ocrPayLoad.append('voterid', res.data.epic_number ? res.data.epic_number : 'voter1234');
                      requesrUrl = commonUrl.verifyVoterId
                      break;
                    case 'LICENCE':
                      ocrPayLoad.append('dlnumber', res.data.dlnumber);
                      ocrPayLoad.append('dob', res.data.dob);
                      requesrUrl = commonUrl.dlverify
                      break;
                  }
                  this.kycServices.verifyVoterIdOrDL(ocrPayLoad, requesrUrl).subscribe((result) => {
                    if (result.statuscode == 200) {
                      if (result.reupload) {
                        this.disableNextButton=true;
                        this.toaster.showError(result.message, 'Error');
                        this.partnersDocumentDetail.partner_file_url1 = 'assets/assets/img/upload_file.png'
                      } else {
                        this.disableNextButton=false;
                        this.updateKycDetail(result);
                        this.compareNames();
                        if (this.isPartOrDir == 'd') {
                          this.pushDirectorData(result);
                        } else { this.isShowSaveNextButton = this.kycServices.finalKycStep.getValue() == 10 ? true : false; }
                        this.toaster.showSuccess(result.message, 'Success');
                      }

                    } else {
                      this.toaster.showError(result.message, 'Error');
                    }
                  }), (err: any) =>{
                    this.disableNextButton=true;
                    this.toaster.showError(err.message, 'Error');
                  };;
                } else {
                  if (doc_type == 5) {
                    this.aadharPPFrontData = res.data
                  }
                  if (doc_type == 6) {
                    this.aadharPPBackData = res.data;
                      const ocrPayLoad = new FormData();
                      ocrPayLoad.append('id', this.document_id);
                      ocrPayLoad.append('fullname', this.aadharPPFrontData.full_name);
                      ocrPayLoad.append('type', this.partnersDocumentDetail.address_proof);
                      ocrPayLoad.append('fileurl', this.partnersDocumentDetail.partner_file_url1);
                      ocrPayLoad.append('fileburl', this.partnersDocumentDetail.partner_file_url2);
                      ocrPayLoad.append('file_number', this.aadharPPBackData.file_num);
                      ocrPayLoad.append('dob', this.aadharPPFrontData.dob);
                      ocrPayLoad.append('passportno', this.aadharPPFrontData.passport_num);

                      this.kycServices.verifyPP(ocrPayLoad).subscribe(
                        (_resp) => {
                        if (_resp.statuscode == 200) {
                          if (_resp.reupload) {
                            this.disableNextButton=true;
                            this.toaster.showError(_resp.message, 'Error');
                            this.partnersDocumentDetail.partner_file_url1 = 'assets/assets/img/upload_file.png';
                            this.partnersDocumentDetail.partner_file_url2 = 'assets/assets/img/upload_file.png'
                          }
                          else {
                            this.disableNextButton=false;
                            this.toaster.showSuccess(_resp.message, 'Success');
                            this.updateKycDetail(_resp);
                            this.compareNames();
                            if (this.isPartOrDir == 'd') {
                              this.pushDirectorData(_resp);
                            } else { this.isShowSaveNextButton = this.kycServices.finalKycStep.getValue() == 10 ? true : false; }
                          }

                        } else {
                          this.toaster.showError(_resp.message, 'Error');
                        }
                      }), (err: any) =>{
                        this.disableNextButton=true;
                        this.toaster.showError(err.message, 'Error');
                      };
                  }
                }
              }
            } else {
              this.panReupload=false;
              this.document_id = res.id;
              if (doc_type == 4) {
                this.showHideDirectorAddressProof=false;
                this.pan_verify=true;
                this.toaster.showSuccess(res.message, 'Success');
                this.isHiddenPan = true;
                this.isShow = false;
                this.enableText = true;
              }
              else{
                if (doc_type == 5 && this.partnersDocumentDetail.address_proof == 'PASSPORT') {
                  this.aadharPPFrontData = res.data;
                  this.toaster.showSuccess(res.message, 'Success');
                }else{
                  this.updateKycDetail(res);
                  this.compareNames();
                  if (this.isPartOrDir == 'd') {
                    this.pushDirectorData(res);
                  } else { this.isShowSaveNextButton = this.kycServices.finalKycStep.getValue() == 10 ? true : false; }
                  this.toaster.showSuccess(res.message, 'Success');
                }
                this.disableNextButton=false;
              }
            }

          } else {
            if( doc_type == 4 && this.isPartOrDir == 'd' ){
              this.panReupload=true;
              this.document_id = res?.id ? res?.id : this.document_id;
            }
            this.onOcrFail(doc_type);
            this.toaster.showError(res.message, 'Error');
          }
        }, error: (err: any) => {
          this.onOcrFail(doc_type);
          this.toaster.showError(err.message, "Error");
          this.disableNextButton=true;
        }
      });

  }
  onOcrFail(doc_type: any) {
    if (doc_type == 4) { this.partnersDocumentDetail.partner_file_url = 'assets/assets/img/upload_file.png' }
    else if (doc_type == 5) { this.partnersDocumentDetail.partner_file_url1 = 'assets/assets/img/upload_file.png' }
    else if (doc_type == 6) { this.partnersDocumentDetail.partner_file_url2 = 'assets/assets/img/upload_file.png' }
  }
  sendOtp() {
    const ocrPayLoad = new FormData();
    ocrPayLoad.append('id', this.document_id);
    ocrPayLoad.append('aadhar_number', this.aadhar_no);
    ocrPayLoad.append('fullname', this.full_name);
    ocrPayLoad.append('type', 'AADHAR');
    this.kycServices.createAadharOtp(ocrPayLoad).subscribe((res) => {
      if (res.statuscode == 200) {
        if (!this.isResendOtp) {
          this.openOTPModal();
        }
        this.aadgarRefid = res.refid;
        this.toaster.showSuccess(res.message, 'Success');
      } else {
        this.toaster.showError(res.message, 'Error');
      }
    }),(err:any)=>{
      this.toaster.showError(err.message, 'Error');
    };
  }
  openOTPModal() {
    this.customConfig = {};
    this.templateRef = this._TemplateModalService.open(
      this.modalTemplate,
      {},
      this.customConfig
    );
    this.templateRef.afterClosed$.subscribe((val) => {
      if (val.type == 'ok') {
      } else {
        //this.frontSide.nativeElement.value = '';
        if (!this.isOtpVerify) {
          this.partnersDocumentDetail.partner_file_url1 = "assets/assets/img/upload_file.png"
        }
      }
    });
  }

  verifyOtp(otp: any) {
    if (!otp) {
      this.toaster.showInfo('please enter OTP', 'Info');
      return;
    }
    const data = new FormData();
    data.append('refid', this.aadgarRefid);
    data.append('id', this.document_id);
    data.append('otp', otp);
    data.append('type', 'AADHAR');
    data.append('fullname', this.partnersDocumentDetail.name);
    data.append('fileurl', this.partnersDocumentDetail.partner_file_url1);
    data.append('fileburl', this.partnersDocumentDetail.partner_file_url2);
    this.kycServices.verifyAadharOtp(data).subscribe((res: any) => {
      if (res && res.statuscode == 200) {
        this.disableNextButton=false;
        this.isOtpVerify = true;
        this.updateKycDetail(res);
        this.closeTemplateModal();
        this.toaster.showSuccess(res.message, 'Success');
        this.compareNames();
        if (this.isPartOrDir == 'd') {
          this.pushDirectorData(res);
        } else {
          this.isShowSaveNextButton = this.kycServices.finalKycStep.getValue() == 10 ? true : false;
        }
      } else {
        this.disableNextButton=true;
        this.toaster.showError(res.message, 'Error');
      }
    }),(err:any)=>{
      this.toaster.showError(err.message, 'Error');
    };
  }

  async sendOTP(isResendOtp: boolean) {
    this.isResendOtp = true;
    this.sendOtp();
  }
  closeTemplateModal($event?: CustomOverlayConfig) {
    this.templateRef.close(this.customConfig);
    this.isOtpVerify = false;

  }
  closeModalPopUp(){
      let customConfig: ConfirmationModalConfig = {
        data: {
          icon: 'info',
          title: 'Final Confirmation?',
          title2: '',
          msg: "if you are closing the OTP model you will be lost your aadhar detail ?",
          btnConfirm: 'Confirm',
          btnClose: 'Cancel'
        },
      };

      let confirmationRef =
        this._ConfirmationModalService.open<ConfirmationComponent>(
          ConfirmationComponent,
          customConfig
        );
      confirmationRef.events.pipe(
        filter((event) => {
          // console.log(event);
          return !!event
        }),
      ).subscribe(async (events) => {
        switch (events.type) {
          case 'confirm':
            confirmationRef.close(customConfig);
            this.templateRef.close(this.customConfig);
            this.isOtpVerify = false;
            this.partnersDocumentDetail.partner_file_url1 = 'assets/assets/img/upload_file.png';
            this.partnersDocumentDetail.partner_file_url2 = 'assets/assets/img/upload_file.png';
            this.frontSide.nativeElement.value = '';
            this.backSide.nativeElement.value = '';
            break;
          case 'close':
            confirmationRef.close(customConfig);
            break;
        }
      });

  }
  SaveAndNext() {
    //|| (this.userDetail.company_id == 3 || this.userDetail.company_id == 11)
    // console.log(this.allDirectorList);
    if((this.userDetail.company_id != 3 && this.userDetail.company_id != 11 && (this.allDirectorList.length < 2 || (this.allDirectorList[1]?.status != 1 && this.allDirectorList[1]?.status != 2 ))) ){
      this.toaster.showError('Please provide minimum 2 director detail including authorised signatory','Error');
      return;
    }else{
      this.isKycStep.emit(9);
      this.userDetailsService.updateLocalStorage({ ['data.kyc_step']: 9 });
    }

  }

  pushDirectorData(data:any) {
    if (this.isDirectorUpdate) {
      this.kycPartnerList = this.kycPartnerList.filter(f => f.document_id != this.document_id);
    }

    this.kycPartnerList.push({
      pan_file: this.partnersDocumentDetail.partner_file_url,
      aadhar_file: this.partnersDocumentDetail.partner_file_url1,
      aadhar_back_file: this.partnersDocumentDetail.partner_file_url2,
      name: this.partnersDocumentDetail.name,
      mobile: this.partnersDocumentDetail.mobile,
      email: this.partnersDocumentDetail.email,
      type: this.partnersDocumentDetail.address_proof,
      document_id: this.document_id,
      status:data?.doc_status?data?.doc_status: 2,
      remarks: ''
    });
    if (this.isPartOrDir == 'd') {
      this.setPartnerDefaultImage();
    }
  }
  updateKycDetail(data:any) {
    if (this.kycServices.finalKycStep.getValue() == 10) {
      let payload_: FormData = new FormData();
      this.kycServices.finalKycUpdate(payload_).subscribe(res => { })
    }
    var jsonObj: any = {};
    jsonObj.av_path = this.partnersDocumentDetail.partner_file_url1;
    jsonObj.av_bpath = this.partnersDocumentDetail.partner_file_url2;
    jsonObj.full_name = this.partnersDocumentDetail.name;
    jsonObj.mobile = this.partnersDocumentDetail.mobile;
    jsonObj.email = this.partnersDocumentDetail.email;
    jsonObj.id = this.document_id;
    jsonObj.pan_path = this.partnersDocumentDetail.partner_file_url;
    jsonObj.status = data?.doc_status?data?.doc_status: 2;
    jsonObj.type = this.partnersDocumentDetail.address_proof;
    jsonObj.update_counter = 1;
    if (this.isPartOrDir == 'a') {
      if (this.allDirectorList.length > 0) {
        this.allDirectorList.shift();
        this.allDirectorList.unshift(jsonObj);
      } else {
        this.allDirectorList.push(jsonObj);
      }
    } else {
      if (this.isDirectorUpdate) {
        this.allDirectorList = this.allDirectorList.filter(f => f.id != this.document_id);
      }
      this.allDirectorList.push(jsonObj);
    }
    this.userDetailsService.updateKycDetail({
      ['kyc_list.kycpartnership']: this.allDirectorList,
    });
  }
  editDocument(value: any) {

    this.partnersDocumentDetail.document_id = value.document_id ?? value.id;
    this.document_id = value.document_id ?? value.id;
    this.partnersDocumentDetail.name = value.name ?? value.full_name;
    this.partnersDocumentDetail.mobile = value.mobile ;
    this.partnersDocumentDetail.email = value.email ;
    this.partnersDocumentDetail.address_proof = '';
    this.partnersDocumentDetail.partner_file_url = value.pstatus == 0 || value.pstatus == 4 ?'assets/assets/img/upload_file.png':value.pan_file;
    this.partnersDocumentDetail.partner_file_url1 =value.ostatus == 0 || value.ostatus == 4 ? 'assets/assets/img/upload_file.png':value.av_path;
    this.partnersDocumentDetail.partner_file_url2 = value.ostatus == 0 || value.ostatus == 4 ?'assets/assets/img/upload_file.png':value.av_bpath;
    this.partnersDocumentDetail.remarks=value.remarks;
    this.isHiddenPan = value.pstatus == 0 || value.pstatus == 4 ? false : true;
    this.showHideDirectorAddressProof=value.pstatus == 0 || value.pstatus == 4 ? true : false;
    this.isDirectorUpdate = true;
  }
  compareNames() {
    // console.log(this.auth_pers_name)
    // if((this.auth_pers_name.a_name.trim() != this.auth_pers_name.p_name.trim()) || (this.auth_pers_name.p_name.trim() != this.auth_pers_name.av_name.trim())){
    //   this.toaster.showInfo("Name mismatch","Info");
    // }
  }
  kycAadharStore(data: any) {
    const form_data=new FormData();
    form_data.append('fileurlf',data.fileurlf);
    form_data.append('type','AADHAR');
    form_data.append('fileurlb',data.fileurlb);
    form_data.append('id',data.id);
    this.kycServices.kycAadharStore(form_data).subscribe(responce=>{
      if(responce && responce.statuscode == 200){
        const formData=new FormData();
        formData.append('id',data.id);
        formData.append('type','AADHAR');
        formData.append('fileurlf',data.fileurlf);
        formData.append('fileurlb',data.fileurlb);
        formData.append('file',data.file);
        formData.append('fileb',data.fileb);
        this.kycServices.kycAadharOcr(formData).subscribe({
          next:(result:any)=>{
            if(result && result.statuscode == 200 ){
                  if(result.reupload){
                    this.disableNextButton=true;
                    this.toaster.showError(result.message,"Error");
                    this.partnersDocumentDetail.partner_file_url1 = 'assets/assets/img/upload_file.png'
                    this.partnersDocumentDetail.partner_file_url2 = 'assets/assets/img/upload_file.png'

                  }
                  else if(!result.reupload && !result.call_api_verify){
                    this.toaster.showSuccess(result.message,"Success");
                    this.disableNextButton=false;
                    this.updateKycDetail(result);
                    if (this.isPartOrDir == 'd') {
                      this.pushDirectorData(result);
                    }
                  }else if(!result.reupload && result.call_api_verify){
                    this.aadhar_no=result.data?.aadhaar_number;
                    this.full_name=result.data?.full_name;
                    this.isResendOtp=false;
                    this.sendOtp();
                  }
                }else{
                  this.toaster.showError(result.message,"Error");
                }
          },error:(err:any)=>{
            this.toaster.showError(err.message,"Error");
             this.partnersDocumentDetail.partner_file_url1 = 'assets/assets/img/upload_file.png'
             this.partnersDocumentDetail.partner_file_url2 = 'assets/assets/img/upload_file.png'

          }
        })
      }else{
        this.toaster.showError(responce.message,"Error");
        this.partnersDocumentDetail.partner_file_url1 = 'assets/assets/img/upload_file.png'
        this.partnersDocumentDetail.partner_file_url2 = 'assets/assets/img/upload_file.png'
      }
    })
  }
  viewDoc(value:any){
    let customConfigData: ConfirmationModalConfig = { data:{img:value}};
    let confirmationRef =this._ConfirmationModalService.open<DocModalComponent>(DocModalComponent,customConfigData);
  }
}

