
<div class="modal dashboard-activation kyc-verify d-block" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
            <div class="modal-content">

                <div class="modal-body p-0">
                  <button type="button" class="close" data-dismiss="modal" (click)="onCloseTab()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="login-form">
                    <div class="logo-dash mb-4"><img src="assets/assets/img/logo.png"></div>
                    <h2>Verify Your Email</h2>
                    <p>Please enter OTP has been sent to your registered email id.</p>
                    <form class="custom-form mt-2" >
                      <div class="form-row mt-4">
                          <div class="form-group col-lg-12">
                              <div class="input-wrapper w-100">

                                  <input type="text" [(ngModel)]="otp" class="form-control" maxlength="6"
                                  [appInputRestriction]="'integer'" placeholder=""  required>

                                  <label for="otp">OTP</label>
                              </div>
                              <div class="text-right otp-time">{{transform(counter)}}</div>
                          </div>
                      </div>
                      <div class="mt-1 w-100">
                          <button  class="btn login-btn" (click)="onSubmitOTP()">Verify OTP</button>
                      </div>
                  </form>
                    </div>
                </div>
            </div>
        </div>
</div>





