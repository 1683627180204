import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from '@core/services/session-storage.service';
import { FormModalOverlayRef } from '@features/customCDK/cdkCustomMdl/classes/form-modal.ref';
import { ComponentModalService, FormModalConfig } from '@features/customCDK/cdkCustomMdl/services/component-modal.service';
import { AuthCoreService } from '@page/auth/auth.service';
import { BehaviorSubject, interval, map, Observable, Subject, takeWhile } from 'rxjs';
import { AutoLogOutPopComponent } from '../auto-log-out-pop/auto-log-out-pop.component';

const STORE_KEY = 'userLastAction';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class AutoLogoutService {
  runTimer!: boolean;
  runSecondTimer!: boolean;
  USER_IDLE_TIMER_VALUE_IN_MIN!: number;
  FINAL_LEVEL_TIMER_VALUE_IN_MIN!: number;
  userIdlenessChecker: BehaviorSubject<string> = new BehaviorSubject<string>('INITIATE_TIMER'); secondTimerLeft: any;
  ;
  secondLevelUserIdleChecker: BehaviorSubject<string> = new BehaviorSubject<string>('INITIATE_SECOND_TIMER');;
  unSubAll: Subject<any> = new Subject();

  private sessionForIdle: Observable<number>;
  private userActivityChangeCallback: ($event) => void;
  secondTimerLeftFn: BehaviorSubject<any> = new BehaviorSubject<any>('');

  public clockForIdle: Observable<number>;

  formatTimeLeft = (time: number) => {
    if (time > 0) {
      let seconds = Math.trunc(time / 1000);

      // this.setCircleDasharray(seconds);

      let min = 0;
      if (seconds >= 60) {
        min = Math.trunc(seconds / 60);
        // console.log(min);
        seconds -= (min * 60);
      }

      return `${min}:${seconds}`;
    }
    return false;
  }
  dialogRef: FormModalOverlayRef;

  constructor(
    private zone: NgZone,
    private _Router: Router,
    private componentModalService: ComponentModalService,
    private sessionStorage: SessionStorageService,

  ) {
    // if (!this.userIdlenessChecker) {
    //   this.userIdlenessChecker = new BehaviorSubject<string>('INITIATE_TIMER');
    this.userIdlenessChecker.subscribe((status: string) => {
      if (status) {
        // initiateFirstTimer = (status: string) => {
        switch (status) {
          case 'INITIATE_TIMER':
            break;

          case 'RESET_TIMER':
            break;

          case 'STOPPED_TIMER':
            // this.showSendTimerDialog();
            let customConfig: FormModalConfig = {
              data: {
                secondTimerLeft: this.secondTimerLeftFn,
                // tblData: data,
                action: 'Nav delete this section',
              },
              // customOverlayPosition: { topCenter: ['30px', '0px'] },
              // isAnimation: {
              //   inAnimationStyle: 'slideInDown',
              //   outAnimationStyle: 'slideOutUp',
              // },
              // backdropClass: 'my-overlay-lightbox-backdrop'
            };

            this.dialogRef =
              this.componentModalService.open<AutoLogOutPopComponent>(
                AutoLogOutPopComponent,
                customConfig
              );
            this.dialogRef.afterClosed$.subscribe((method: any) => {
              if (method.type == 'continue') {
                this.runSecondTimer = false;
                this.initilizeSessionTimeout();
              } else {
                this.runTimer = false;
                this.runSecondTimer = false;
                this.sessionStorage.clearStorage();
                this._Router.navigate(['/login']);
                // this._AuthCoreService.logout();
              }
            })
            break;

          default:
            // this.idleTimerLeft = this.formatTimeLeft(Number(status));
            break;
        }
        // }


      }
    });
    // }

    // if (!this.secondLevelUserIdleChecker) {
    //   this.secondLevelUserIdleChecker = new BehaviorSubject<string>('INITIATE_SECOND_TIMER');
    this.secondLevelUserIdleChecker.subscribe((status: string) => {
      if (status) {
        // initiateSecondTimer = (status: string) => {
        switch (status) {
          case 'INITIATE_SECOND_TIMER':
            break;

          case 'SECOND_TIMER_STARTED':
            break;

          case 'SECOND_TIMER_STOPPED':
            this.dialogRef.close()
            // this._AuthCoreService.logout();
            // this.logout();
            break;

          default:
            this.secondTimerLeftFn.next(status)
            // this.secondTimerLeftFn(status);
            // this.secondTimerLeft = status;
            break;
        }
        // }
      }
    });
    // }
  }
  public init() {
    this.USER_IDLE_TIMER_VALUE_IN_MIN = 5;
    this.FINAL_LEVEL_TIMER_VALUE_IN_MIN = 1;
    this.initilizeSessionTimeout()
  }
  uninit() {
    this.runTimer = false;
    this.runSecondTimer = false;
  }
  public initilizeSessionTimeout(): void {
    this.runTimer = true;

    if (this.USER_IDLE_TIMER_VALUE_IN_MIN === 0) {
      this.userIdlenessChecker.thrownError('Please provide USER_IDLE_TIMER_VALUE in minuite');
      return;
    }

    this.reset();
    this.initListener();
    this.initInterval();
  }

  get lastAction(): number {
    return parseInt(localStorage.getItem(STORE_KEY) ?? '', 10);
  }

  set lastAction(value) {
    localStorage.setItem(STORE_KEY, value.toString());
  }

  private initListener(): void {
    this.zone.runOutsideAngular(() => {
      this.userActivityChangeCallback = ($event) => this.handleUserActiveState($event);
      let events: any = [
        'click', 'dblclick', 'mousedown', 'mouseup', 'contextmenu', 'mouseout', 'mousewheel', 'mouseover', 'touchstart', 'touchend', 'touchmove', 'touchcancel', 'keydown', 'keyup', 'keypress', 'focus', 'blur', 'change', 'submit', 'resize', 'scroll', 'load', 'unload', 'hashchange'

      ]
      events.forEach((key: string) => {
        window.document.addEventListener(key, this.userActivityChangeCallback.bind(this), true);
      });
      // Object.keys(window).forEach(key => {
      //   sd.push(key);
      //   if (/^on/.test(key)) {
      //     // window.addEventListener(key.slice(2), event => {
      //     //     //console.log(event);
      //     // });
      //     window.document.addEventListener(key, this.userActivityChangeCallback.bind(this), true);
      //   }
      // });

      // window.document.addEventListener('click', this.userActivityChangeCallback.bind(this), true);
      // window.document.addEventListener('mouseover', this.userActivityChangeCallback.bind(this), true);
      // window.document.addEventListener('mouseout', this.userActivityChangeCallback.bind(this), true);
      // window.document.addEventListener('keydown', this.userActivityChangeCallback.bind(this), true);
      // window.document.addEventListener('keyup', this.userActivityChangeCallback.bind(this), true);
      // window.document.addEventListener('keypress', this.userActivityChangeCallback.bind(this), true);
    });
  }

  handleUserActiveState(event): void {
    this.reset();
  }

  public reset(): void {
    this.lastAction = Date.now();
    if (this.userIdlenessChecker) {
      this.userIdlenessChecker.next('RESET_TIMER');
    }
  }

  private initInterval(): void {
    const intervalDuration = 1000;
    this.sessionForIdle = interval(intervalDuration).pipe(
      map((tick: number) => {
        return tick;
      }),
      takeWhile(() => this.runTimer)
    );

    this.check();
  }

  private check(): void {
    this.sessionForIdle
      .subscribe(() => {
        const now = Date.now();
        const timeleft = this.lastAction + this.USER_IDLE_TIMER_VALUE_IN_MIN * 60 * 1000;
        const diff = timeleft - now;
        const isTimeout = diff < 0;

        this.userIdlenessChecker.next(`${diff}`);

        if (isTimeout) {
          window.document.removeEventListener('click', this.userActivityChangeCallback, true);
          this.zone.run(() => {
            if (this.userIdlenessChecker) {
              this.userIdlenessChecker.next('STOPPED_TIMER');

              if (this.FINAL_LEVEL_TIMER_VALUE_IN_MIN > 0) {
                this.secondLevelUserIdleChecker.next('SECOND_TIMER_STARTED');
                this.executeFinalTimer();
              }
            }
            this.runTimer = false;
          });
        }
      });
  }

  public removeActionFromStore(): void {
    localStorage.removeItem(STORE_KEY);
  }

  private executeFinalTimer = () => {
    this.runSecondTimer = true;
    this.initializeFinalTimer();
  }

  private initializeFinalTimer(): void {
    const intervalDuration = 1000;
    this.clockForIdle = interval(intervalDuration).pipe(
      map((tick: number) => {
        return tick;
      }),
      takeWhile(() => this.runSecondTimer)
    );

    this.checkUserActionTime();
  }

  private checkUserActionTime(): void {
    let timeInSecond = 60;
    let timeInMin = this.FINAL_LEVEL_TIMER_VALUE_IN_MIN - 1;
    this.clockForIdle.subscribe(() => {
      if (--timeInSecond === 0) {
        if (--timeInMin === 0) {
          timeInMin = (timeInMin > 0) ? (timeInMin - 1) : 0;
        }
        if (timeInMin === -1 && timeInSecond === 0) {
          this.runSecondTimer = false;

          if (this.secondLevelUserIdleChecker) {
            this.secondLevelUserIdleChecker.next('SECOND_TIMER_STOPPED');
          }
        }
        if (timeInMin < 0) {
          timeInMin = 0;
          setTimeout(() => {
            timeInSecond = 60;
          }, 800);
        } else {
          timeInSecond = 60;
        }
      }

      this.secondLevelUserIdleChecker.next(`${timeInMin}:${timeInSecond}`);
    });
  }

  ngOnDestroy(): void {
    // if (this.userIdlenessChecker) {
    //   this.userIdlenessChecker.unsubscribe();
    //   this.userIdlenessChecker.next('');
    // }

    // if (this.secondLevelUserIdleChecker) {
    //   this.secondLevelUserIdleChecker.unsubscribe();
    //   this.secondLevelUserIdleChecker.next('');
    // }
  }

}
