import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BankDetailComponent } from './bank-detail.component';
import { SharedModule } from '@shared/shared.module';



@NgModule({
  declarations: [BankDetailComponent],
  imports: [
    SharedModule
  ],
  exports:[BankDetailComponent]
})
export class BankDetailModule { }
