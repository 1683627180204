import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-global-drop-down',
  templateUrl: './global-drop-down.component.html',
  styleUrls: ['./global-drop-down.component.scss']
})
export class GlobalDropDownComponent implements OnInit {

  @Input() dataList: any[];
  @Input() keyword: string;
  @Input() placeHolderName: string;
  @Input() selectedid: string;
  @Output() selected_value = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  onValueSelect() {
    this.selected_value.emit(this.selectedid);
  }
}
