<header class="header navbar navbar-expand-sm">
  <ul class="navbar-nav theme-brand flex-row text-center justify-content-center">
    <li class="nav-item theme-logo">
      <a (click)="onSideBar(dashboardStatic, 0)" class="d-flex align-items-center">
        <img src="assets/assets/img/logo-icon.png" class="navbar-logo" alt="logo" />
        <span><img src="assets/assets/img/logo_text.png" /></span>
      </a>
    </li>
    <li class="toggle-sidebar">
      <a href="javascript:void(0);" (click)="showhide()" class="sidebarCollapse" data-placement="bottom"><svg
          xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
          class="feather feather-chevron-left">
          <polyline points="15 18 9 12 15 6" class="text-black"></polyline>
        </svg></a>
    </li>
  </ul>
  <div class="pageTitle" [ngClass]="{'hasSubMenu':_payoutSideBar}">
    {{_payoutSideBar?_title:_titleName}}
  </div>
</header>

<div class="sidebar-wrapper sidebar-theme scoll-bar" aria-expanded="true">
  <nav id="sidebar">
    <ul class="list-unstyled menu-categories ps" id="accordionExample">
      <li class="menu" *ngFor="let obj of sidebarList; let i = index">
        <a (click)="onSideBar(obj, i)" data-toggle="collapse" [attr.aria-expanded]="i === selectedItem"
        *ngIf="_permissionService?.hasModule(obj?.module)"
          class="dropdown-toggle" [ngClass]="{ active: i === selectedItem }">
          <div class="" style="display: flex">
            <div [innerHTML]="obj?.icon | safeHtml" title="{{obj?.name}}"></div>
            <span>{{ obj?.name }}</span>
          </div>
        </a>
      </li>

    </ul>

    <ul class="list-unstyled menu-categories">
      <li class="menu" (click)="bottomsidebar('profile')">
        <a class="dropdown-toggle" [attr.aria-expanded]="selectedBottom == 'profile'" (click)="clickProfile()"
          [ngClass]="{ active: selectedBottom == 'profile' }">
          <div class="d-flex profileBox">
            <div><img [src]="userDetail?.profile_picture" title="Profile" /></div>
            <div>
              <span>{{ userDetail.firmname }}</span>
              <!-- <span>{{ userDetail.name }}</span> -->
              <span>{{ userDetail.username }}</span>
            </div>
          </div>
        </a>
      </li>

      <li class="menu" (click)="bottomsidebar('logout')">
        <a class="dropdown-toggle" [attr.aria-expanded]="selectedBottom == 'logout'"
          [ngClass]="{ active: selectedBottom == 'logout' }">
          <div class="d-flex" (click)="logOut()" title="Logout">
            <svg id="logout" xmlns="http://www.w3.org/2000/svg" width="24" height="23.905" viewBox="0 0 24 23.905">
              <path id="Path_268772" data-name="Path 268772"
                d="M315.23,48.215a3.415,3.415,0,0,0-3.415-3.415h-7.684a3.415,3.415,0,0,0-3.415,3.415V51.4a1.138,1.138,0,0,0,2.277,0V48.215a1.138,1.138,0,0,1,1.138-1.138h7.684a1.138,1.138,0,0,1,1.138,1.138V65.29a1.138,1.138,0,0,1-1.138,1.138h-7.684a1.138,1.138,0,0,1-1.138-1.138v-3.13a1.138,1.138,0,0,0-2.277,0v3.13a3.415,3.415,0,0,0,3.415,3.415h7.684a3.415,3.415,0,0,0,3.415-3.415Z"
                transform="translate(-291.23 -44.8)" fill="#586270" />
              <path id="Path_268773" data-name="Path 268773"
                d="M122.02,139.364a1.138,1.138,0,0,0-1.611,0l-5.976,5.976h0a1.138,1.138,0,0,0,0,1.708l5.976,5.976h0a1.139,1.139,0,1,0,1.611-1.611l-4.1-4.081h12.724a1.138,1.138,0,1,0,0-2.276H117.925l4.1-4.092a1.138,1.138,0,0,0,0-1.6Z"
                transform="translate(-114.048 -134.241)" fill="#586270" />
            </svg>

            <span>Logout</span>
          </div>
        </a>
      </li>

      <!-- <li class="menu" (click)="onHelp();bottomsidebar('help')">
        <a class="dropdown-toggle" [attr.aria-expanded]="selectedBottom == 'help'"
          [ngClass]="{ active: selectedBottom == 'help'}">
          <div class="d-flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g id="help" transform="translate(-70.012 0.002)">
                <path id="Path_268802" data-name="Path 268802"
                  d="M94.011,11.564V22.575A1.426,1.426,0,0,1,92.589,24H81.249c-6.113,0-11.237-5.983-11.237-12a12,12,0,0,1,12-12c6.2,0,12,5.2,12,11.566ZM81.13,15.284h1.763V13.6c0-2.264,3.146-1.362,3.146-4.728,0-4.923-8.054-4.961-8.054.461h1.823c.041-3.032,4.408-3.115,4.408-.441,0,2.585-3.085,1.563-3.085,4.688v1.7Zm-.241,3.987h2.3V16.926h-2.3Z"
                  fill="#586270" fill-rule="evenodd" />
              </g>
            </svg>
            <span>Help</span>
          </div>
        </a>
      </li> -->
    </ul>
  </nav>
</div>

<div class="subMenu" *ngIf="_payoutSideBar">
  <h3>{{_titleName}}</h3>
  <ul>
    <li *ngFor="let item of _payoutSideBar;let i=index">
      <a class="nav-link" *ngIf="_permissionService.hasPermission(item.permission)" [routerLink]="[item.routePath]"
        [routerLinkActive]="'active'" href="javascript:void(0);" (isActiveChange)="$event ?_title = item.value:null">
        {{item.value}}
      </a>
    </li>
  </ul>
</div>

<!-- (isActiveChange)="$event ?pageHeading = item.value:null" -->
