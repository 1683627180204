
<div class="app-submit bene-added">
    <div class="blue-bar d-flex justify-content-end align-items-center">
        <button type="button" class="close-btn" data-dismiss="modal" (click)="closeModal($event)">
            <span>×</span>
        </button>
    </div>

    <div class="text-center px-5 pb-5 app-content">
        <div class="app-content-tick"><i class="far fa-check"></i></div>
        <p class="pt-3" *ngIf="modalSetting.title2" [innerHTML]="modalSetting.title2"></p>
        <button type="button" *ngIf="modalSetting.btnConfirm" (click)="confirmModal($event)" class="btn save-btn mt-2">{{modalSetting.btnConfirm}}</button>

    </div>

</div>
