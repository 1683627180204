
<div class="payment-modal">
  <div class="blue-bar d-flex justify-content-between align-items-center">
    <h2>Do you want to Repeat Payment ?</h2>
    <button type="button" class="close-btn" data-dismiss="modal" (click)="closeModal($event)">
      <span>×</span>
    </button>
  </div>

  <div class="px-5 py-4 w-100">
    <div>
      <div class="title-repeat">
        <p>Select Repeat Mode</p>
        <div class="mb-3">
          <div class="select-repeat-mode d-flex">
            <div class="radio">
              <input  id="radio-1" class="radio-custom" name="radio-group1" [value]="modalSetting.repeatMode_auto" [formControl]="radioForm1" type="radio">
              <label for="four-wheeler4" class="radio-label">Auto Sched.</label>
            </div>
            <div class="radio">
              <input  id="radio-1" class="radio-custom" name="radio-group1" [value]="modalSetting.repeatMode_semi" [formControl]="radioForm1" type="radio">
              <label for="four-wheeler4" class="radio-label">Semi Sched.</label>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="title-repeat">
            <p>Select Repeat Time Interval</p>
          </div>
          <div class="select-repeat-mode d-flex">
            <div class="radio">
              <input  id="radio-2" class="radio-custom" name="radio-group2" [value]="modalSetting.timeInterval_monthly" [formControl]="radioForm2" type="radio">
              <label for="four-wheeler4" class="radio-label">Monthly</label>
            </div>
            <div class="radio">
              <input  id="radio-2" class="radio-custom" name="radio-group2" [value]="modalSetting.timeInterval_early" [formControl]="radioForm2" type="radio">
              <label for="four-wheeler4" class="radio-label">Early</label>
            </div>
            <div class="radio">
              <input  id="radio-2" class="radio-custom" name="radio-group2" [value]="modalSetting.timeInterval_weekly" [formControl]="radioForm2" type="radio">
              <label for="four-wheeler4" class="radio-label">Weekly</label>
            </div>
          </div>

        </div>


        <div class="mb-2">
          <div class="title-repeat">
            <p>Send Advance Notification</p>
          </div>
          <div class="select-repeat-mode d-flex">
            <div class="radio">
              <input id="radio-3" class="radio-custom" name="radio-group3" [value]="modalSetting.notification_yes" [formControl]="radioForm3" type="radio">

              <label for="four-wheeler4" class="radio-label">Yes</label>
            </div>
            <div class="radio">
              <input  id="radio-3" class="radio-custom"name="radio-group3" [value]="modalSetting.notification_no" [formControl]="radioForm3" type="radio">

              <label for="four-wheeler4" class="radio-label">No</label>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
  <div class="btn-main">
    <div class="col-lg-5">
      <button type="button" class="btn btn-no" *ngIf="modalSetting.btnClose"   (click)="cancelModal($event)">
        {{modalSetting.btnClose}}
      </button>
    </div>
    <div class="col-lg-5">
      <button type="button" class="btn btn-yes" *ngIf="modalSetting.btnConfirm" (click)="confirmModal($event)">
        {{modalSetting.btnConfirm}}
      </button>
    </div>
  </div>

</div>
