import { Directive, Input, TemplateRef, OnInit, ElementRef, HostListener, ViewContainerRef } from '@angular/core';
import { OverlayRef, Overlay, OverlayPositionBuilder, ConnectedPosition } from '@angular/cdk/overlay';
import { ComponentType, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import * as $ from "jquery";


export let defPos: ConnectedPosition[] = [
  {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    offsetX: 0,
    offsetY: -15,
    panelClass: 'top'
  },
  {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
    offsetX: 15,
    offsetY: 0,
    panelClass: 'right'
  },
  {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    offsetX: 0,
    offsetY: 15,
    panelClass: 'bottom'
  },
  {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
    offsetX: -15,
    offsetY: 0,
    panelClass: 'left'
  },

]

export const posOptions: { [key: string]: ConnectedPosition[] } = {
  top: [
    defPos[0],
    defPos[1],
    defPos[2],
    defPos[3],
  ],
  right: [
    defPos[1],
    defPos[3],
    defPos[0],
    defPos[2],
  ],
  bottom: [
    defPos[2],
    defPos[0],
    defPos[3],
    defPos[1],
  ],
  left: [
    defPos[3],
    defPos[1],
    defPos[0],
    defPos[2],
  ]
}

@Directive({
  selector: '[customTooltip]'
})
export class CustomTooltipDirective implements OnInit {
  // Contenido que se va a renderizar dentro del tooltip
  // Content to be rendered within the tooltip
  @Input('customTooltip') tooltipContent: TemplateRef<any> | ComponentType<any>|any = null;
  @Input('customTooltipType') customTooltipType: 'default' = 'default';
  @Input('prefPos') prefPos: 'top' | 'right' | 'bottom' | 'left' = 'bottom';

  /** Overlay que simula ser un tooltip */
  // Overlay that simulates being a tooltip
  private _overlayRef!: OverlayRef;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
  ) { }

  ngOnInit(): void {
    // Si se recibe el contenido a mostrar
    // If the content to display is received

    if (this.tooltipContent) {
      // Se crea la configuración de posicionamiento para el tooltip
      // The positioning configuration for the tooltip is created

      const position = this.overlayPositionBuilder
        .flexibleConnectedTo(this.elementRef)
        .withPositions(posOptions[this.prefPos]);

      // Se crea el overlay y se guarda su referencia
      // The overlay is created and its reference is saved
      this._overlayRef = this.overlay.create({
        // Configuración para la posición del overlay
        // Settings for the position of the overlay
        positionStrategy: position,
        // Comportamiento del overlay cuando se haga scroll y se esté mostrando
        // Overlay behavior when scrolling and showing
        scrollStrategy: this.overlay.scrollStrategies.close(),
        // Clase para darle estilo al overlay
        // Class to style the overlay
        panelClass: ['custom-tooltip', this.customTooltipType],
      });
    }
    // Se muestra un error si la directiva no recibe contenido para mostrar
    // An error is displayed if the directive does not receive content to display
    else {
      // console.error('[ERROR] La directiva tiene que recibir el contenido a mostrar...');
      // console.error('[ERROR] The directive has to receive the content to display...');
    }
  }

  @HostListener('mouseenter', ['$event'])
  private _show(event: MouseEvent): void {
    // Si existe overlay se enlaza con el contenido
    // If there is an overlay, it is linked to the content
    if (this._overlayRef) {
      let containerPortal: TemplatePortal<any> | ComponentPortal<any>;

      // Creamos un TemplatePortal si lo que recibió la directiva era un Template
      // We create a TemplatePortal if what the directive received was a Template
      if (this.tooltipContent instanceof TemplateRef) {
        containerPortal = new TemplatePortal(this.tooltipContent, this.viewContainerRef);
      }
      // En caso contrario creamos un ComponentPortal
      // Otherwise we create a ComponentPortal
      else {
        containerPortal = new ComponentPortal(this.tooltipContent, this.viewContainerRef);
      }

      // Enlazamos el portal con el overlay creado al iniciar la directiva
      // We link the portal with the overlay created when starting the directive
      this._overlayRef.attach(containerPortal);

    }
  }

  @HostListener('mouseout', ['$event'])
  private _hide(e: MouseEvent): void {
    // console.log(this.elementRef.nativeElement);
    // console.log($(this.elementRef.nativeElement).find(e.relatedTarget as HTMLElement)[0]);

    // Si existe un overlay se desenlaza del contenido
    // If there is an overlay, it is unlinked from the content

    if (this._overlayRef && !$(e.relatedTarget as HTMLElement).closest(this.elementRef.nativeElement)[0]) {
      this._overlayRef.detach();
    }
  }

}
