import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadDocumentComponent } from './upload-document.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '@core/pipes/pipes.module';
import { DocModalModule } from '../doc-modal/doc-modal.module';



@NgModule({
  declarations: [
    UploadDocumentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    DocModalModule
  ],
  exports:[UploadDocumentComponent]
})
export class UploadDocumentModule { }
