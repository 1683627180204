import { Component, OnInit,Input,Output,ElementRef,ViewChild,EventEmitter } from '@angular/core';
import { UserDetailsService } from '@core/services/user-details.service';
import { ConfirmationModalConfig, ConfirmationModalService } from '@features/customCDK/cdkCustomMdl/services/confirmation-modal.service';
import { DocModalComponent } from '../doc-modal/doc-modal.component';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnInit {

  @Input() file_url:any;
  @Input() status:any;
  @Input() remarks:any;
  @Input() fileType:any;
  @Input() uploadFileType:any;
  @Input() reqType:any;
  @Input() reqDocument:any;
  @Input() isuploadClass:boolean;
  @Output() uploadDoc_=new EventEmitter();
  showMsg:string='';
  common_msg: string = 'Details should be of the mentioned business only';
  // fileType: string = '';
  pdfPath: string = 'assets/img/Mask Group 43.png';
  @ViewChild('fileDropRef') selectedfile: ElementRef;
  constructor(
    private userDetailsService: UserDetailsService,
    private _ConfirmationModalService: ConfirmationModalService,
  ) { }

  ngOnInit(): void {
    // console.log(this.reqDocument)
    this.userDetailsService.userLoginDetails.subscribe({
      next: (value: any) =>{
        const company_id = value?.data?.company_id;
        this.showMsg=(company_id == 3 && this.reqDocument == 'kycgst')?'Please upload GSTIN/Shop & Establishment/Udhyog Aadhar':'';
      }
    });
    //console.log(this.reqDocument);
  }
  onDocumentChange(event:any){
    var file_ = <File>event.target.files[0];
    this.uploadDoc_.emit(event);
    this.selectedfile.nativeElement.value = "";
  }
  viewDoc(value:any){
    let customConfigData: ConfirmationModalConfig = { data:{img:value}};
    let confirmationRef =this._ConfirmationModalService.open<DocModalComponent>(DocModalComponent,customConfigData);
  }
}
