<div class="fixed-top">
  <header class="navbar navbar-expand-sm">

    <div class="d-flex justify-content-end w-100 py-2">
      <!-- *ngIf="permission.hasPermission('Dashboard-View-Wallet') && isOnConnect" -->
      <div class="currentBalBox" >
        <div class="walletIconBox">
          <svg id="noun-wallet-5132069" xmlns="http://www.w3.org/2000/svg" width="20" height="18.333"
            viewBox="0 0 20 18.333">
            <path id="Path_270491" data-name="Path 270491"
              d="M203.053,124.436V121.8a2.573,2.573,0,0,0-2.105-2.5v-1.069a2.075,2.075,0,0,0-2.105-2.037H186.211a2.074,2.074,0,0,0-2.105,2.037v13.75a2.593,2.593,0,0,0,2.632,2.546h13.684a2.593,2.593,0,0,0,2.632-2.546v-2.64a1.53,1.53,0,0,0,1.053-1.434v-2.037a1.53,1.53,0,0,0-1.053-1.434Zm-16.842-7.223h12.632a1.037,1.037,0,0,1,1.053,1.018v1.018H186.211a1.019,1.019,0,1,1,0-2.037Zm14.211,16.3H186.737a1.556,1.556,0,0,1-1.579-1.528v-12a2.135,2.135,0,0,0,1.053.284h14.211A1.556,1.556,0,0,1,202,121.8v2.546h-3.684a1.556,1.556,0,0,0-1.579,1.528v2.037a1.556,1.556,0,0,0,1.579,1.528H202v2.546a1.556,1.556,0,0,1-1.579,1.528Zm2.632-5.6a.519.519,0,0,1-.526.509h-4.211a.519.519,0,0,1-.526-.509v-2.037a.519.519,0,0,1,.526-.509h4.211a.519.519,0,0,1,.526.509Z"
              transform="translate(-184.106 -116.195)" fill="#fcfcfc" />
          </svg>
        </div>
        <div class="amtBox">
          <div>Total Current Balance</div>
          <div>₹ {{ userBalance }}</div>
        </div>
        <div class="reloadBox">
          <a href="javascript:void(0);" (click)="getUserBalance()"><svg xmlns="http://www.w3.org/2000/svg" width="24"
              height="23.596" viewBox="0 0 24 23.596">
              <g id="noun-refresh-92014" transform="translate(-154.005 -83.999)">
                <path id="Path_274082" data-name="Path 274082"
                  d="M157.367,96.209a8.85,8.85,0,0,1,2.454-6.175,8.226,8.226,0,0,1,11.9,0L169.537,92.3h6.724V85.325l-2.185,2.267a11.392,11.392,0,0,0-16.608,0,12.234,12.234,0,0,0-3.463,8.617Z"
                  fill="#fff" />
                <path id="Path_274083" data-name="Path 274083"
                  d="M182,283.941v6.977l2.185-2.267a11.43,11.43,0,0,0,16.608-.035A12.379,12.379,0,0,0,204.256,280h-3.362a8.85,8.85,0,0,1-2.454,6.175,8.226,8.226,0,0,1-11.9,0l-1.177,1.256,3.362-3.489Z"
                  transform="translate(-26.251 -184.614)" fill="#fff" />
              </g>
            </svg>
          </a>
        </div>
      </div>

      <div>
        <ul class="navbar-item flex-row navbar-dropdown ml-auto">

          <li class="nav-item dropdown user-profile-dropdown order-lg-0 order-1">
            <a href="javascript:void(0);" class="nav-link dropdown-toggle user" id="userProfileDropdown"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-settings">
                <circle cx="12" cy="12" r="3"></circle>
                <path
                  d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
                </path>
              </svg>
            </a>
            <div class="dropdown-menu position-absolute animated fadeInUp" aria-labelledby="userProfileDropdown">
              <!-- <div class="user-profile-section">
                                <div class="media mx-auto">
                                    <img src="assets/assets/img/90x90.jpg" class="img-fluid mr-2" alt="avatar" />
                                    <div class="media-body">
                                        <h5 class="text-capitalize">
                                            {{ userDetails?.name }}
                                        </h5>
                                    </div>
                                </div>
                            </div> -->
              <div class="dropdown-item">
                <a (click)="myProfile()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-user">
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                  <span>My Profile</span>
                </a>
              </div>

              <div class="dropdown-item" *ngIf="this.userDetails?.is_kyc == 1 && permission.hasPermission('Dashboard-View-Kyc')">
                <a href="javascript:void(0);" (click)="openkycModule()">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="95.972"
                    height="111.85" viewBox="0 0 95.972 111.85">
                    <defs>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_25831" data-name="Rectangle 25831" width="86.611" height="100.941"
                          transform="translate(0 0)" fill="none" />
                      </clipPath>
                      <clipPath id="clip-path-2">
                        <rect id="Rectangle_25830" data-name="Rectangle 25830" width="95.972" height="111.85"
                          fill="none" />
                      </clipPath>
                    </defs>
                    <g id="Group_85097" data-name="Group 85097" transform="translate(-4683.32 -1922.545)">
                      <g id="Group_85092" data-name="Group 85092" transform="translate(4688 1928)"
                        clip-path="url(#clip-path)">
                        <path id="Path_274053" data-name="Path 274053"
                          d="M43.365,100.941c-25.837,0-41.667-9.849-41.824-9.948A3.27,3.27,0,0,1,0,88.195c0-20.9,6.8-23.113,20.343-27.518,1.988-.651,4.136-1.361,6.44-2.167a15.416,15.416,0,0,0,.435-5.744,21.756,21.756,0,0,1-3.567-6.276c-1.239-3.911-1.452-9.609-1.678-15.643l-.061-1.465c-.051-1.255-.1-2.519-.169-3.778-.067-1.2-.141-2.32-.209-3.353l-.022-.329c-.49-7.27-.786-11.653,5.741-17.211C30.695,1.762,36.8,0,43.591,0c6.931,0,12.9,1.829,15.967,4.891,6.583,6.582,6.279,9.948,5.546,18.079-.075.854-.159,1.78-.251,2.791-.251,3.081-.4,6.115-.523,8.553-.266,5.257-.474,9.371-1.351,12.158A22.481,22.481,0,0,1,59.4,52.785a11.092,11.092,0,0,0,.979,5.65c2.33.827,4.475,1.536,6.323,2.142C80.377,65.1,86.61,67.157,86.61,88.225a3.277,3.277,0,0,1-1.549,2.8c-1.719,1.036-17.163,9.916-41.7,9.916"
                          fill="#c5c0d8" />
                      </g>
                      <g id="Group_85091" data-name="Group 85091" transform="translate(4683.32 1922.545)">
                        <g id="Group_85090" data-name="Group 85090" transform="translate(0 0)"
                          clip-path="url(#clip-path-2)">
                          <path id="Path_274050" data-name="Path 274050"
                            d="M44.131,59.35a3.676,3.676,0,0,1-3.5-2.613c-1.449-4.98,1.283-9.077,3.278-12.07,2.279-3.447,2.872-4.776,2.046-6.189a1.7,1.7,0,0,0-1.352-.674,1.633,1.633,0,0,0-.367.041l-.113.021a2.886,2.886,0,0,0-2.389,2.164,3.626,3.626,0,0,1-3.552,2.809,3.653,3.653,0,0,1-3.564-4.468,10.122,10.122,0,0,1,8.376-7.728,8.649,8.649,0,0,1,1.626-.151,8.8,8.8,0,0,1,7.5,4.145c3.429,5.545.257,10.445-2.06,14.022-1.534,2.364-2.848,4.388-2.365,5.989A3.658,3.658,0,0,1,45.242,59.2a3.957,3.957,0,0,1-1.047.151Z"
                            transform="translate(3.731 3.296)" fill="#57488e" />
                          <path id="Path_274051" data-name="Path 274051"
                            d="M43.665,64.648a3.653,3.653,0,1,1,3.653-3.653,3.658,3.658,0,0,1-3.653,3.653"
                            transform="translate(4.324 6.197)" fill="#57488e" />
                          <path id="Path_274052" data-name="Path 274052"
                            d="M48.052,111.85c-28.629,0-46.17-10.913-46.344-11.023A3.623,3.623,0,0,1,0,97.727C0,74.566,7.534,72.116,22.542,67.235c2.2-.721,4.583-1.508,7.136-2.4a17.082,17.082,0,0,0,.482-6.365,24.107,24.107,0,0,1-3.953-6.954c-1.373-4.334-1.609-10.647-1.859-17.334l-.068-1.623c-.057-1.391-.114-2.791-.187-4.186-.074-1.333-.156-2.571-.232-3.715l-.024-.365c-.543-8.056-.871-12.912,6.361-19.071C34.012,1.952,40.782,0,48.3,0,55.982,0,62.6,2.027,65.995,5.42c7.294,7.293,6.958,11.023,6.145,20.033-.083.946-.176,1.972-.278,3.093-.278,3.414-.445,6.776-.58,9.477-.295,5.825-.525,10.384-1.5,13.472a24.911,24.911,0,0,1-3.964,7,12.291,12.291,0,0,0,1.085,6.261c2.582.916,4.959,1.7,7.006,2.373C89.064,72.131,95.97,74.415,95.97,97.76a3.631,3.631,0,0,1-1.716,3.1c-1.9,1.148-19.018,10.988-46.2,10.988M7.329,95.6c5.085,2.593,19.7,8.944,40.722,8.944h.009c20.656,0,35.421-6.322,40.58-8.9-.26-15.985-3.325-17-17.019-21.524L69.7,73.48c-1.992-.659-4.252-1.406-6.6-2.273a3.643,3.643,0,0,1-1.791-1.377A19.453,19.453,0,0,1,58.7,56.352a3.62,3.62,0,0,1,.835-1.8,18.315,18.315,0,0,0,3.259-5.331c.675-2.224.9-6.77,1.144-11.583l.038-.726c.143-2.731.3-5.827.571-9.006.092-1.095.185-2.136.278-3.1.779-8.627.842-9.333-4.039-14.223-2.008-1.987-6.9-3.277-12.467-3.277-5.784,0-10.923,1.33-13.41,3.47-4.448,3.781-4.351,5.194-3.814,13.028l.082,1.229c.062.932.129,1.925.186,2.99.1,1.978.176,3.966.247,5.922l.006.14c.238,6.084.463,11.833,1.483,15.139A17.677,17.677,0,0,0,36.4,54.5a3.593,3.593,0,0,1,.876,1.859,23.351,23.351,0,0,1-1.36,12.826l-.049.11A3.634,3.634,0,0,1,33.8,71.123c-3.3,1.238-6.307,2.216-8.959,3.079C11.093,78.673,7.625,79.8,7.329,95.6"
                            transform="translate(0 0)" fill="#57488e" />
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span>View Kyc</span>
                </a>
              </div>
              <div class="dropdown-item">
                <a href="javascript:void(0);" (click)="onLogOut()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-log-out">
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                  </svg>
                  <span>Log Out</span>
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </header>
</div>
