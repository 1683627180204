import { TemplatePortal } from '@angular/cdk/portal';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormModalOverlayRef } from '@features/customCDK/cdkCustomMdl/classes/form-modal.ref';
import { TemplateModalOverlayRef } from '@features/customCDK/cdkCustomMdl/classes/template-modal.ref';
import { CustomOverlayConfig, TemplateModalService } from '@features/customCDK/cdkCustomMdl/services/template-modal.service';
import { COMPONENT_MODAL_DATA } from '@features/customCDK/cdkCustomMdl/tokens/component-data.token';
import { AutoLogoutService } from '../service/auto-logout.service';

@Component({
  selector: 'app-auto-log-out',
  templateUrl: './auto-log-out.component.html',
  styleUrls: ['./auto-log-out.component.scss']
})
export class AutoLogOutComponent implements OnInit {
  @ViewChild('modalTemplate') modalTemplate!: TemplatePortal<any>;


  inputForm: any;
  idleTimerLeft: any;
  secondTimerLeft: string;
  timeRemain: number;
  FULL_DASH_ARRAY = 283;


  private templateRef!: TemplateModalOverlayRef | null;
  customConfig!: CustomOverlayConfig;


  constructor(
    private ngIdle: AutoLogoutService,
    private fb: FormBuilder,
    private _TemplateModalService: TemplateModalService,
    private _Router: Router,

    public dialogRef: FormModalOverlayRef,
    @Inject(COMPONENT_MODAL_DATA) public data: any

  ) { }

  ngOnInit(): void {
    this.inputForm = this.fb.group({
      firstLevelTimer: ['', Validators.required],
      secondLevelTimer: ['', Validators.required],
    });
    this.initTimer(0.1, 1);
  }

  _continue() {
    this.dialogRef.close(this.data, 'continue');
  }

  _logout() {
    this.dialogRef.close(this.data);
  }

  /**
   * Draw timer circle
   */
  formatTimeLeft = (time: number) => {
    if (time > 0) {
      let seconds = Math.trunc(time / 1000);

      this.setCircleDasharray(seconds);

      let min = 0;
      if (seconds >= 60) {
        min = Math.trunc(seconds / 60);
        //console.log(min);
        seconds -= (min * 60);
      }

      return `${min}:${seconds}`;
    }
    return false;
  }

  setCircleDasharray = (elapsedTime: number) => {
    const inputValue: any = this.inputForm.value;
    const timeLimit = inputValue.firstLevelTimer * 60;

    this.timeRemain = elapsedTime / timeLimit;
    const circleDasharray = `${(
      this.timeRemain * this.FULL_DASH_ARRAY
    ).toFixed(0)} 283`;
    document.getElementById('base-timer-path-remaining')?.setAttribute('stroke-dasharray', circleDasharray);
  }

  onSubmit(): void {
    if (this.inputForm.invalid) {
      return;
    }

    const inputValue = this.inputForm.value;
    this.initTimer(inputValue.firstLevelTimer, inputValue.secondLevelTimer);
  }

  initTimer(firstTimerValue: number, secondTimerValue: number): void {
    // Timer value initialization
    this.ngIdle.USER_IDLE_TIMER_VALUE_IN_MIN = firstTimerValue;
    this.ngIdle.FINAL_LEVEL_TIMER_VALUE_IN_MIN = secondTimerValue;
    // end

    // Watcher on timer
    this.ngIdle.initilizeSessionTimeout();
    this.ngIdle.userIdlenessChecker.subscribe((status: string) => {
      if (status) {
        this.initiateFirstTimer(status);
      }
    });

    this.ngIdle.secondLevelUserIdleChecker.subscribe((status: string) => {
      if (status) {
        this.initiateSecondTimer(status);
      }
    });
  }

  initiateFirstTimer = (status: string) => {
    switch (status) {
      case 'INITIATE_TIMER':
        break;

      case 'RESET_TIMER':
        break;

      case 'STOPPED_TIMER':
        this.showSendTimerDialog();
        break;

      default:
        this.idleTimerLeft = this.formatTimeLeft(Number(status));
        break;
    }
  }

  initiateSecondTimer = (status: string) => {
    switch (status) {
      case 'INITIATE_SECOND_TIMER':
        break;

      case 'SECOND_TIMER_STARTED':
        break;

      case 'SECOND_TIMER_STOPPED':
        this.logout();
        break;

      default:
        this.secondTimerLeft = status;
        break;
    }
  }

  showSendTimerDialog(): void {
    // const modal: any = document.getElementById('myModal');
    // modal.style.display = 'block';
    this.customConfig = {};
    this.templateRef = this._TemplateModalService.open(this.modalTemplate, {}, this.customConfig);
    this.templateRef.afterClosed$.subscribe((val) => { });
  }

  async continue() {
    // const modal: any = document.getElementById('myModal');
    // modal.style.display = 'none';
    let sd = await this.closeTemplateModal();
    if (sd) {
      //console.log(this.templateRef);

      // stop second timer and initiate first timer again
      this.ngIdle.runSecondTimer = false;
      this.ngIdle.initilizeSessionTimeout();
    }
  }

  async logout() {
    // const modal: any = document.getElementById('myModal');
    // modal.style.display = 'none';
    let sd = await this.closeTemplateModal()

    if (sd) {
      //console.log(this.templateRef);

      // stop all timer and end the session
      this.ngIdle.runTimer = false;
      this.ngIdle.runSecondTimer = false;
      this._Router.navigateByUrl('/login')
    }
  }

  closeTemplateModal($event?: CustomOverlayConfig) {

    if (this.templateRef) {
      this.templateRef.close(this.customConfig);
      this.templateRef = null;
    }
    return true;
  }
}
