<!-- <div class="model-verifiy-section">
      <div class="blue-bar d-flex justify-content-end align-items-center">
          <button type="button" class="close-btn" data-dismiss="modal">
              <span>×</span>
          </button>
      </div>

      <div class="d-flex justify-content-center align-items-center flex-column">
          <figure class="verify-img" *ngIf="modalSetting.icon" >
                <img [src]="
                modalSetting.icon == 'error'?'assets/assets/img/svg/error.svg':
                modalSetting.icon == 'success'?'assets/assets/img/svg/success.svg':
                modalSetting.icon == 'info'?'assets/assets/img/bene_icon.png':
                modalSetting.icon == 'warning'?'assets/assets/img/svg/warning.svg':
                modalSetting.icon == 'custom'?modalSetting.img:
                'assets/assets/img/svg/warning.svg'">
          </figure>

          <div class="verify-sec mt-3">
            <div class="mt-3 mb-5" *ngIf="modalSetting.img">
              <img [src]="modalSetting.img ">
            </div>

            <h5 *ngIf="modalSetting.title" [innerHTML]="modalSetting.title"></h5>
            <h3 *ngIf="modalSetting.title2" [innerHTML]="modalSetting.title2"></h3>
            <p *ngIf="modalSetting.msg" [innerHTML]="modalSetting.msg"></p>
              <div class="row justify-content-center">
                  <div class="col-lg-5">
                      <button type="button" class="btn btn-yes" *ngIf="modalSetting.btnConfirm" (click)="confirmModal($event)">
                        {{modalSetting.btnConfirm}}</button>
                  </div>
                  <div class="col-lg-5">
                    <button type="button" class="btn btn-no" *ngIf="modalSetting.btnClose"
                    (click)="cancelModal($event)">{{modalSetting.btnClose}}</button>
                  </div>
              </div>
          </div>

      </div>
</div> -->

<div class="model-verifiy-section">
  <div class="blue-bar d-flex justify-content-end align-items-center">
      <button type="button" class="close-btn" data-dismiss="modal" (click)="closeModal($event)">
          <span>×</span>
      </button>
  </div>
  <div class="d-flex justify-content-center align-items-center flex-column">
      <figure class="verify-img">
          <img src="assets/assets/img/bene_icon.png">
      </figure>

      <div class="verify-sec mt-3">
          <div class="d-flex justify-content-center">
              <p>Amount</p>
              <p class="textans">: {{dataValue.data.amount}}</p>
          </div>
          <div class="d-flex justify-content-center">
              <p>Bene Name</p>
              <p class="textans">: {{dataValue.data.bene_name}}</p>
          </div>
          <div class="d-flex justify-content-center flex-wrap">
              <p>Bank Name</p>
              <p class="textans">: {{dataValue.data.bank_name}}</p>
          </div>
          <div class="d-flex justify-content-center">
              <p>Schedule Date</p>
              <p class="textans">: {{dataValue.data.created_at | date:'MMM dd yyyy'}}</p>
          </div>
          <h6 *ngIf="modalSetting.title4" [innerHTML]="modalSetting.title4"></h6>
          <div class="d-flex justify-content-center">
              <div class="radio mr-4">
                  <input *ngIf="modalSetting.itemId == 'addBene'" id="radio-1" class="radio-custom" name="radio-group" [value]="modalSetting.title2" [formControl]="radioForm" type="radio">
                  <label for="four-wheeler4"
                      class="radio-label"><strong>Permanently</strong></label>
              </div>
              <div class="radio">
                  <input *ngIf="modalSetting.itemId == 'addBene'" id="radio-2" class="radio-custom"name="radio-group" [value]="modalSetting.title3" [formControl]="radioForm" type="radio">
                  <label for="four-wheeler4" class="radio-label"><strong>Only next transaction</strong></label>
              </div>
          </div>
          <div class="row justify-content-center">
              <div class="col-lg-5">
                  <button type="button" class="btn btn-yes" *ngIf="modalSetting.btnConfirm" (click)="confirmModal($event)">
                    {{modalSetting.btnConfirm}}
                  </button>
              </div>
              <div class="col-lg-5">
                  <button type="button" class="btn btn-no" *ngIf="modalSetting.btnClose"   (click)="cancelModal($event)">
                    {{modalSetting.btnClose}}
                  </button>
              </div>
          </div>
      </div>

  </div>
</div>
