import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerifyDocumentComponent } from './verify-document.component';
import { SharedModule } from '@shared/shared.module';
import { PartnerDocumentsModule } from '../partner-documents/partner-documents.module';



@NgModule({
  declarations: [VerifyDocumentComponent],
  imports: [
    SharedModule,
    PartnerDocumentsModule
  ],
  exports:[VerifyDocumentComponent]
})
export class VerifyDocumentModule { }
