import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConfirmationModalOverlayRef } from '@features/customCDK/cdkCustomMdl/classes/confirmation-modal.ref';
import { CONFIRMATION_MODAL_DATA } from '@features/customCDK/cdkCustomMdl/tokens/confirmation-data.token';

@Component({
  selector: 'app-bulk-payout-modal',
  templateUrl: './bulk-payout-modal.component.html',
  styleUrls: ['./bulk-payout-modal.component.scss']
})
export class BulkPayoutModalComponent implements OnInit {

  modalSetting: any;
  radioForm1: FormControl = new FormControl()
  radioForm2: FormControl = new FormControl()
  radioForm3: FormControl = new FormControl()
  constructor(
    public dialogRef: ConfirmationModalOverlayRef,
    @Inject(CONFIRMATION_MODAL_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.modalSetting = this.data;
    this.radioForm1.setValue(this.modalSetting?.repeatMode_auto)
    this.radioForm2.setValue(this.modalSetting?.timeInterval_monthly)
    this.radioForm3.setValue(this.modalSetting?.notification_yes)
  }


  public closeModal($event: any) {
    this.dialogRef.events.next({
      type: 'cancel',
      data: this.data
    });
  }

  public confirmModal($event: any) {
    this.data['repeatModeValue'] = this.radioForm1.value
    this.data['repeatTimeValue'] = this.radioForm2.value
    this.data['notificationValue'] = this.radioForm3.value
    this.dialogRef.events.next({
      type: 'confirm',
      data: this.data
    });
  }

  public cancelModal($event: any) {
    this.dialogRef.events.next({
      type: 'cancel',
      data: this.data
    });
  }

}
