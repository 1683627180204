import { SidebarComponent } from './sidebar.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';



@NgModule({
  declarations: [
    SidebarComponent
  ],
  imports: [
    SharedModule,
  ],
  exports: [
    SidebarComponent
  ]
})
export class SidebarModule { }
