import { TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SessionStorageService } from '@core/services/session-storage.service';
import { NgxToasterService } from '@core/services/toasterNgs.service';
import { UserDetailsService } from '@core/services/user-details.service';
import { TemplateModalOverlayRef } from '@features/customCDK/cdkCustomMdl/classes/template-modal.ref';
import {
  CustomOverlayConfig,
  TemplateModalService,
} from '@features/customCDK/cdkCustomMdl/services/template-modal.service';
import { SignUpService } from '@page/auth/sign-up.service';
import { KycServices } from '../kyc-services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss'],
})
export class ContactDetailComponent implements OnInit {
  isResendOtp: boolean = false;
  private templateRef!: TemplateModalOverlayRef;
  customConfig!: CustomOverlayConfig;
  @ViewChild('modalTemplate') modalTemplate!: TemplatePortal<any>;
  @Output() selectedService = new EventEmitter();
  contactDetail: any;
  resellerSubs: Subscription;
  isStatus: boolean = false;
  resellerDetails: any = {
    reseller_requested: null,
    aggregator_requested: null,
    upi_requested: null,
    payout_requested: null,
    cb_requested: null
  };
  is_reseller: any;
  is_aggregator: any;
  is_upi: any;
  is_cb: any;
  is_payout: any;
  constructor(
    private toster: NgxToasterService,
    private kycService: KycServices,
    private sessionStorage: SessionStorageService,
    private signUpService: SignUpService,
    private userDetailsService: UserDetailsService,
    private _TemplateModalService: TemplateModalService
  ) {
   let data = this.kycService.resellerData.getValue();
     if(data == ''){
      this.getResellerStatus();
     }
     else{
      this.resellerDetails = data;
     }


    this.getUserDetail();
  }

    getResellerStatus(){
      this.resellerSubs = this.kycService.updateReseAggreStatus(null).subscribe(
      {
        next: (resp: any) => {
          if(resp.statuscode == 200){
            this.resellerDetails.reseller_requested = resp?.data?.reseller_requested;
            this.resellerDetails.aggregator_requested = resp?.data?.aggregator_requested;
            this.resellerDetails.upi_requested = resp?.data?.upi_requested;
            this.resellerDetails.payout_requested = resp?.data?.payout_requested;
            this.resellerDetails.cb_requested = resp?.data?.cb_requested;
            this.kycService.resellerData.next(this.resellerDetails);
          } else {
            this.toster.showError(resp.message, 'Error')

          }
        },
        error: (err: any) => {
          this.toster.showError(err.message, 'Error')

        }
      }
      );
    }

  ngOnInit(): void {}
  getUserDetail() {
    this.userDetailsService.userLoginDetails.subscribe({
      next: (value: any) => {
        // console.log("login details  value ", value)
        this.contactDetail = value?.data;
      },
    });
  }
  saveContactDetail() {

    if (this.isStatus) {
      let formData = new FormData();
      formData.append('aggregator_requested', (this.is_aggregator == undefined? this.resellerDetails.aggregator_requested:this.is_aggregator));
      formData.append('reseller_requested',  (this.is_reseller == undefined? this.resellerDetails.reseller_requested:this.is_reseller));
      formData.append('upi_requested',  (this.is_upi == undefined? this.resellerDetails.upi_requested:this.is_upi));
      formData.append('cb_requested',  (this.is_cb == undefined? this.resellerDetails.cb_requested:this.is_cb));
      formData.append('payout_requested',  (this.is_payout == undefined? this.resellerDetails.payout_requested:this.is_payout));
      this.kycService.updateReseAggreStatus(formData).subscribe(
      {
        next: (resp: any) => {
          if(resp.statuscode == 200){
            this.toster.showSuccess(resp.message, 'Success')
            this.resellerDetails.reseller_requested = resp?.data?.reseller_requested;
            this.resellerDetails.aggregator_requested = resp?.data?.aggregator_requested;
            this.resellerDetails.upi_requested = resp?.data?.upi_requested;
            this.resellerDetails.payout_requested = resp?.data?.payout_requested;
            this.resellerDetails.cb_requested = resp?.data?.cb_requested;
            this.kycService.resellerData.next(this.resellerDetails);
            this.selectedService.emit(2);
            this.userDetailsService.updateLocalStorage({ ['data.kyc_step']: 2 });
          } else {
            this.toster.showError(resp.message, 'Error')
          }
        },
        error: (err: any) => {
          this.toster.showError(err.message, 'Error')
        }
      }
      );
    }else{
      this.selectedService.emit(2);
      // this.sessionStorage.setItem('kycSteps', 2);
      this.userDetailsService.updateLocalStorage({ ['data.kyc_step']: 2 });
      // let payLoad={id:this.contactDetail.id};
      // this.kycService.saveContactDetail(payLoad).subscribe(res=>{
      //   if(res.statuscode == 200){
      //     this.toster.showSuccess(res.msg,'Success');
      //     this.selectedService.emit(res.kyc_step);
      //     this.sessionStorage.setItem('kycSteps',res.kyc_step);
      //   }else{
      //     this.toster.showError(res.msg,'Error');
      //   }

      // })
    }

  }
  onVerify() {
    let formData: FormData = new FormData();
    this.signUpService.sendEmailOTP(formData).subscribe((res) => {
      console.log(res);
      if (res.statuscode == 200 && res.status) {
        if (!this.isResendOtp) {
          this.openOTPModal();
        }
        this.toster.showSuccess(res.message, 'Success');
      } else {
        this.toster.showError(res.message, 'Error');
      }
    });
  }
  async sendOTP(isOtpSend: boolean) {
    this.isResendOtp = isOtpSend;
    this.onVerify();
  }
  verifyOtp(otp: any) {
    if (!otp) {
      this.toster.showInfo('please enter OTP', 'Info');
      return;
    }
    const data = new FormData();
    data.append('otp', otp);
    this.signUpService.verifyEmailOTP(data).subscribe((res: any) => {
      if (res && res.statuscode == 200 && res.status) {
        this.closeTemplateModal();
        this.toster.showSuccess(res.message, 'Success');
        this.userDetailsService.updateLocalStorage({
          ['data.email_verify']: '1',
        });
        this.getUserDetail();
      } else {
        this.toster.showError(res.message, 'Error');
      }
    });
  }
  closeTemplateModal($event?: CustomOverlayConfig) {
    this.templateRef.close(this.customConfig);
  }
  openOTPModal() {
    this.customConfig = {};
    this.templateRef = this._TemplateModalService.open(
      this.modalTemplate,
      {},
      this.customConfig
    );
    this.templateRef.afterClosed$.subscribe((val) => {
      if (val.type == 'ok') {
      } else {
      }
    });
  }

  updateReseAggre(status: any, type: any) {
    this.isStatus = true;
    if (type === 'reseller') this.is_reseller = status ? 1 : 0;
    if (type === 'aggregator') this.is_aggregator = status ? 1 : 0;
    if (type === 'upi') this.is_upi = status ? 1 : 0;
    if (type === 'cb') this.is_cb = status ? 1 : 0;
    if (type === 'payout') this.is_payout = status ? 1 : 0;
  }

  ngOnDestroy(): void {
    if (this.resellerSubs) {
      this.resellerSubs.unsubscribe();
    }
  }
}
