<div class="tab-pane" role="tabpanel">
    <form [formGroup]="form">
        <div class="kyc-box">
            <div class="kyc-heading pb-4">Provide your bank details</div>
            <div class="row mt-3">
                <div class="col-12 col-lg-6">
                    <div class="floating-label">

                        <select class="floating-select form-control" formControlName="bankId" placeholder="Select Bank">
                            <option *ngFor="let item of bankList" [ngValue]="item">{{item.name}}</option>
                        </select>
                        <span class="highlight"></span>
                        <label class="floating-label-label" *ngIf="form.get('bankId')?.value">Select Bank <span class="text-danger star">*</span></label>
                        <label class="p-2" *ngIf="!form.get('bankId')?.value">Select Bank <span class="text-danger star">*</span></label>
                        <app-show-errors [ctrl]="form.get('bankId')" [requiredMesg]="'Bank is Required'">
                        </app-show-errors>
                    </div>

                </div>
                <div class="col-12 col-lg-6">
                    <div class="floating-label">
                        <input class="floating-input form-control" type="text" placeholder=" "
                            formControlName="accountHolderName"  >
                        <span class="highlight"></span>
                        <label class="p-2">Account Holder Name <span class="text-danger star">*</span></label>
                        <app-show-errors [ctrl]="form.get('accountHolderName')"
                            [requiredMesg]="'Account Holder Name is Required'">
                        </app-show-errors>

                    </div>
                </div>
                <div class="col-12 col-lg-6">

                    <div class="floating-label">
                        <input class="floating-input form-control" type="text" [appInputRestriction]="'noSpecialChars'"
                            placeholder=" " formControlName="accountNumber" maxlength="17" appUppercase>
                        <span class="highlight"></span>
                        <label class="p-2">Account Number <span class="text-danger star">*</span></label>
                        <app-show-errors [ctrl]="form.get('accountNumber')"
                            [requiredMesg]="'Account Number is Required'">
                        </app-show-errors>

                    </div>

                </div>
                <div class="col-12 col-lg-6">

                    <div class="floating-label">
                        <input class="floating-input form-control" type="text" [appInputRestriction]="'noSpecialChars'"
                            placeholder=" " formControlName="c_accountNumber" appUppercase
                            onpaste="return false;">
                        <span class="highlight"></span>
                        <label class="p-2">Confirm Account Number <span class="text-danger star">*</span></label>
                        <div *ngIf="(bankDetailControle['c_accountNumber'].touched || submitted)" class="mt-3">
                            <span class="text-danger" *ngIf="bankDetailControle['c_accountNumber'].errors?.['required']">
                                Confirm Account number is Required
                            </span>

                            <div class="text-danger"
                                *ngIf="bankDetailControle['c_accountNumber'].hasError('confirmedValidator')">
                                Account Number and Confirm Account Number not matched</div>

                        </div>
                    </div>

                </div>
                <div class="col-12 col-lg-6">
                    <div class="floating-label">
                        <input class="floating-input form-control" type="text" placeholder=" "
                            formControlName="ifscCode" >
                        <span class="highlight"></span>
                        <label class="p-2">IFSC Code <span class="text-danger star">*</span></label>
                        <app-show-errors [ctrl]="form.get('ifscCode')" [requiredMesg]="'IFSC Code is Required'">
                        </app-show-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <p>Upload Cancelled Cheque <span class="text-danger star">*</span></p>
                    <div *ngIf="!status" class="csv-drag" appFileUpload (fileDropped)="onFileChange($event,file_url)">
                        <div class="drop-zone-prompt" >
                            <img [src]="file_url" class="mb-4"><br>
                            <input #checkFile type="file" name="file" id="file" (change)="onFileChange($event,file_url)"
                                accept=".png, .jpg, .jpeg" >
                            <p>Drop file here or <span>Click to Upload</span></p>
                        </div>
                    </div>
                    <a *ngIf="status" (click)="viewDoc(file_url)">
                      <img [src]="file_url" class="mb-4" style="height: auto !important;min-width: 300;max-width: 60%;">
                    </a>
                    <app-show-errors [ctrl]="form.get('cancelChequeUrl')"
                        [requiredMesg]="'Please upload cancel Cheque'">
                    </app-show-errors>

                </div>
                <div class="col-12">
                    <p class="align-items-start">Details should be of the mentioned business only*</p>
                </div>
                <div class="col-12 d-flex justify-content-end" *ngIf="!status">
                    <button class="btn save-btn" (click)="onSaveBankDetail()" type="button"
                        [disabled]="(kycStep_ == 10 && status) || form.invalid ">Save & Next</button>
                </div>
            </div>
        </div>
    </form>
</div>
