import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { KycComponent } from '../kyc.component';

@Component({
  selector: 'app-more-detail-required',
  templateUrl: './more-detail-required.component.html',
  styleUrls: ['./more-detail-required.component.scss']
})
export class MoreDetailRequiredComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    config: NgbModalConfig, private modalService: NgbModal,
  ) {
    //config.backdrop = 'static';
   }

  ngOnInit(): void {
  }
  conpleteKyc(){
    const modalRef = this.modalService.open(KycComponent);
    this.activeModal.close();
  }
}
