import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoLogOutComponent } from './auto-log-out/auto-log-out.component';
import { AutoLogoutService } from './service/auto-logout.service';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CdkCustomMdlModule } from '@features/customCDK/cdkCustomMdl/cdk-custom-mdl.module';
import { AutoLogOutPopComponent } from './auto-log-out-pop/auto-log-out-pop.component';

const routes: Routes = [
  {
    path: '',
    component: AutoLogOutComponent
  },]

@NgModule({
  declarations: [
    AutoLogOutComponent,
    AutoLogOutPopComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CdkCustomMdlModule,
    RouterModule.forChild(routes)
  ],
})
export class AutoLogOutModule { }
