<div class="modal kyc-details d-block" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content text-center">
                <div class="modal-header justify-content-center border-0">
                    <h5 class="modal-title">Few more details required</h5>
                </div>
                <div class="modal-body">
                    <p class="modal-text">For your business model we need a few more KYC details to allow you to accept
                        payments</p>

                    <button class="btn activate-btn btn-margin" (click)="conpleteKyc()">Complete KYC</button>
                </div>

            </div>
        </div>
    </div>
