<!-- <div class="modal-otp col-12 p-3">
    <div class="">
        <h2><b>Second Level Timer activated</b></h2>
        <div class="clock-style">
            <span>Time Remain -</span>
            <span class="time-in-mins">{{ secondTimerLeft }}</span>
        </div>

        <div style='display: flex;'>
            <button (click)='_continue()'>Continue</button>
            <button (click)='_logout()'>Logout</button>
        </div>
    </div>
</div> -->


<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
  Launch demo modal
</button> -->

<!-- Modal -->
<div class="bank-modal " id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header border-0 py-1">

                <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
            </div>
            <div class="modal-body">
                <h5 class="modal-title" id="exampleModalLongTitle">Second Level Timer activated</h5>
                <!-- <p class="text-center p-3"><img src="assets/assets/img/yes-logo.png"></p> -->
                <h3>Time Remain -</h3>
                <p>{{ secondTimerLeft }}</p>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="btn btn-success" (click)='_continue()'>Continue</button>
                <button type="button" class="btn btn-primary" (click)='_logout()'>Logout</button>
            </div>
        </div>
    </div>
</div>


<!-- Modal footer -->