<div class="row">
    <div class="col-12" *ngIf="!status">

        <div [ngClass]="isuploadClass?'upload-csv-file':'upload-csv' " ngClass="{}">
            <div *ngIf="reqDocument == 'kycboard' " style="margin-top: -100px; margin-bottom: 25px;">
                <label style="display: flex;font-size: 16px; font-weight: 600;"> Note: <p class="mt-1 ml-1 bor-label" >Board resolution should be printed on the company’s letter head and signed by all the Director’s as per the signature on their PAN card.</p></label>

                <a class="btn btn btn-br-formate" href="assets/doc/Board-resolution-Format.docx" target="_blank" download rel="noopener noreferrer">Download BR Format</a>
                <!-- <button type="button" class="btn btn-br-formate">BR Formate</button> -->
            </div>
            <p>{{showMsg}}</p>

            <div class="csv-drag" appFileUpload (fileDropped)="onDocumentChange($event)">
                <div class="drop-zone-prompt">
                    <img [src]="fileType == 'pdf'? pdfPath :file_url" class="mb-4"><br>
                    <input type="file" #fileDropRef (change)="onDocumentChange($event)" />
                    <p style="font-size: 11px;" class="mb-0">Allowed Format - {{uploadFileType}} </p>
                    <p style="font-size: 11px;">Max File Size 2MB</p>
                    <p>Drop file here or <span>Click to Upload</span></p>
                </div>
            </div>
            <a *ngIf="file_url != 'assets/assets/img/upload_file.png' && file_url " class="document" (click)="viewDoc(file_url)" >View Document</a>
            <p *ngIf="!status" class="align-items-start ">{{status == 0?remarks:common_msg}}</p>
        </div>
        <p class="align-items-start ">{{status == '2'?'Document approval pending from admin side':(status == '1'?'Your document is approved':'Details should be of the mentioned business only*')}}</p>
    </div>
    <div [ngClass]="reqDocument != 'moaaoakyc'?'col-12 col-lg-6' : 'col-12' " *ngIf="status ">
        <a class="document">
          <object *ngIf="fileType == 'pdf' " [data]="file_url | safeUrl" height="450" width="100%" ></object>
            <button *ngIf="fileType == 'pdf' " class="pdf-view-btn" type="button" (click)="viewDoc(file_url)">View Document</button>
            <img *ngIf="fileType != 'pdf' " (click)="viewDoc(file_url)"  [src]="file_url" class="mb-4" style="height: auto !important;min-width: 300;max-width: 90%;">
        </a>

        <p class="align-items-start ">{{status == '2'?'Document approval pending from admin side':(status == '1'?'Your document is approved':'Details should be of the mentioned business only*')}}</p>

    </div>
</div>
