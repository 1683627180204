
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { NgxToasterService } from '@core/services/toasterNgs.service';
import { TosterService } from '@core/services/toster.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SignUpService } from '@page/auth/sign-up.service';
import { Subscription, take, timer } from 'rxjs';
import { CommonSubjectService } from 'src/app/services/common-subject.service';

@Component({
  selector: 'custom-dynamic-otp',
  templateUrl: './custom-dynamic-otp.component.html',
  styleUrls: ['./custom-dynamic-otp.component.scss']
})
export class CustomDynamicOtpComponent implements OnInit {
  @Input() user_id;
 
  @Output() otpChange: EventEmitter<any> = new EventEmitter();
  @Output() resendOtp: EventEmitter<any> = new EventEmitter(false);
  @Output() submit: EventEmitter<any> = new EventEmitter();

  @ViewChild('otpInput') otpInputElement: ElementRef;

  otp: any;
  countDown: Subscription;
  counter = 120;
  tick = 1000;
  userId:number;

  constructor(
    private toaster:TosterService,
    private ngxToster:NgxToasterService,
    private signUpServices:SignUpService,
    private commonServices:CommonSubjectService,
    // public dialogRef: FormModalOverlayRef,
    public activeModal: NgbActiveModal,
    private router:Router
  ) { 
    
    setTimeout(()=>{ 
      this.otpInputElement?.nativeElement?.focus();
    },0);  
  };

  ngOnInit(): void {
    this.userId= this.user_id;
    this.transformData()
  };


  onResendOtp() {
    this.resendOtp.emit(true);
  };

  onSubmitOTP() {
    //this.submit.emit();
    this.verifyOtp(this.otp);  
  }
  
  transformData(){
    this.countDown = timer(0, this.tick)
    .pipe(take(this.counter))
    .subscribe(() => {
      --this.counter;
      // console.log(this.counter);
      if (this.counter == 0) {
        this.countDown.unsubscribe();
      }
    });
    this.transform(120);
  }
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );
  }
  verifyOtp(otp:any){
    let payLoad={id:this.userId,otp:otp};
    this.signUpServices.validateOtp(payLoad).subscribe(res=>{
        if(res){
          if(res.statuscode == 200 && res.status == true){
            this.ngxToster.showSuccess(res.message, 'Success');
            //this.commonServices.verifiedOtp.next(true);            
            this.activeModal.close({res:true});
            this.router.navigate(['/company-type'])
          }else{
            this.ngxToster.showError(res.msg, 'Error');
          }
        }
         else{
          this.ngxToster.showError(res.msg, 'Error');
         }         
        }    
    )
  }
  onCloseTab(){
    this.activeModal.close();
  }
}
