
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class LoaderService {

  public isLoading = new BehaviorSubject(false);

  constructor() { }

  show() {
    this.isLoading.next(true);
  }

  hide() {
      this.isLoading.next(false);
  }
}
