
import { LoaderComponent } from './shared/components/loader/loader.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@shared/shared.module';
import { Relipayconfig } from '@rnficonfig/index';
import { RnfiModule } from '@rnficonfig//rnfi.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutsModule } from '@features/layouts/layouts.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe, HashLocationStrategy } from '@angular/common';
import { HttpInterceptorInterceptor } from '@core/interceptors/http-interceptor.interceptor';
import { AutoLogOutModule } from '@features/autoLogout/auto-log-out.module';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RnfiModule.forRoot(Relipayconfig),
    LayoutsModule,
    AutoLogOutModule,
    SharedModule,
    CoreModule,

  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: NetworkInterceptor,
    //   multi: true
    // },
   [ {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorInterceptor,
      HashLocationStrategy,
      multi: true,

    }],
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
