
<div class="tab-pane" *ngIf="(kyc_steps >= 3 && kyc_steps < 6 ) || (kyc_steps == 7) || (kyc_steps == 6 && current_doc_obj.value != 'moaaoakyc') " role="tabpanel">
    <form action="" method="post">
        <div class="kyc-box">
            <div class="kyc-heading pb-4">{{current_doc_obj.value == 'kyctrustdeedormou' && userData.company_id == 2 ? 'Partnership Deed' :current_doc_obj.name}} <span class="text-danger star">*</span></div>
            <app-upload-document *ngIf="file_url" (uploadDoc_)="onChildDocChange($event)" [file_url]="file_url" [remarks]="remarks" [status]="status"
             [fileType]="fileType" [uploadFileType]="current_doc_obj?.document_type" [reqDocument]="current_doc_obj.value" ></app-upload-document>
            <div class="row" *ngIf="!status">
              <div class="col-12 d-flex justify-content-end mt-5">
                  <button type="button" class="btn save-btn" (click)="verifyOcrDocument()" [disabled]="file_url == 'assets/assets/img/upload_file.png' ">Save & Next</button>
              </div>
          </div>
        </div>
    </form>
</div>

<div class="tab-pane " *ngIf="kyc_steps == 6 && current_doc_obj.value == 'moaaoakyc' " role="tabpanel">
    <form action="" method="post">
        <div class="kyc-box">
            <div class="kyc-heading pb-4">MOA/ AOA </div>
            <div class="row">
              <div class="col-6">
                <p>MOA*</p>
                <app-upload-document *ngIf="file_url" (uploadDoc_)="onChildDocChange($event)" [file_url]="file_url" [remarks]="remarks"
                 [status]="status" [fileType]="fileType" [uploadFileType]="current_doc_obj?.document_type" [reqDocument]="current_doc_obj.value"></app-upload-document>
              </div>
              <div class="col-6">
                <p>AOA*</p>
                <app-upload-document *ngIf="file_url1" (uploadDoc_)="onFileChange($event)" [file_url]="file_url1" [remarks]="remarks"
                 [status]="status" [fileType]="fileType1" [uploadFileType]="current_doc_obj?.document_type" [reqDocument]="current_doc_obj.value"></app-upload-document>
              </div>
            </div>
      <div class="row" *ngIf="!status">
        <div class="col-12 d-flex justify-content-end mt-5">
            <button type="button" class="btn save-btn" (click)="verifyOcrDocument()" [disabled]="file_url == 'assets/assets/img/upload_file.png' || file_url1 == 'assets/assets/img/upload_file.png' ">Save & Next</button>
        </div>
    </div>
        </div>
    </form>
</div>
<app-partner-documents *ngIf="kyc_steps == 8 " (isKycStep)="onSubmitKyc($event)"></app-partner-documents>
