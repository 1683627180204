import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomInputComponent),
      multi: true
    }
  ]
})
export class CustomInputComponent implements OnInit, ControlValueAccessor {

  value = '';

  onChange: (value?: any) => void;

  onTouch: (event: any) => void;

  @Input() placeholder = '';

  @Input() autoFocus = false;

  @Input() isDisabled: boolean;

  @Input('type') inputType: string;

  @Input() edit: boolean;

  @Input() fxFlex;
  @Input() tabIndex;

  @ViewChild('input', { static: false }) input: ElementRef;

  ngAfterViewInit() {
    if (this.autoFocus) {
      this.onFocus()
    }
  }
  ngOnInit(): void {

  }
  writeValue(value: any) {
    //console.log(value)
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisableState(status: boolean) {
    this.isDisabled = status;
  }

  onInput(value: any) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  onTouched(value: any = '') {
    if (this.onTouch) {
      this.onTouch(value)
    }
  }

  onFocus() {
    //Normal Focus Method
    this.input.nativeElement.focus();

    // Another Method for set Focus
    // this.input.nativeElement.select();
  }
}
