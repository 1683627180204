
export class ConfigDynamicField {
  heading: string;
  nameLabel: string;
  mobileNumber:string;
  emailId:string;
  panCardHeading: string;
  addressHeading: string;
  addressNameLabel: string;
}
export class PartnersDocumentDetail {
  document_id: any;
  name: string;
  mobile:string;
  email:string;
  address_proof: string;
  voter_back_file: string;
  partner_file_url: string;
  partner_file_url1: string;
  partner_file_url2: string;
  pan_status:any;
  status:string='';
  remarks:string;
  pstatus:string|number;
  ostatus:string|number;
}
