import { Component, OnInit ,Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-show-message',
  templateUrl: './show-message.component.html',
  styleUrls: ['./show-message.component.scss']
})
export class ShowMessageComponent implements OnInit {

  @Input() message:string;
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }
  onContinue(){
    this.activeModal.close({return:true});
  }
}
