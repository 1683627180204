<!-- <div class="sidebar-noneoverflow">
  <relipay-content></relipay-content>
 </div> -->


<!-- <div class="sidebar-noneoverflow"> -->
<div class="main-container" id="container">

  <div class="overlay"></div>
  <div class="search-overlay"></div>

  <ng-container *ngIf="!_relipayConfig?.layout?.header?.hidden">

    <relipay-header></relipay-header>
    <!-- <ng-container *ngIf="!loginData?.email_verify" >
            <verify-email ></verify-email>
        </ng-container> -->


  </ng-container>
  <ng-container *ngIf="!_relipayConfig?.layout?.sidepanel?.hidden">

    <relipay-sidebar class="relipay-sidebar"></relipay-sidebar>

  </ng-container>



  <div id="content" class="main-content"
    [ngStyle]="{'margin-left': !_relipayConfig.layout.sidepanel.hidden ? '222px' : '0px','padding-top': !_relipayConfig.layout.sidepanel.hidden ? '0' : '0px','margin-top': !relipayConfig.layout.sidepanel.hidden ? '75px' : '0px','padding-left':_payoutSideBar?'215px':'unset'}">
    <ng-container>
      <relipay-content></relipay-content>

    </ng-container>

    <ng-container *ngIf="!_relipayConfig.layout.footer.hidden">

      <relipay-footer></relipay-footer>

    </ng-container>

  </div>
</div>
