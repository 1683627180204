import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { EMPTY, merge, Observable, of, Subscription } from 'rxjs';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { DropdownPanel } from './dropdown-panel';
import { defPos, posOptions } from '../customTooltip/custom-tooltip.directive';

@Directive({
  selector: '[dropdownTriggerFor]',
  host: {
    '(click)': 'toggleDropdown()',
  },
})
export class DropdownTriggerForDirective implements OnInit, OnDestroy {
  private isDropdownOpen = false;
  private overlayRef!: OverlayRef;
  private dropdownClosingActionsSub = Subscription.EMPTY;

  @Input('dropdownTriggerFor') public dropdownPanel!: DropdownPanel;
  @Input('ddHasBackdrop') ddHasBackdrop: boolean = true;
  @Input('backdropClose') backdropClose: boolean = true;
  @Input('setActiveClass') setActiveClass: boolean = true;
  @Input('activeClassName') activeClassName: string = 'activeDd';
  @Input('prefPos') prefPos!: ConnectedPosition;
  @Output('onDdClose') onDdClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input('apiCall') apiCall: boolean = false;
  @Output('apiDD') apiDD = new EventEmitter<any>();

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef<HTMLElement>,
    private _Renderer2: Renderer2,
    private viewContainerRef: ViewContainerRef
  ) { }
  ngOnInit(): void { }
  toggleDropdown(): void {

    if (this.apiCall) {
      let calBack: Function = () => {
        this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown();
      };
      this.apiDD.emit(calBack);
    } else {
      this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown();
    }
  }

  openDropdown(): void {

    this.isDropdownOpen = true;
    this.overlayRef = this.overlay.create({
      hasBackdrop: this.ddHasBackdrop,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions(this.prefPos ? [this.prefPos] : posOptions['bottom']),
      // .withPositions([
      //   // defPos[2],
      //   {
      //     originX: 'start',
      //     originY: 'top',
      //     overlayX: 'end',
      //     overlayY: 'bottom',
      //     offsetX: 0,
      //     offsetY: -15,
      //     panelClass: 'topLeft',
      //   },
      // ]),
    });

    const templatePortal = new TemplatePortal(
      this.dropdownPanel.templateRef,
      this.viewContainerRef
    );
    this.overlayRef.attach(templatePortal);
    if (this.setActiveClass) {
      this._Renderer2.addClass(this.elementRef.nativeElement, this.activeClassName);
    }
    this.onDdClose.emit(false);

    this.dropdownClosingActionsSub = this.dropdownClosingActions().subscribe(
      (val: any) => {
        this.destroyDropdown();
      }
    );
  }

  private dropdownClosingActions(): Observable<MouseEvent | void> {
    const backdropClick$ =
      this.ddHasBackdrop && this.backdropClose
        ? this.overlayRef.backdropClick()
        : EMPTY;
    const detachment$ = this.overlayRef.detachments();
    const dropdownClosed = this.dropdownPanel.closed;

    return merge(backdropClick$, detachment$, dropdownClosed);
  }

  destroyDropdown(): void {

    if (!this.overlayRef || !this.isDropdownOpen) {
      return;
    }

    this.dropdownClosingActionsSub.unsubscribe();
    this.isDropdownOpen = false;
    this.overlayRef.detach();
    this.onDdClose.emit(true);
    if (this.setActiveClass) {
      this._Renderer2.removeClass(this.elementRef.nativeElement, this.activeClassName);
    }

  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.onDdClose.emit(true);
    }
  }
}
