import { HeaderComponent } from './header.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { KycModule } from '@page/dashboard/kyc/kyc.module';



@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    SharedModule,
    KycModule
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
