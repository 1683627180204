

export enum ToasterIcon {
    error = 'toast-error',
    info = 'toast-info',
    success = 'toast-success',
    warning = 'toast-warning',
}

export enum ToasterPosition {
    topRight = 'toast-top-right',
    topLeft = 'toast-top-left',
    bottomRight = 'toast-bottom-right',
    bottomLeft= 'toast-bottom-left',
    centerRight = "toast-top-center",
    // center = "toast-top-center"
    
    // Other positions you would like
  }

 