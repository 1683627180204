
import { Component, OnInit, OnDestroy } from '@angular/core';
import { RnfiConfigService } from '@rnficonfig/config.service';
import { Subject, takeUntil, Subscription } from 'rxjs';
import { AuthCoreService } from '@page/auth/auth.service';
declare function mySideBarInitFn(): any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // providers: [ FileuploadService]
})
export class AppComponent implements OnInit, OnDestroy {
  // title(title: any) {
  //   throw new Error('Method not implemented.');
  // }

  relipayConfig: any;
  amountDatasubscription: Subscription;

  // Private
  private _unsubscribeAll: Subject<any>;

  public static staticIsLoad = false;

  get isLoad() {
    return AppComponent.staticIsLoad;
  };

  set isLoad(value) {
    AppComponent.staticIsLoad = value;
  }
  constructor(
    private _rnfiConfigService: RnfiConfigService,
    private _authCoreService:AuthCoreService
    // private fileuploadService: FileuploadService
  ) {

    this._authCoreService.shouldEncryptApiRequest().subscribe(res=>{
      if(res.statuscode == 200){
        if (res.data.encrypt) {
          this._authCoreService.encrypted = true;
        }
        else {
          this._authCoreService.encrypted = false;
        }
        this.isLoad = true;
      }
      });



    this._rnfiConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        header: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        news: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        },
        content: {
          hidden: true
        },
        slider: {
          hidden: true
        },
      }
    };
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    // this._AutoLogoutService.initTimer(0.1, 1);
    setTimeout(() => {
      // mySideBarInitFn();
    }, 1);

    // this.loader.hide();
    this._rnfiConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: any) => {
        this.relipayConfig = config;
      });

  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  };


}
