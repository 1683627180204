import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalDropDownComponent } from './global-drop-down/global-drop-down.component';
import { PrimaryDropDownComponent } from './primary-drop-down/primary-drop-down.component';
import { SecondaryDropDownComponent } from './secondary-drop-down/secondary-drop-down.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [
    GlobalDropDownComponent,
    PrimaryDropDownComponent,
    SecondaryDropDownComponent
  ],
  imports: [
    CommonModule,
    AutocompleteLibModule,
    FormsModule,
    NgSelectModule
  ],
  exports:[
    GlobalDropDownComponent,
    PrimaryDropDownComponent,
    SecondaryDropDownComponent
  ]
})
export class DropDownModule { }
