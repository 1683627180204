import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { UserLoginDtlService } from 'src/app/service/user-login-dtl.service';
import { pytCustTblOptions, PytCustTblService } from '../pyt-cust-tbl.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { ApiRequestService } from '@core/services/api-request.service';
import { DOWNLOAD_TYPE } from '@core/common/common-config';
// import { ApiService } from 'src/app/service/api.service';
@Component({
  selector: 'app-pyt-tbl-top-box',
  templateUrl: './pyt-tbl-top-box.component.html',
  styleUrls: ['./pyt-tbl-top-box.component.scss'],
})
export class PytTblTopBoxComponent implements OnInit {
  @Input() tableOptions!: pytCustTblOptions;

  @Input() rotateIt: boolean = false;
  @Input() searchTypeWidth: string = '250px';
  @Output() reloadTbl = new EventEmitter<void>();

  @Input() isReportCanDownload: boolean = false;
  @Output() downloadReport = new EventEmitter<void>();
  @Output() addBtn: EventEmitter<any> = new EventEmitter<any>();

  download_reports: boolean = true;
  downloadFileType: any[];
  config = {
    value: true,
    name: 'abc',
    disabled: false,
    height: 35,
    width: 80,
    margin: 3,
    fontSize: 10,
    speed: 300,
    color: {
      checked: '#3D9C3C',
      unchecked: '#00659E',
    },
    switchColor: {
      checked: '#101F33',
      unchecked: '#101F33',
    },
    labels: {
      unchecked: 'kyc',
      checked: 'Merchant',
    },
    fontColor: {
      checked: '#fafafa',
      unchecked: '#ffffff',
    },
  };
  checked: boolean;
  // download_reports = this._UserLoginDtlService.getLoginDtlKeyValue(
  //   'permission.download_reports'
  // );
  constructor(
    public _PytCustTblService: PytCustTblService,
    // private _UserLoginDtlService: UserLoginDtlService,
    private auth: ApiRequestService,
  ) {

  }

  ngOnInit(): void {
    console.log(this.tableOptions)
    let downloadFileType: any = this.tableOptions?.config?.downloadReports?.fileTypes;
    this.downloadFileType = downloadFileType;
  };

  onAddButton() {
    this.addBtn.emit(true);
  };

  download(filetype: any) {

    let formdata = this.tableOptions.config.downloadReports?.formData();
    let name = (typeof this.tableOptions.config.downloadReports?.fileName === 'function' ? this.tableOptions.config.downloadReports?.fileName() : this.tableOptions.config.downloadReports?.fileName);
    formdata.set('length', 'All');
    this.auth
      .postAdminData(formdata, this.tableOptions.config.downloadReports?.url,this.tableOptions.config.downloadReports?.baseUrl )
      .subscribe((res: any) => {
        // console.log("type --> ",filetype, "name -->  ",name)
        let fileName: any = name + (filetype ?? '.xlsx');
        if (res.statuscode == 200) {
          console.log("response download ",typeof res.data,  res.data.length)
          let mainData: any = [];
          let data: any[] = [];
          if(res.data?.transactions){
            data = res.data.transactions
          } else{
            data = res.data
          }
          (data ).forEach((e) => {
            let obj: any = {};
            (res.header as []).forEach((l: any) => {
              if (l.is_show) {
                obj[l.name] = e[l.name];
              }
            })
            mainData.push(obj);
          })

          mainData.forEach((e: any) => {
            for (const key in e) {
              const etype: any = e[key];
              const nmype: any = parseFloat(etype);
              const dtype: any = Date.parse(etype);
              if (key === 'time' || key === 'account_no') { } else
                if (isNaN(etype) && !isNaN(dtype)) {
                } else if (!isNaN(nmype)) {
                  e[key] = nmype;
                }
            }
          })
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(mainData);
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, name);
          XLSX.writeFile(wb, fileName);
        } else {
          Swal.fire({
            icon: 'error',
            title: res.message,
          });
        }
      });
  }
}
