<input type="text" *ngIf="datepickerType === 'bsDaterangepicker'" [placeholder]="placeholder"
 [class]="_class" bsDaterangepicker [minDate]="minDate" [maxDate]="maxDate" [(ngModel)]="input"
 [bsConfig]="_bsCustConfg" #rangePicker="bsDaterangepicker" (onShown)="onDateRangePickerShow()"
    (ngModelChange)="dateRangeTransform($event)" >

<input *ngIf="datepickerType === 'bsDatepicker'" type="text" [placeholder]="placeholder" [class]="_class"
 bsDatepicker [minDate]="minDate" [maxDate]="maxDate" [(ngModel)]="input" #rangePicker="bsDatepicker"
  (focus)="onfocus()" (onShown)="onDateRangePickerShow()"
    (blur)="onBlur()" [bsConfig]="_bsCustConfg" (ngModelChange)="singleRateTransform($event)">

<!-- <ng-container >


    <form class="row row-cols-sm-auto">
        <div class="col-12">
            <div class="input-group">
                <input type="text" placeholder="Select Date" class=" border-0" bsDaterangepicker
                [maxDate]="maxDate" [formControl]="selectdate" [bsConfig]="bsCustConfg" [bsValue]="selectdate.value"
                #rangePicker="bsDaterangepicker" (onShown)="onDateRangePickerShow()" (bsValueChange)="bsValueChange($event)">

                <button class="btn btn-outline-secondary bi bi-calendar3" (click)="rangePicker.toggle()" type="button"></button>
            </div>
        </div>
    </form>
</ng-container> -->
