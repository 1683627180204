<!-- <div class="bank-details"> -->
    <!-- <div class="close-img d-flex justify-content-end" (click)="cancelModal($event)">
        <img src="assets/assets/img/svg/close_btn.svg">
    </div> -->


        <h2 class="mb-3"><img src="assets/assets/img/svg/bank_details.svg"></h2>
        <p> {{modalSetting.title1}}</p>
        <!-- <p>{{modalSetting.title2}}</p> -->
        <!-- <p>{{modalSetting.title3}}</p> -->
        <div class="d-flex justify-content-center my-3 flex-column text-left">
          <input *ngIf="modalSetting.itemId == 'addBene'" id="radio-1" class="radio-custom" name="radio-group" [value]="modalSetting.title2" [formControl]="radioForm" type="radio">
          <label for="radio-1" class="radio-custom-label" selected><span class="font-weight-normal">Response Name:</span> {{modalSetting.title2}}</label>

          <input *ngIf="modalSetting.itemId == 'addBene'" id="radio-2" class="radio-custom"name="radio-group" [value]="modalSetting.title3" [formControl]="radioForm" type="radio">
          <label for="radio-2" class="radio-custom-label"><span class="font-weight-normal">Entered Name:</span> {{modalSetting.title3}}</label>
       </div>
        <h3 class="text-center my-3"> {{modalSetting.title4}}</h3>

        <div class="modal-btn">
            <button type="button" class="btn btn-primary btn-both mr-2" *ngIf="modalSetting.btnConfirm"
            (click)="confirmModal($event)">{{modalSetting.btnConfirm}}</button>
            <button type="button" class="btn btn-default btn-both" *ngIf="modalSetting.btnClose"
             (click)="cancelModal($event)">{{modalSetting.btnClose}}</button>
        </div>
<!-- </div> -->

<!-- <div class="bank-details">
  <p>During verification process, we got following details:</p>
  <p>Name : Jaspreet Josan</p> <p>Account No. : XXXXXXX</p>
  <h3 class="text-center my-4">Do you want to proceed with above details?</h3>
  <div class="d-flex justify-content-center my-3">
      <input id="radio-1" class="radio-custom" name="radio-group" type="radio" checked>
      <label for="radio-1" class="radio-custom-label">Yes</label>

      <input id="radio-2" class="radio-custom"name="radio-group" type="radio">
      <label for="radio-2" class="radio-custom-label">No</label>
  </div>
  <div class="modal-btn"><button type="button" class="btn btn-primary mr-2">Confirm</button>
    <button type="button" class="btn btn-default">Cancel</button>
</div>
</div> -->
