import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-slider-toggle',
  templateUrl: './slider-toggle.component.html',
  styleUrls: ['./slider-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderToggleComponent),
      multi: true,
    },
  ],
})
export class SliderToggleComponent implements OnInit,OnChanges, ControlValueAccessor {

  @Input() toggelButtonType: any = '';
  @Input() id: any = '';
  @Input() toggleText:any = {0:'Kyc', 1:'Merc'};
  // @Output() sliderModelChange: EventEmitter<any> = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }
  /*ControlValueAccessor Functions */
  _input: any = null;


  onChange(v){
    console.log("v" , v);

    // this.sliderModelChange.emit(this._input);
  };
  onTouch: any = () => { };
  @Input('disabled')disabled: boolean = false;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: any) {
    this._input = input;
  }
}
